import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { usersSelector } from '../../selectors/entities';
import { getUsersQuery } from '../../actions/queries';
import { statusIsGood } from '../../utils/helpers';
import { Button, LinkLikeButton } from '../general/Button';
import { useDeleteUser } from '../../hooks/messagesAndRequests';
import { useParams } from 'react-router-dom';
import { GREY_LIGHT, GREY_MEDIUM, HEADER_HEIGHT, PRIMARY, 
  MAX_DESKTOP_WIDTH, SHADOW_CRISP, SHADOW_CRISP_DARK, SHADOW_DARK, ERROR
} from '../../constants/cssVars';
import ellipsesIcon from '../../assets/icons/ellipses.svg'
import { activeDropdownSelector } from '../../selectors/notices';
import { setActiveDropdown } from '../../actions/notices';
import { USER_ROW_DROPDOWN_ID } from '../../constants/notices';
import { setNewUserModal } from '../../actions/modals';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0px;
  > div {

    width: 100%;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding-top: ${HEADER_HEIGHT * 4}px;
  }  
`;

export const Text = styled.p`
  margin-top: 10px;
`

export const Table = styled.div`
  // border: 1px solid ${GREY_MEDIUM};
  margin: 20px 0px;
`
export const List = styled.div`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  > div {
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  > div:nth-child(odd) {
    background-color: ${GREY_LIGHT};
  }
  @media only screen and (max-width: 800px) {
    > div {
      flex-direction: column;
      align-items: flex-start;
    }
    > div > div {
      overflow: hidden;
    }
    > div > div:last-child {
      overflow: visible;
    }
  } 
`

export const EllipsesButton = styled.button`
  display: inline-flex;
  align-items: center;
  background-color: white;
  border: 1px solid transparent;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  box-shadow: ${SHADOW_CRISP_DARK};
  padding: 10px;
  > img {
    width: 24px;
    height: 6px;
  }
  &:hover {
    box-shadow: ${SHADOW_DARK};
  }
`

export const DropdownWrapper = styled.div`
  display: inline-block;
  margin-right: 10px;
  position: relative;
`

export const DropdownItems = styled.div`
  position: absolute;
  top: 35px;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  min-width: 100px;
  text-align: left;
  button {
    display: block;
    text-decoration: none;
  }
`

export const DescriptionAndAddUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  } 
`

export const UserManagement = () => {
  const { orgSlug } = useParams();
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);
  // const activeDropdownUserRow = useSelector(activeDropdownUserRowSelector);
  const [{ isFinished, status }] = useRequest(getUsersQuery(orgSlug));
  const users = useSelector(usersSelector);
  const [deleteUser] = useDeleteUser();

  const handleClick = (dropdownId) => () => {
    if (activeDropdown !== dropdownId) {
      dispatch(setActiveDropdown(dropdownId))
    }
  }

  const handleDeleteUser = (user) => () => {
    const confirmedDelete = window.confirm(
      `Are you sure you want to deactivate ${user.firstName} ${user.lastName} (${user.email})?`
    );
    if (confirmedDelete) {
      deleteUser(user._id);
      dispatch(setActiveDropdown(null))
    }
  }
  
  return (
    <Wrapper>
      <div>
        <h2 style={{ marginTop: '0px' }}>
          User Management
        </h2>
        <DescriptionAndAddUser>
          <Text style={{ marginBottom: '0px', marginRight: '10px'  }}>
            Viewers can view mailing lists. Admin users can send mailers and manage users.
          </Text>
          <Button style={{ minWidth: '10em' }} onClick={() => dispatch(setNewUserModal(true))}>
            Add new user +
          </Button>
        </DescriptionAndAddUser>
        <Table color={PRIMARY}>
          {(!isFinished || !statusIsGood(status)) &&
            <div>
              Loading ...
            </div>
          }
          <List>
            {users.map((user, idx) => {
              const dropdownId = `${USER_ROW_DROPDOWN_ID}_${idx}`
              const role = user.admin ? 'Admin' : 'Viewer'
              return <div key={user._id}>
                  <div style={{width: '300px'}}>{user.firstName} {user.lastName}</div>
                  <div style={{width: '400px'}}>{user.email}</div>
                  <div style={{width: '100px'}}>{role}</div>
                  <DropdownWrapper>
                    <EllipsesButton id={`${dropdownId}_btn`} onClick={handleClick(dropdownId)}>
                      <img src={ellipsesIcon} alt="" aria-label="more options" />
                    </EllipsesButton>
                    {activeDropdown === dropdownId && <DropdownItems id={dropdownId}>
                      <LinkLikeButton onClick={handleDeleteUser(user)} style={{ color: ERROR, minWidth: '150px' }}>
                        Delete user
                      </LinkLikeButton>
                    </DropdownItems>}
                  </DropdownWrapper>
              </div>})}
          </List>
        </Table>
      </div>
    </Wrapper>
  )
}