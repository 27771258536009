import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setVisiblePlaces } from '../../../actions/general';
import MapLocationsIcon from '../../../assets/icons/map_location.svg';
import { LinkLikeButton } from '../../general/Button';
import { PLACES_ICONS } from '../../../constants/map';
import { visiblePlacesSelector } from '../../../selectors/general';
import { WarningFlag } from '../WarningFlag';
import { GREY_DARK } from '../../../constants/cssVars';

const MapUIWrapper = styled.div`
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
`;

const LayersWrapper = styled.div`
  left: 10px;
  position: absolute;
  top: ${({ isDesktop }) => (isDesktop ? '85px' : '129px')};
  width: 34px;
  z-index: 500;
`;

const MapLocationIconWrapper = styled.button`
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 4px;
  :hover {
    background-color: rgb(244, 244, 244);
  }
`;

const LocationLayersControls = styled(MapUIWrapper)`
  display: flex;
  flex-flow: column;
  font-size: 0.9rem;
  left: 38px;
  position: absolute;
  top: 0;
  user-select: none;
`;

const ControlWrapper = styled.div`
  background-color: #fafafa;
  border: 1px solid #dedede;
`;

const PlacesLabel = styled.label`
  color: ${GREY_DARK};
  margin: 0 0 4px 4px;
`;

export const CustomControls = ({ isDesktop, showPlacesIcons }) => {
  const dispatch = useDispatch();
  const visiblePlaces = useSelector(visiblePlacesSelector);

  const [showLocationLayersControls, setShowLocationLayersControls] = useState(false);
  const wrapperRef = useRef(null);
  const t = useRef(null);

  const handlePointerEnter = useCallback((e, fromMainButton) => {
    if (e.pointerType === 'touch' && fromMainButton) {
      setShowLocationLayersControls(state => !state);
      return;
    }
    if (t.current) {
      clearTimeout(t.current);
    }
    setShowLocationLayersControls(true);
  }, []);

  const handlePointerLeave = useCallback(e => {
    if (e.pointerType === 'touch') {
      return;
    }
    if (t.current) {
      clearTimeout(t.current);
    }
    t.current = window.setTimeout(() => setShowLocationLayersControls(false), 500);
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowLocationLayersControls(false);
      }
    };
    const closeOnEscapePress = event => {
      if (event.key === 'Escape') {
        setShowLocationLayersControls(false);
      }
    };
    window.addEventListener('keydown', closeOnEscapePress);
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePress);
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <LayersWrapper isDesktop={isDesktop} ref={wrapperRef}>
      <MapLocationIconWrapper
        aria-label="layers"
        onKeyDown={e => {
          // Make accessible from keyboard
          if (e.key === 'Enter') {
            setShowLocationLayersControls(state => !state);
          }
        }}
        onPointerEnter={e => handlePointerEnter(e, true)}
        onPointerLeave={handlePointerLeave}
        type="button"
      >
        <img style={{ height: '24.75px', width: '22px' }} src={MapLocationsIcon} alt="layers" />
      </MapLocationIconWrapper>
      {showLocationLayersControls && (
        <LocationLayersControls
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
        >
          <PlacesLabel>Places of Concern</PlacesLabel>

          <ControlWrapper>
            {!showPlacesIcons && (
              <WarningFlag
                iconStyle={{
                  borderLeft: '1px solid #dedede',
                  borderRight: '1px solid #dedede',
                  borderBottom: '1px solid #dedede',
                  height: '18px',
                  width: '18px',
                }}
                style={{ fontSize: '1em', margin: '0 0 4px 0', padding: '6px 4px' }}
                text="Zoom in map to see places icons"
              ></WarningFlag>
            )}
            <div style={{ padding: '4px 4px 0 0' }}>
              {Object.entries(PLACES_ICONS).map(([key, placeType], idx) => (
                <div key={key} style={{ alignItems: 'center', display: 'flex', gap: '6px' }}>
                  <input
                    id={idx}
                    checked={visiblePlaces[key]}
                    onChange={e => {
                      dispatch(setVisiblePlaces({ ...visiblePlaces, [key]: !visiblePlaces[key] }));
                    }}
                    type="checkbox"
                  />
                  <label htmlFor={idx} style={{ whiteSpace: 'nowrap' }}>
                    {placeType.label}
                  </label>
                  <img alt={placeType.label} src={placeType.icon} style={{ height: '1.2em' }} />
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '6px',
                margin: '4px 4px 0 0',
              }}
            >
              <LinkLikeButton
                onClick={() =>
                  dispatch(
                    setVisiblePlaces(
                      Object.fromEntries(Object.keys(PLACES_ICONS).map(icon => [icon, true]))
                    )
                  )
                }
                style={{ fontSize: '0.9em' }}
              >
                show all
              </LinkLikeButton>
              <LinkLikeButton
                onClick={() =>
                  dispatch(
                    setVisiblePlaces(
                      Object.fromEntries(Object.keys(PLACES_ICONS).map(icon => [icon, false]))
                    )
                  )
                }
                style={{ fontSize: '0.9em', marginLeft: '10px' }}
              >
                hide all
              </LinkLikeButton>
            </div>
          </ControlWrapper>
        </LocationLayersControls>
      )}
    </LayersWrapper>
  );
};
