import React from 'react';
import styled from 'styled-components';
import { ButtonLoader } from './Loaders';
import {
  PRIMARY,
  PRIMARY_DARK,
  MAX_DESKTOP_WIDTH,
  BG_LIGHTER,
  GREY_MEDIUM_DARK,
} from '../../constants/cssVars';
import { trackEvent } from '../../utils/googleAnalytics';
import { BUTTON_CLICK } from '../../constants/analytics';

export const ButtonContent = styled.button`
  background-color: ${({ secondary, color }) => (secondary ? 'none' : color || PRIMARY)};
  color: ${({ fontColor, secondary, isLoading, color }) =>
    fontColor ? fontColor : secondary || isLoading ? color || PRIMARY : '#fff'};
  font-weight: 500;
  font-size: ${({ prominent }) => (prominent ? '1.2' : '1.1')}em;
  box-shadow: ${({ secondary }) => (secondary ? 'none' : '0 2px 8px rgba(0,0,0,.11)')};
  padding: 10px 25px;
  min-width: ${({ wide }) => (wide ? '100%' : '250px')};
  text-align: center;
  cursor: ${({ disabled, isLoading }) => (disabled || isLoading ? 'default' : 'pointer')};
  border-radius: 0px;
  border: ${({ secondary, color }) => (secondary ? `1px solid ${color || PRIMARY}` : 'none')};
  opacity: ${({ disabled, isLoading }) => (disabled || isLoading ? 0.5 : 1)};
  transition: all 0.2s ease;
  &:focus {
    box-shadow: 0 0 2px 4px rgb(93, 196, 255);
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    ${({ fillOnMobile }) => (fillOnMobile ? 'width: min(100%, 400px);' : '')};
  }
`;

export const TableButtonContent = styled(ButtonContent)`
  background-color: ${({ color }) => color || PRIMARY};
  &:hover {
    background-color: ${({ color }) => color || PRIMARY_DARK};
  }
`;

export const InvertedButtonContent = styled(ButtonContent)`
  color: ${PRIMARY_DARK};
  box-sizing: border-box;
  background-color: ${BG_LIGHTER};
  border: 1px solid ${PRIMARY_DARK};
  transition: all 0.2s ease;
  padding: 9px 24px;
  min-width: 100px;
  margin-right: 10px;
`;

const ButtonLikeLinkContent = styled.a`
  background-color: ${PRIMARY};
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.11);
  padding: 10px 25px;
  min-width: ${({ wide }) => (wide ? '100%' : '250px')};
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  transition: all 0.2s ease;
  &:focus {
    box-shadow: 0 0 2px 4px rgb(93, 196, 255);
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: ${({ wide }) => (wide ? 'block' : 'inline-block')};
  cursor: ${({ disabled, isLoading }) => (disabled || isLoading ? 'default' : 'pointer')};
`;

export const InvertedButton = ({ isLoading, disabled, onClick, wrapperStyle, ...props }) => {
  const handleClick = () => {
    trackEvent(BUTTON_CLICK, props.analyticsName || props.children);
    onClick();
  };
  return (
    <ButtonWrapper
      style={wrapperStyle}
      onClick={isLoading ? null : handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <InvertedButtonContent {...props} disabled={disabled} isLoading={isLoading} />
    </ButtonWrapper>
  );
};

export const Button = ({
  isLoading,
  disabled,
  onClick,
  wide,
  wrapperStyle,
  prominent,
  ...props
}) => {
  const handleClick = () => {
    if (!isLoading && onClick) {
      trackEvent(BUTTON_CLICK, props.analyticsName || props.children);
      onClick();
    }
  };
  return (
    <ButtonWrapper
      style={wrapperStyle}
      wide={wide}
      onClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <ButtonContent
        {...props}
        wide={wide}
        disabled={disabled}
        prominent={prominent}
        isLoading={isLoading}
      />
    </ButtonWrapper>
  );
};

const ButtonWithLinkStyle = styled.button`
  background-color: transparent;
  color: ${PRIMARY};
  text-decoration: underline;
  border: none;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  font-size: 1em;
`;

const LoadingText = styled.p`
  color: ${GREY_MEDIUM_DARK};
  font-weight: 600;
  font-style: italic;
  margin: 0px;
  margin-top: 3px;
`;

export const LinkLikeButton = ({ onClick, id, style, analyticsName, children, isLoading }) => {
  const handleClick = () => {
    trackEvent(BUTTON_CLICK, analyticsName || children);
    onClick();
  };
  if (isLoading) {
    return <LoadingText>Loading ...</LoadingText>;
  }
  return (
    <ButtonWithLinkStyle id={id} type="button" style={style} onClick={handleClick}>
      {children}
    </ButtonWithLinkStyle>
  );
};

// External link (<a> tag) that looks like a button
// the "to" prop should be the url address to visit on click
export const ButtonLikeLink = ({ to, wide, wrapperStyle, prominent, ...props }) => {
  return (
    <ButtonWrapper style={wrapperStyle} wide={wide}>
      <ButtonLikeLinkContent
        {...props}
        target="_blank"
        rel="noreferrer noopener"
        href={to}
        wide={wide}
        prominent={prominent}
      />
    </ButtonWrapper>
  );
};
