import { useSelector } from "react-redux";
import { addressesSelector } from "../selectors/entities";
import { customAddressesSelector, includeStateLegislatorsInAddressesSelector } from "../selectors/general";
import { getStateLegislatorAddresses } from "../utils/helpers";
import { useRange } from "./useRange";

// Return current queried addresses, including state legislators if checkbox is marked
export const useCurrentAddresses = (notice) => {
  const includeStateLegislators = useSelector(includeStateLegislatorsInAddressesSelector);
  const customAddresses = useSelector(customAddressesSelector);
  const [,, rangeInMiles] = useRange();

  let addresses = useSelector(state => addressesSelector(state, rangeInMiles));

  // Add state legislator addresses to start of array if indicated
  if (notice && includeStateLegislators) {
    addresses = [...getStateLegislatorAddresses(notice.stateLegislators), ...addresses];
  }

  // Add custom addresses to start of array if present
  if (Array.isArray(customAddresses) && customAddresses.length > 0) {
    addresses = [...customAddresses, ...addresses];
  }

  return addresses;
}