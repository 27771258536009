import { createSelector } from 'reselect';

export const modalsSelector = state => state.modals;
export const showNewUserModalSelector = state => state.modals.showNewUserModal;
export const showAboutUsModalSelector = state => state.modals.showAboutUsModal;
export const showEmailSubscribeModalSelector = state => state.modals.showEmailSubscribeModal;
export const showEmailUnsubscribeOptionSelector = state => state.modals.showEmailUnsubscribeOption;
export const showAutopopulateEmailSelector = state => state.modals.autopopulateEmail || '';
export const showAutopopulateCountiesSelector = state => state.modals.autopopulateCounties || [];
export const showCreateCampaignModalSelector = state => state.modals.showCreateCampaignModal;
export const showIndustryTypeCodesModalSelector = state => state.modals.showIndustryTypeCodesModal;
export const showCustomAddressesModalSelector = state => state.modals.showCustomAddressesModal;
export const showLearnMoreModalSelector = state => state.modals.showLearnMoreModal;
export const showEditNoticeModal = state => state.modals.showEditNoticeModal;

export const anyModalIsActiveSelector = createSelector([modalsSelector], modals =>
  Object.values(modals).some(value => value === true)
);
