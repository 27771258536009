import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { List, AutoSizer } from 'react-virtualized';
import moment from 'moment';
import styled from 'styled-components';

import mapLocationsIcon from '../../../assets/icons/map_location.svg';
import alertIcon from '../../../assets/icons/alert-icon.svg';
import searchIcon from '../../../assets/icons/search.svg';
import { setFilterSortToDefault, setNoticesListView, setSearch } from '../../../actions/notices';
import { COUNTIES_WITH_ADDRESSES } from '../../../constants/counties';
import {
  GREY_DARK,
  PRIMARY,
  GREY_MEDIUM,
  GREY_LIGHTEST,
  MAX_DESKTOP_WIDTH,
  GREY_LIGHTER,
  GREY_MEDIUM_DARK,
  PRIMARY_DARK,
} from '../../../constants/cssVars';
import {
  NOTICE_TYPE_TO_FULL_NAME,
  FILTERS_DROPDOWN_ID,
  SORT_DROPDOWN_ID,
  DATE_RANGE_DROPDOWN_ID,
  MILEAGE_SORTS,
  RESIDENCES_IN_MILE,
  MILEAGE_SORTS_TO_MILES,
  NAME_SEARCH,
} from '../../../constants/notices';
import { useFilteredNotices } from '../../../hooks/useFilteredNotices';
import { useFiltersSortActiveStatus } from '../../../hooks/useFiltersSortActiveStatus';
import { useNoticeListDescription } from '../../../hooks/useNoticeListDescription';
import { useNoticeParamString } from '../../../hooks/useSyncNoticeFilterParams';
import {
  activeDropdownSelector,
  countySelector,
  searchSelector,
  sortSelector,
} from '../../../selectors/notices';
import { LinkLikeButton } from '../../general/Button';
import { DaysTillPublicActionDeadline, getActionDeadlineHasPassed } from '../Map';
import ShareSocial from '../ShareSocial';
import { NoticesSidebar } from '../Sidebar';
import { WarningFlag } from '../WarningFlag';

import NoticeFilterAndSort from './NoticeFilterAndSort';

const NoticeTile = styled(Link)`
  display: block;
  width: calc(100% - 11px);
  text-decoration: none;
  color: ${GREY_DARK};
  background-color: ${({ active }) => (active ? GREY_LIGHTEST : GREY_LIGHTER)};
  margin-bottom: 10px;
  border: 1px solid ${({ active }) => (active ? PRIMARY : GREY_MEDIUM)};
  border-left: 5px solid
    ${({ active, $important }) => ($important ? PRIMARY_DARK : active ? PRIMARY : GREY_MEDIUM)};
  > div {
    padding: 10px;
    h4 {
      margin: 0px;
      margin-bottom: 5px;
    }
    > p {
      margin: 0px;
      margin-bottom: 2px;
      font-size: 0.85em;
    }
  }
`;

// PA List height expands to fill flex box
const PAList = styled.div`
  margin-top: 10px;
  position: relative;
  height: calc(100vh);
`;

const SearchWrapper = styled.div`
  position: relative;
  > img {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
  > input {
    padding-right: 35px !important;
  }
`;

const SearchNote = styled.div`
  margin-top: 8px;
  margin-bottom: 2px;
  color: ${GREY_DARK};
  font-size: 0.85em;
`;

const NoticesDescription = styled.div`
  margin-top: 8px;
  margin-bottom: 2px;
  color: ${GREY_MEDIUM_DARK};
  font-size: 0.85em;
  font-style: italic;
  span {
    color: ${GREY_DARK};
    font-weight: 600;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;
  gap: 8px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    align-items: flex-start;
  }
`;

const ButtonFlexWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
  }
`;

const ListButton = styled.button`
  font-size: 1em;
  background-color: white;
  border: 1px solid ${PRIMARY};
  color: ${PRIMARY};
  display: none;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
    white-space: nowrap;
  }
`;

const FixedDropdownBackdrop = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const NoNoticesFound = styled.div`
  margin: 20px 10px;
  text-align: center;
  color: ${GREY_DARK};
  font-style: italic;
  font-size: 0.9em;
`;

// Return the corresponding number of residences for a notice given the active sort
const getResidencesInSortMileage = (notice, sortBy) => {
  if (MILEAGE_SORTS.includes(sortBy)) {
    return notice[sortBy];
  }
  return notice[RESIDENCES_IN_MILE];
};

export const AllNoticesSidebar = () => {
  const filteredNotices = useFilteredNotices();
  const { anyFilterActive } = useFiltersSortActiveStatus();
  const searches = useSelector(searchSelector);
  const searchStr = searches[NAME_SEARCH];
  const [showSearch, setShowSearch] = useState(false);
  const { aahOrApp } = useParams();
  const sortBy = useSelector(sortSelector);
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);
  const noticeListDescription = useNoticeListDescription();

  // Checks whether the selected county on the sidebar is using the NASA API
  const selectedCounty = useSelector(countySelector);
  const nasaCounty = !COUNTIES_WITH_ADDRESSES.includes(selectedCounty);

  // if there are a lot of notices, the map / filtering will load slower, so display a warning message
  const showQuantityWarning = filteredNotices.length > 500;
  const showDropdownBackdrop =
    activeDropdown === FILTERS_DROPDOWN_ID ||
    activeDropdown === DATE_RANGE_DROPDOWN_ID ||
    activeDropdown === SORT_DROPDOWN_ID;

  // Will add current notice params to the link path if necessary
  const noticeParams = useNoticeParamString();
  const noticeQs = noticeParams ? `&${noticeParams}` : '';

  const rowRenderer = ({ index, style }) => {
    const notice = filteredNotices[index];

    return (
      <div style={style} key={`notice_tile_${notice._id}`}>
        <NoticeTile
          active={getActionDeadlineHasPassed(notice) ? 0 : 1}
          $important={notice.important}
          to={`/${aahOrApp}/notices/?notice_id=${notice._id}${noticeQs}`}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {notice.facilityName}
              </h4>
              {notice.important && (
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={alertIcon}
                  alt="important icon"
                />
              )}
            </div>
            <p style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {notice.principal}
            </p>
            {notice.issuanceDate ? (
              <p>Issued on {moment(new Date(notice.issuanceDate)).utc().format('MM/DD/YYYY')}</p>
            ) : (
              <p>
                Mailed on {moment(new Date(notice.dateNoticeMailed)).utc().format('MM/DD/YYYY')}
              </p>
            )}
            {/* Unknown residences/residents only when values is null/undefined. Using nullish coalescing operator */}
            <p>
              <b>{(notice.location && getResidencesInSortMileage(notice, sortBy)) ?? 'Unknown'}</b>{' '}
              {COUNTIES_WITH_ADDRESSES.includes(notice.county) ? 'residences' : 'residents'} in{' '}
              {MILEAGE_SORTS.includes(sortBy) ? MILEAGE_SORTS_TO_MILES[sortBy] : '1 mile'}
            </p>
            <p style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <b>{notice.location ? notice.placesInMile : 'Unknown'}</b> places of concern (
              <img
                alt="places"
                src={mapLocationsIcon}
                style={{ height: '1.1em', transform: 'translateY(4px)' }}
              />
              ) in 1 mile
            </p>
            <p>{NOTICE_TYPE_TO_FULL_NAME[notice.noticeType]}</p>
            <p>
              {notice.publicTotalComments ? <b>{notice.publicTotalComments}</b> : 'No'} public
              comments and requests
            </p>
            <DaysTillPublicActionDeadline notice={notice} />
          </div>
        </NoticeTile>
      </div>
    );
  };

  // React-virtualized-list needs to have a specific height set.
  // When the "reset filters" & search settings are active, they need to be offset from the list height to prevent overflow.
  let additionalHeightOffset = 0;
  if (showSearch) {
    additionalHeightOffset += 70; // Approximate pixel height of search bar & text
  }
  if (showQuantityWarning) {
    additionalHeightOffset += 50; // Approximate pixel height of warning message
  }

  return (
    <NoticesSidebar>
      {showDropdownBackdrop && <FixedDropdownBackdrop />}
      <div>
        <FlexWrapper>
          <h3 style={{ fontSize: '1.1em', lineHeight: '1.1.em', margin: '0', width: '100%' }}>
            Permit Notices ({filteredNotices.length})
          </h3>
          <ButtonFlexWrapper>
            <ShareSocial shareHeaderText="Share this view" />
            <ListButton
              onClick={() => {
                dispatch(setNoticesListView(false));
              }}
            >
              Map View
            </ListButton>
          </ButtonFlexWrapper>
        </FlexWrapper>
      </div>
      <NoticeFilterAndSort
        setShowSearch={setShowSearch}
        showSearch={showSearch}
        nasaCounty={nasaCounty}
      />
      {showSearch && (
        <>
          <SearchNote>Search facility, principal, or industry name</SearchNote>
          <SearchWrapper>
            <img src={searchIcon} alt="" />
            <input
              id="noticesSearchInput"
              onChange={e => dispatch(setSearch(NAME_SEARCH, e.target.value))}
              value={searchStr || ''}
            />
          </SearchWrapper>
        </>
      )}
      <NoticesDescription>
        {noticeListDescription}
        {` `}
        {anyFilterActive && (
          <LinkLikeButton
            style={{ fontStyle: 'italic', textTransform: 'uppercase' }}
            onClick={() => dispatch(setFilterSortToDefault())}
          >
            Reset
          </LinkLikeButton>
        )}
      </NoticesDescription>
      {nasaCounty && (
        <WarningFlag
          text={
            selectedCounty === 'ALL COUNTIES' &&
            'The number of people in some counties are approximations'
          }
          showLearnMore={true}
        />
      )}
      {showQuantityWarning && (
        <WarningFlag text="Site may be slow due to the number of notices shown. Filter notices to improve speed." />
      )}
      {filteredNotices.length < 1 && (
        <NoNoticesFound>No notices found for this selection</NoNoticesFound>
      )}
      <PAList additionalHeightOffset={additionalHeightOffset}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              rowCount={filteredNotices.length}
              rowHeight={232}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      </PAList>
    </NoticesSidebar>
  );
};
