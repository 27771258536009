import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import aahLogo from '../../assets/aah_logo.png';
import aahNav from '../../assets/aah_nav.png';
import styled from 'styled-components'
import { setAboutUsModal } from '../../actions/modals';
import { showAboutUsModalSelector } from '../../selectors/modals';
import { TextLink } from '../general/Common';
import { Modal } from '../general/Modal';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const AAHLogo = styled.img`
  width: 90px;
  height: 90px;
`;

const Logos = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`

export const AboutUsModal = () => {
  const showModal = useSelector(showAboutUsModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setAboutUsModal(false));
  }
  
  if (!showModal) {
    return null;
  }

  return (

    <Modal closeOnOuterClick={true} onClose={onClose} headerImg={aahNav} style={{ width: 'min(95%, 550px)', backgroundImage: `url(${aahNav})`, backgroundRepeat: 'repeat-x', maxHeight: '95vh', boxSizing: 'border-box', overflowY: 'scroll' }}>
      <Title>About AirMail</Title>
      <div style={{ fontSize: '0.9em' }}>
        <p>
          AirMail is a free tool designed to help advocates track and take action on polluter permit notices in their area.
        </p>
        <p>
          Feel free to share this site with others.  
        </p>
        <p>
          AirMail is brought to you by <TextLink to="https://airalliancehouston.org/">Air Alliance Houston</TextLink>, a non-profit advocacy organization working to reduce the public health impacts from air pollution and advance environmental justice through applied research, education, and advocacy. 
          This website was developed by <TextLink to="https://www.januaryadvisors.com/">January Advisors</TextLink>.
        </p>
        <Logos>
          <AAHLogo src={aahLogo} alt="" />
        </Logos>
        <div style={{ marginTop: '15px' }}>
          Having trouble with the tool? <TextLink to="https://docs.google.com/forms/d/e/1FAIpQLSdechc8OqDeZxbROTaFriKv5pMN0Dh20zXqrtsCl1HmzjK-5w/viewform?usp=sf_link">
            Report an issue.
          </TextLink>
        </div>
      </div>
    </Modal>
  )
}