import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'redux-query-react';

import { getUserSubscriptionsQuery } from '../../actions/queries';
import { COUNTIES_OPTIONS, COUNTY_ID_TO_FRIENDLY_NAME } from '../../constants/counties';
import {
  useUpdateEmailSubscription,
  useDeleteEmailSubscription,
  useUpdateFilterSubscription,
} from '../../hooks/messagesAndRequests';
import {
  currentUserFiltersSelector,
  userSubscriptionCountiesSelector,
} from '../../selectors/entities';
import { Button, LinkLikeButton } from '../general/Button';
import { GREY_DARK } from '../../constants/cssVars';

/*  
  This component allows users to manage their subscription preferences. 
  * Accessing through the user settings, this page will display an option to subscribe if the user doesn't have a subscription 
  or update their subscription in two ways; changing the counties they are subscribed to or unsubscribing.
  * Accessing through their email, this page will only display the subscription update options (changing counties / unsubscribing).
  Once the user has made changes to their subscription, they will redirected to the main page.
*/
export const EmailSubscriptionsPreferences = ({ userEmail, redirectToMap, settingsPage }) => {
  const navigate = useNavigate();

  const [{ isFinished }] = useRequest(getUserSubscriptionsQuery({ userEmail }));
  const userSubscriptionCounties = useSelector(userSubscriptionCountiesSelector);

  const userFilters = useSelector(currentUserFiltersSelector);

  const [updateSubscription] = useUpdateEmailSubscription();
  const [updateFilterSubscription] = useUpdateFilterSubscription();
  const [unsubscribe] = useDeleteEmailSubscription();

  const [selectedCounties, setSelectedCounties] = useState(userSubscriptionCounties);
  const userSubscriptionFilters = userFilters.filter(f => f.subscribed);
  const [selectedFilters, setSelectedFilters] = useState(userSubscriptionFilters);

  useEffect(() => {
    const userCounties = userSubscriptionCounties.map(county => {
      return { label: COUNTY_ID_TO_FRIENDLY_NAME[county], value: county };
    });
    setSelectedCounties(userCounties);
  }, [userSubscriptionCounties]);

  useEffect(() => {
    setSelectedFilters(userFilters.filter(f => f.subscribed));
  }, [userFilters]);

  const handleSelectCounties = selectedCountiesList => {
    setSelectedCounties(selectedCountiesList);
  };

  const handleSelectFilters = selectedFiltersList => {
    setSelectedFilters(selectedFiltersList);
  };

  // If subscription exists, it updates the user's subscription with the new county selection
  // Otherwise, it creates a new subscription with the selected counties
  const handleUpdateSubscription = () => {
    const counties = selectedCounties.map(county => county.value);
    updateSubscription({ userEmail, counties, isCurrentUser: true });
    redirectToMap && navigate('/');
  };

  const handleUpdateFilterSubscription = () => {
    const filters = selectedFilters.map(f => f._id);
    updateFilterSubscription(filters);
  };

  const handleUnsubscribe = () => {
    const confirmedDelete = window.confirm(
      `Are you sure you want to unsubscribe this user from all emails?`
    );
    if (confirmedDelete) {
      unsubscribe({ userEmail, isCurrentUser: true });
      redirectToMap && navigate('/');
    }
  };

  // Disables the save/subscribe button if the user has not changed their county selection or has not selected any counties
  const checkCorrectCountySelection = () => {
    if (selectedCounties.length === 0) {
      return true;
    }
    const selectedCountiesArray = selectedCounties.map(county => county.value);

    return (
      JSON.stringify([...selectedCountiesArray].sort()) ===
      JSON.stringify([...userSubscriptionCounties].sort())
    );
  };

  const checkCorrectFilterSelection = () => {
    const selectedFilterIds = selectedFilters.map(filter => filter._id).sort();
    const userSubsciptionFilterIds = userSubscriptionFilters.map(filter => filter._id).sort();
    return JSON.stringify(selectedFilterIds) === JSON.stringify(userSubsciptionFilterIds);
  };

  if (!isFinished) {
    return <p>Loading...</p>;
  }

  // If user tries to access this page through their email and they have already deleted their subscription, display error message
  if (!settingsPage && userSubscriptionCounties.length === 0) {
    return (
      <p>
        No subscription found. Please visit{' '}
        <a href="https://aah-airmail.org/app/notices/">AirMail</a> to subscribe.
      </p>
    );
  }

  return (
    <>
      <h3>Subscriptions -- Counties</h3>
      {userSubscriptionCounties.length > 0 ? (
        <p style={{ marginBottom: '20px' }}>
          Update your preferences for weekly notice summary emails for counties:
        </p>
      ) : (
        <p style={{ marginBottom: '20px' }}>
          Subscribe to receive weekly email updates on the latest permits issued in specific
          counties.
        </p>
      )}

      <Multiselect
        id="counties-email-subscription"
        options={COUNTIES_OPTIONS}
        selectedValues={selectedCounties}
        onSelect={handleSelectCounties}
        onRemove={handleSelectCounties}
        displayValue="label"
        showCheckbox
        closeIcon="cancel"
        avoidHighlightFirstOption
        keepSearchTerm
        emptyRecordMsg="No counties found"
        style={{
          searchBox: {
            borderRadius: '0px',
            minHeight: '39px',
            marginTop: '5px',
          },
          inputField: { height: '20px' },
          chips: { background: '#00A7D9' },
        }}
      />

      <Button
        onClick={handleUpdateSubscription}
        disabled={checkCorrectCountySelection()}
        style={{ margin: '30px 0px' }}
      >
        {userSubscriptionCounties.length > 0 ? 'Save Changes' : 'Subscribe'}
      </Button>
      <hr />
      <h3>Subscriptions -- Filters</h3>
      {userSubscriptionFilters.length > 0 ? (
        <p style={{ marginBottom: '20px' }}>
          Update your preferences for weekly notice summary emails based on your saved filters.
        </p>
      ) : (
        <p style={{ marginBottom: '20px' }}>
          Subscribe to receive weekly email updates on the latest permits based on your saved
          filters.
        </p>
      )}
      {userFilters.length > 0 ? (
        <>
          <Multiselect
            id="filters-email-subscription"
            options={userFilters}
            selectedValues={selectedFilters}
            onSelect={handleSelectFilters}
            onRemove={handleSelectFilters}
            displayValue="label"
            showCheckbox
            closeIcon="cancel"
            avoidHighlightFirstOption
            keepSearchTerm
            emptyRecordMsg="No saved filters"
            style={{
              searchBox: {
                borderRadius: '0px',
                minHeight: '39px',
                marginTop: '5px',
              },
              inputField: { height: '20px' },
              chips: { background: '#00A7D9' },
            }}
          />

          <Button
            onClick={handleUpdateFilterSubscription}
            disabled={checkCorrectFilterSelection()}
            style={{ margin: '30px 0px' }}
          >
            {userSubscriptionFilters.length > 0 ? 'Save Changes' : 'Subscribe'}
          </Button>
        </>
      ) : (
        <p style={{ fontSize: '0.85em', color: GREY_DARK }}>
          *You have not yet saved any filters. To create one, click on the bookmark icon next to the
          notice filter buttons on the main application page.
        </p>
      )}

      {(userSubscriptionCounties.length > 0 || userSubscriptionFilters.length > 0) && (
        <>
          <hr></hr>
          <h3>Unsubscribe from all</h3>
          <p style={{ margin: '20px 0px' }}>We're sorry to see you go!</p>
          <LinkLikeButton onClick={handleUnsubscribe}>
            Unsubscribe from AirMail Notifications
          </LinkLikeButton>
        </>
      )}
    </>
  );
};
