import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { JALogo } from './general/JALogo';
import { StyledInternalLink } from './general/Common';
import { Section } from './general/Section';
import aahLogo from '../assets/aah_logo_white.png';
import { GREY_DARK, PRIMARY_DARK, TEMPEST } from '../constants/cssVars';

const AAHLogo = styled.img`
  width: 100px;
  height: 100px;
`;

const Content = styled.div`
  color: #fff;
  font-size: 0.8em;
  font-family: 'Aleo', serif;
`;

const FooterList = styled.ul`
  list-style-type:none;
  padding-inline-start: 0px;
  margin: 10px 0px;
  > li {
    font-weight: 400;
    color: #fff;

    display: inline-block;
    margin: 0px 10px;
    cursor: pointer;
  }
`;

export const Footer = () => {
  const history = useNavigate();

  return (
    <footer>
      <Section color={TEMPEST} templateColumns="42% 16% 42%"  zIndex={500}>
        <Content>
            AirMail is a free tool designed to help advocates track and take action on polluter permit notices in their area.
            <br/><br/>
            Feel free to share this site with others.  
        </Content>
        <div style={{ textAlign: 'center' }}>
          <AAHLogo src={aahLogo} alt="" />
        </div>
        <Content>
            AirMail is brought to you by Air Alliance Houston, a non-profit advocacy organization working to reduce the public health impacts from air pollution and advance environmental justice through applied research, education, and advocacy. 
            This website was developed by January Advisors.
        </Content>
      </Section>
    </footer>
  )
}