import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  HEADER_HEIGHT,
  PRIMARY_DARK,
  PRIMARY,
  SHADOW_CRISP,
  GREY_DARK,
  SIDEBAR_WIDTH,
  GREY_MEDIUM_LIGHT,
} from '../constants/cssVars';
import { Link, useNavigate } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout.js';
import { trackEvent } from '../utils/googleAnalytics';
import { BUTTON_CLICK } from '../constants/analytics';
import { LinkLikeButton } from './general/Button';
import questionMarkIcon from '../assets/icons/question_mark.svg';
import emailIcon from '../assets/icons/email.svg';
import personIcon from '../assets/icons/person.svg';
import { ACCOUNT_DROPDOWN_ID } from '../constants/notices';
import { useDispatch, useSelector } from 'react-redux';
import { activeDropdownSelector } from '../selectors/notices';
import { setActiveDropdown, setNoticesListView } from '../actions/notices';
import { currentUserSelector } from '../selectors/entities';
import { onMobile } from '../utils/helpers';
import { setAboutUsModal, setEmailSubscribeModal } from '../actions/modals';

const HeaderWrapper = styled.nav`
  width: 100%;
  // width: calc(100% - ${SIDEBAR_WIDTH}px);
  height: ${HEADER_HEIGHT}px;
  background-color: #3e4346;
  display: flex;
  color: #fff;
  justify-content: ${({ centerItems }) => (centerItems ? 'center' : 'space-between')};
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 510;
  box-shadow: ${SHADOW_CRISP};
  position: absolute;
`;

export const HeaderOffset = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DropdownItems = styled.div`
  position: absolute;
  top: 1.4em;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  min-width: 280px;
  display: flex;
  color: ${GREY_DARK};
  flex-direction: column;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 18px;
    height: 18px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const StyledLink = styled(Link)`
  margin: 0px 15px;
  color: ${PRIMARY_DARK};
  text-decoration: none;
  font-family: 'Aleo', serif;
  border-bottom: ${({ active }) => (active === 1 ? `2px solid ${PRIMARY}` : 'none')};
  &:hover {
    color: ${PRIMARY};
  }
`;

const AccountWrapper = styled.div`
  > div {
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  }
  > a,
  button {
    text-decoration: none;
    font-weight: 500;
    &:hover {
      color: ${PRIMARY_DARK} !important;
    }
  }
  > a {
    margin: 10px 0px;
  }
`;

const AccountSection = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);
  const currentUser = useSelector(currentUserSelector);
  const { routeId } = useParams();
  const settingsActive = routeId === 'settings' ? 1 : 0;

  const handleClick = dropdownId => () => {
    dispatch(setActiveDropdown(activeDropdown !== dropdownId ? dropdownId : null));
  };

  const handleLogoutClick = () => {
    trackEvent(BUTTON_CLICK, 'Logout');
    logout();
  };

  const url = new URL(window.location.href);
  url.searchParams.set('emailSubscription', true);

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <LinkLikeButton
        style={{
          fontSize: '0.9em',
          color: '#eee',
          textDecoration: 'none',
        }}
        onClick={() => dispatch(setAboutUsModal(true))}
      >
        {!onMobile() ? (
          'About us'
        ) : (
          <IconWrapper>
            <img src={questionMarkIcon} aria-label="about" alt="" />
          </IconWrapper>
        )}
      </LinkLikeButton>

      {!currentUser && (
        <LinkLikeButton
          style={{
            marginLeft: '20px',
            fontSize: '0.9em',
            color: '#eee',
            textDecoration: 'none',
          }}
          onClick={() =>
            dispatch(setEmailSubscribeModal(true)) &&
            // add the emailSubscription param to the url
            window.history.replaceState(null, null, url)
          }
        >
          {!onMobile() ? (
            'Subscribe'
          ) : (
            <IconWrapper>
              <img src={emailIcon} aria-label="email" alt="" />
            </IconWrapper>
          )}
        </LinkLikeButton>
      )}

      <DropdownWrapper>
        <LinkLikeButton
          id={`${ACCOUNT_DROPDOWN_ID}_btn`}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.9em',
            margin: '0px 20px',
            textDecoration: 'none',
            color: '#eee',
          }}
          onClick={currentUser ? handleClick(ACCOUNT_DROPDOWN_ID) : () => navigate('/login')}
        >
          {currentUser ? (
            <>
              {!onMobile() && currentUser.firstName}
              <Icon
                style={{ marginLeft: !onMobile() && '10px' }}
                src={personIcon}
                aria-label="account"
                alt=""
              />
            </>
          ) : (
            <>{!onMobile() ? 'Log in' : <Icon src={personIcon} aria-label="account" alt="" />}</>
          )}
        </LinkLikeButton>
        {activeDropdown === ACCOUNT_DROPDOWN_ID && (
          <DropdownItems id={ACCOUNT_DROPDOWN_ID}>
            {currentUser && (
              <AccountWrapper>
                <div>
                  Logged in as {currentUser.firstName} {currentUser.lastName}
                </div>
                {currentUser && (
                  <Link
                    onClick={() => dispatch(setActiveDropdown(null))}
                    style={{ color: PRIMARY, display: 'block' }}
                    to="/app/settings"
                    active={settingsActive}
                  >
                    Settings
                  </Link>
                )}
                <LinkLikeButton style={{ color: PRIMARY }} onClick={handleLogoutClick}>
                  Log out
                </LinkLikeButton>
              </AccountWrapper>
            )}
          </DropdownItems>
        )}
      </DropdownWrapper>
    </div>
  );
};

export const Header = ({ countyNavOnly }) => {
  const dispatch = useDispatch();
  const { routeId } = useParams();
  const noticesActive = routeId === 'notices' ? 1 : 0;

  return (
    <header>
      <HeaderWrapper centerItems={countyNavOnly}>
        {!countyNavOnly && (
          <StyledLink
            onClick={() => dispatch(setNoticesListView(false))}
            to="/app"
            style={{ fontWeight: '700', color: '#fff' }}
          >
            AirMail
          </StyledLink>
        )}
        {!noticesActive && (
          <StyledLink style={{ color: '#fff' }} to="/app">
            Back to Map
          </StyledLink>
        )}
        {!countyNavOnly && <AccountSection />}
      </HeaderWrapper>
    </header>
  );
};
