import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { mutateAsync } from 'redux-query';
import {
  loginQuery,
  forgotPasswordQuery,
  resetPasswordQuery,
  addUserQuery,
  deleteUserQuery,
  getNumberOfAddressesQuery,
  getNumberOfAddressesNASACountiesQuery,
  uploadAddressesQuery,
  createCampaignQuery,
  approveCampaignQuery,
  updateCountyQuery,
  updateEmailSubscriptionQuery,
  deleteEmailSubscriptionQuery,
  getUserSubscriptionEmailQuery,
  saveFilterQuery,
  deleteFilterQuery,
  editNoticeDetailsQuery,
  getNumberOfPlacesQuery,
  updateFilterSubscriptionQuery,
} from '../actions/queries';
import { SESSION_EXP_STORAGE_KEY } from '../constants/general';
import { tokenSelector } from '../selectors/entities';
import { statusIsGood, statusIsUnauthorized } from '../utils/helpers';
import { useHandleUnauthorized } from './useHandleUnauthorized';

const ERROR_MESSAGE = 'An error occurred. Please try again later.';

// Returns common vars/functions used across editors
const useRequestBasics = () => {
  const dispatch = useDispatch();
  const jwtToken = useSelector(tokenSelector);
  const handleUnauthorized = useHandleUnauthorized();
  return [dispatch, jwtToken, handleUnauthorized];
};

// Hook we can reuse for simple queries
const useSimpleQuery = (query, onSuccess, successMsg, errorMsg, onError) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, , handleUnauthorized] = useRequestBasics();

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(query(values))).then(({ status, body, text }) => {
      if (statusIsGood(status) && (body || text)) {
        let responseContent = body;
        // if there isn't a body, convert text to JSON to be the response content
        if (!body && text) {
          responseContent = JSON.parse(text);
        }
        setSubmitting(false);
        successMsg && toast.success(successMsg);
        onSuccess && onSuccess(responseContent);
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        onError && onError(body);
        setSubmitting(false);
        toast.error((body && body.msg) || errorMsg || ERROR_MESSAGE);
      }
    });
  };
  return [submit, submitting];
};

// User login query
export const useLoginQuery = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const submit = values => {
    values.username = values.email;
    setSubmitting(true);
    dispatch(mutateAsync(loginQuery(values))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        const sessionExpDate = new Date(body.expirationTime);
        const sessionExpTimeMs = sessionExpDate.getTime();
        localStorage.setItem(SESSION_EXP_STORAGE_KEY, sessionExpTimeMs);
        onSuccess();
      } else {
        setSubmitting(false);
        toast.error('Could not log in with the provided credentials');
      }
    });
  };
  return [submit, submitting];
};

// Forgot password
export const useForgotPasswordQuery = () => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(forgotPasswordQuery(values.email))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success('Success! Check your inbox for instructions.');
      } else {
        setSubmitting(false);
        toast.error('Could not reset password.');
      }
    });
  };
  return [submit, submitting];
};

// Reset password
export const useResetPasswordQuery = (onSuccess, token) =>
  useSimpleQuery(
    resetPasswordQuery(token),
    onSuccess,
    'Successfully updated password!',
    'Could not reset password.'
  );

// Admin update to add a user
export const useAddUser = onSuccess =>
  useSimpleQuery(addUserQuery, onSuccess, 'Added user successfully!', 'Failed to add user');

// Admin update to delete a user
export const useDeleteUser = () =>
  useSimpleQuery(deleteUserQuery, null, 'Deleted user successfully!', 'Failed to delete user');

// Admin upload addresses Stannp query
export const useUploadAddresses = onSuccess =>
  useSimpleQuery(uploadAddressesQuery, onSuccess, null, 'Upload failed :(');

// Admin create Stannp campaign query
export const useCreateCampaignQuery = (onSuccess, onError) =>
  useSimpleQuery(createCampaignQuery, onSuccess, null, 'Failed to create campaign :(', onError);

// Admin approve Stannp campaign query
export const useApproveCampaignQuery = onSuccess =>
  useSimpleQuery(approveCampaignQuery, onSuccess, null, 'Failed to approve campaign :(');

// Public request to get number of notices for a given lat/lng/radiusMiles
export const useGetNumberOfAddresses = onSuccess =>
  useSimpleQuery(getNumberOfAddressesQuery, onSuccess, null, 'Failed to fetch number of addresses');

// Public request to get number of addresses in NASA counties for a given lat/lng/radiusMiles
export const useGetNumberOfAddressesNASACounty = onSuccess =>
  useSimpleQuery(
    getNumberOfAddressesNASACountiesQuery,
    onSuccess,
    null,
    'Failed to fetch number of addresses for NASA county'
  );

export const useGetNumberOfPlaces = onSuccess =>
  useSimpleQuery(
    getNumberOfPlacesQuery,
    onSuccess,
    null,
    'Failed to fetch number of places of concern'
  );

// User's county update
export const useUpdateCountyQuery = onSuccess =>
  useSimpleQuery(
    updateCountyQuery,
    onSuccess,
    'Successfully updated primary county',
    'Could not update primary county'
  );

// Add an email subscription or update it if it already exists
export const useUpdateEmailSubscription = onSuccess =>
  useSimpleQuery(
    updateEmailSubscriptionQuery,
    onSuccess,
    'Email subscription successfully updated',
    'Could not subscribe :('
  );

// Delete an email subscription
export const useDeleteEmailSubscription = onSuccess =>
  useSimpleQuery(
    deleteEmailSubscriptionQuery,
    onSuccess,
    'Successfully unsubscribed',
    'Could not unsubscribe :('
  );

// Get all the counties a user is subscribed to
export const useGetUserSubscriptionEmail = onSuccess =>
  useSimpleQuery(
    getUserSubscriptionEmailQuery,
    onSuccess,
    null,
    'Could not find an email subscription :('
  );

export const useSaveFilter = onSuccess =>
  useSimpleQuery(saveFilterQuery, onSuccess, 'Filter successfully saved', 'Could not save filter');

export const useDeleteFilter = onSuccess =>
  useSimpleQuery(
    deleteFilterQuery,
    onSuccess,
    'Filter successfully deleted',
    'Could not delete filter'
  );

export const useUpdateFilterSubscription = onSuccess =>
  useSimpleQuery(
    updateFilterSubscriptionQuery,
    onSuccess,
    'Successfully subscribed!',
    'Could not subscribe'
  );

export const useEditNoticeDetails = onSuccess =>
  useSimpleQuery(
    editNoticeDetailsQuery,
    onSuccess,
    'Notice details successfully updated',
    'Could not update notice details, please try again later'
  );
