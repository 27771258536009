import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';

import { setActiveDropdown, setActiveFilter } from '../../../actions/notices';
import backIcon from '../../../assets/icons/back_primary.svg';
import { GREY_DARK, GREY_MEDIUM, PRIMARY, PRIMARY_DARK } from '../../../constants/cssVars';
import { LinkLikeButton } from '../../general/Button';
import { useDeleteFilter, useSaveFilter } from '../../../hooks/messagesAndRequests';
import { currentUserFiltersSelector } from '../../../selectors/entities';
import { activeFilterSelector, filtersQuerySelector } from '../../../selectors/notices';

const DropdownListButton = styled.button`
  border: none;
  background: none;
  color: ${PRIMARY};
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  width: 100%;
  &:hover {
    color: ${PRIMARY_DARK};
  }
  &:focus {
    color: ${PRIMARY_DARK};
  }
  img {
    transform: rotate(180deg);
  }
`;

const SELECT_FILTER_SUBMENU = 'SELECT_FILTER_SUBMENU';
const NEW_FILTER_SUBMENU = 'NEW_FILTER_SUBMENU';

const MenuHeader = ({ header, onClick }) => {
  return (
    <div
      style={{
        borderBottom: `1px solid ${GREY_MEDIUM}`,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <button onClick={onClick} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
        <img alt="back" src={backIcon} style={{ width: '16px' }} />
      </button>
      <div
        style={{
          fontFamily: "'Aleo', serif",
          textAlign: 'center',
          flexGrow: '1',
        }}
      >
        <span style={{ display: 'inline-block', transform: 'translateX(-12px)' }}>{header}</span>
      </div>
    </div>
  );
};

const SavedFiltersMenu = () => {
  const dispatch = useDispatch();

  const [subMenu, setSubMenu] = useState(null);

  const [filterName, setFilterName] = useState('');

  const [deleteFilter, filterDeleting] = useDeleteFilter();
  const userFilters = useSelector(currentUserFiltersSelector);
  const activeFilter = useSelector(activeFilterSelector);

  const [saveFilter, filterSaving] = useSaveFilter(respBody => {
    const newFilter = respBody.filters[respBody.filters.length - 1];
    dispatch(setActiveFilter({ ...newFilter, label: newFilter.name, value: newFilter._id }));
  });

  const filterQueryParams = useSelector(filtersQuerySelector);

  const mainMenuActive = subMenu === null;
  const selectFiltersActive = subMenu === SELECT_FILTER_SUBMENU;
  const newFilterActive = subMenu === NEW_FILTER_SUBMENU;

  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            position: mainMenuActive ? 'relative' : 'absolute',
            left: mainMenuActive ? '0%' : '-100%',
            transition: 'left .3s ease-in-out',
            top: 0,
            padding: '10px',
          }}
        >
          <DropdownListButton onClick={() => setSubMenu(NEW_FILTER_SUBMENU)}>
            <div>Save Current Filter Selection</div>
            <img alt="forward" src={backIcon} style={{ width: '16px' }} />
          </DropdownListButton>
          <DropdownListButton onClick={() => setSubMenu(SELECT_FILTER_SUBMENU)}>
            <div>Load a Saved Filter</div>
            <img alt="forward" src={backIcon} style={{ width: '16px' }} />
          </DropdownListButton>
        </div>
        <div
          style={{
            position: newFilterActive ? 'relative' : 'absolute',
            right: newFilterActive ? '0%' : '-100%',
            transition: 'right .3s ease-in-out',
            top: 0,
            padding: '10px',
          }}
        >
          <MenuHeader header="Save Filter" onClick={() => setSubMenu(null)} />
          <div style={{ marginTop: '8px', fontSize: '0.85em' }}>
            <div style={{ color: GREY_DARK, marginBottom: '2px' }}>Add a name for your filter</div>
            <input
              placeholder="My Filter"
              value={filterName}
              onChange={e => {
                setFilterName(e.target.value);
              }}
            ></input>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
              <LinkLikeButton
                disabled={!filterName}
                onClick={() => {
                  if (filterSaving) {
                    return;
                  }
                  saveFilter({ name: filterName, filter: filterQueryParams });
                  setFilterName('');
                  dispatch(setActiveDropdown(null));
                }}
              >
                Save Filter
              </LinkLikeButton>
              <LinkLikeButton onClick={() => setSubMenu(null)}>Cancel</LinkLikeButton>
            </div>
          </div>
        </div>
        <div
          style={{
            position: selectFiltersActive ? 'relative' : 'absolute',
            right: selectFiltersActive ? '0%' : '-100%',
            transition: 'right .3s ease-in-out',
            top: 0,
            padding: '10px',
          }}
        >
          <MenuHeader header="Load Filter" onClick={() => setSubMenu(null)} />
          <div style={{ marginTop: '8px', fontSize: '0.85em' }}>
            <div style={{ color: GREY_DARK, marginBottom: '2px' }}>Select a Filter</div>
            <Select
              styles={{
                container: base => ({ ...base, marginBottom: '10px' }),
                control: base => ({
                  ...base,
                  minHeight: 30,
                  borderRadius: '0px',
                  borderColor: PRIMARY,
                }),
                dropdownIndicator: base => ({
                  ...base,
                  padding: 4,
                }),
                clearIndicator: base => ({
                  ...base,
                  padding: 4,
                }),
                valueContainer: base => ({
                  ...base,
                  padding: '0px 6px',
                }),
                input: base => ({
                  ...base,
                  margin: 0,
                  padding: '0px 5px',
                  fontSize: '16px',
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
              }}
              className="react-select-filters"
              noOptionsMessage={() => 'No Saved Filters'}
              options={userFilters}
              onChange={selected => {
                dispatch(setActiveFilter(selected));
              }}
              menuPosition={'fixed'}
              menuPortalTarget={document.body}
              value={activeFilter}
            />
            {activeFilter && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LinkLikeButton onClick={() => dispatch(setActiveFilter(null))}>
                  Clear Selected Filter
                </LinkLikeButton>
                <LinkLikeButton
                  onClick={() => {
                    if (filterDeleting) {
                      return;
                    }
                    dispatch(setActiveFilter(null));
                    deleteFilter(activeFilter);
                  }}
                >
                  Delete Filter
                </LinkLikeButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedFiltersMenu;
