import {
  prepNoticeObjForExport,
  flattenObj,
  flattenNoticeSpecialProperties,
} from '../utils/helpers';
import { useCurrentNotice } from './useCurrentNotice';

// Hook to grab mailer template variables (facility name & address, public action deadline) from current notice
export const useMailMergeVariables = () => {
  const notice = useCurrentNotice();

  if (!notice) {
    return {};
  }

  const preppedNotice = prepNoticeObjForExport(notice);
  const flattenedNotice = flattenObj(preppedNotice, flattenNoticeSpecialProperties);

  return flattenedNotice;
};
