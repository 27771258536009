import { CONCRETE_BATCH, GENERAL_MISC, LANDFILL, PETROCHEM_REFINERY, TERMINALS } from "../constants/notices";

// Return corrent template for given facility type and public meeting boolean
export const getTemplateJSONByType = (facilityType, isPublicMeeting) => {
  switch(facilityType) {
    case GENERAL_MISC:
      return isPublicMeeting ? otherGenericPublicMeeting : otherGenericInitial;
    case LANDFILL:
      return isPublicMeeting ? mswLandfillsGenericPublicMeeting : otherGenericInitial;
    case TERMINALS:
      return isPublicMeeting ? terminalsGenericPublicMeeting : terminalsGenericInitial;
    case PETROCHEM_REFINERY:
      return isPublicMeeting ? petrochemGenericPublicMeeting : petrochemGenericInitial;
    case CONCRETE_BATCH:
      return isPublicMeeting ? cbpGenericPublicMeeting : cbpGenericInitial;
    default:
      return isPublicMeeting ? otherGenericPublicMeeting : otherGenericInitial;;
  }
}

// These templates are copied directly from AAH's Stannp designs. They do not auto-update when AAH updates their Stannp templates, & need to be manually copied.
// To copy, view a design and click the "Template JSON" button. Copy all of the contents of the template JSON.

// LAST UPDATED: 06/30/2022

// From AAH template "Other_generic_public meeting", ID 200759
const otherGenericPublicMeeting = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.2800000000000001,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": 0,
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646357969.png",
                  "x": "210px",
                  "y": "485px",
                  "width": "109px",
                  "height": "85px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651918620.png",
                  "x": "1224px",
                  "y": "484px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656105486.jpg",
                  "x": "206px",
                  "y": "876px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle8\"><b>Say NO to pollution and safety risks in YOUR neighborhood!</b></span><br></p>",
                  "x": "197px",
                  "y": "271px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle5\"><b>TAKE ACTION! ¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "321px",
                  "y": "478px",
                  "width": "897px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle9\"><b>¡Diga NO a la contaminación y a los riesgos de seguridad en SU vecindario!</b></span><br></p>",
                  "x": "201px",
                  "y": "635px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} has applied for an air quality permit from the Texas Commission on Environmental Quality (TCEQ) that would authorize <b>a&nbsp;<span class=\"cstyle7\">[add facility type]&nbsp;</span>at {facilityaddress} </b>that may produce air pollutants that could be hazardous to your health and impact your quality of life.</p>",
                  "x": "1530px",
                  "y": "227px",
                  "width": "1039px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} ha solicitado un permiso de calidad del aire a la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) que autorizaría <b>una&nbsp;<span class=\"cstyle6\">[add facility type]</span>&nbsp;en {facilityaddress} </b>que puede producir contaminantes atmosféricos que podrían ser peligrosos para su salud e impactar en su calidad de vida.<br></p>",
                  "x": "1528px",
                  "y": "829px",
                  "width": "1043px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><i>You have received this notice because living in close proximity of the proposed facility may give you the right to contest the permit.</i></span></p><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span></p>",
                  "x": "176px",
                  "y": "1689px",
                  "width": "2430px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p><span class=\"cstyle11\"><b>{publicmeetingaddress}</b></span><br></p>",
                  "x": "311px",
                  "y": "564px",
                  "width": "1394px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle10\"><b>A public meeting will be held at: |&nbsp;Se llevará a cabo una reunión pública en:&nbsp;</b></span><br></p>",
                  "x": "120px",
                  "y": "146px",
                  "width": "1828px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#000000",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.</p><p><span><br></span></p><p><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span>{noticeurl}.</p>",
                  "x": "119px",
                  "y": "1494px",
                  "width": "1325px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>{publicmeetingdayen} / {publicmeetingdayes}</b></p><p><b>{publicmeetingdateen} / {publicmeetingdatees}</b></p><p><b>At {publicmeetingtimeen} / A las {publicmeetingtimeen}</b></p>",
                  "x": "102px",
                  "y": "266px",
                  "width": "1833px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>Representatives from the TCEQ and the permit applicant will be in attendance. We strongly encourage you to join your community in attending this meeting to express your concerns and ask questions about the industrial development proposed to be built in your neighborhood.</p><p><br></p><p>Asistirán representantes de la TCEQ y del solicitante del permiso. Le animamos encarecidamente a que se una a su comunidad asistiendo a esta reunión para expresar sus preocupaciones y hacer preguntas sobre el desarrollo industrial que se propone construir en su vecindario.</p>",
                  "x": "121px",
                  "y": "767px",
                  "width": "1330px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle10",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle11",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
}

// From AAH template "Other_generic_initial", ID 200757
const otherGenericInitial = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.2800000000000001,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200757",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920568.png",
                  "x": "1216px",
                  "y": "470px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920578.png",
                  "x": "203px",
                  "y": "475px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656105368.jpg",
                  "x": "192px",
                  "y": "881px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span><b>Say NO to pollution and safety risks in YOUR neighborhood!</b></span><br></p>",
                  "x": "187px",
                  "y": "267px",
                  "width": "1130px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle0\"><b>TAKE ACTION!&nbsp;¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "312px",
                  "y": "466px",
                  "width": "896px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><b>¡Diga NO a la contaminación y a los riesgos de seguridad en SU vecindario!</b></span><br></p>",
                  "x": "191px",
                  "y": "620px",
                  "width": "1133px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} has applied for an air quality permit from the Texas Commission on Environmental Quality (TCEQ) that would authorize <b>a</b><span class=\"cstyle9\"><b> [add facility type]&nbsp;</b></span><b>at {facilityaddress}</b> that may produce air pollutants that could be hazardous to your health and impact your quality of life.<br></p>",
                  "x": "1534px",
                  "y": "225px",
                  "width": "1057px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} ha solicitado un permiso de calidad del aire a la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) que autorizaría <b>una&nbsp;</b><span class=\"cstyle4\"><b>[add facility type]</b>&nbsp;</span><b>en&nbsp;</b><span><b>{facilityaddress}&nbsp;</b></span>que puede producir contaminantes atmosféricos que podrían ser peligrosos para su salud e impactar en su calidad de vida.<br></p>",
                  "x": "1535px",
                  "y": "809px",
                  "width": "1059px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"JsGRdQ\"><i>You have received this notice because living in close proximity of the proposed facility may give you the right to challenge the permit.</i></span></p><p></p><span><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span><br></p></span>",
                  "x": "84px",
                  "y": "1700px",
                  "width": "2601px",
                  "align": "center",
                  "font": "dejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p></p><p><span class=\"cstyle5\"><b>Submit public comments to the TCEQ regarding this permit application AND speak to your State Representative, {staterepname} at {staterepphone} and State Senator, {statesenatorname} at {statesenatorpphone} to request a public meeting by {publicactiondeadlineen}.&nbsp;</b></span></p><p><span class=\"cstyle5\"><br></span></p><p><span class=\"cstyle5\">The TCEQ must organize a public meeting at the request of State Officials.&nbsp;</span><br></p>",
                  "x": "123px",
                  "y": "403px",
                  "width": "1236px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><b>Act now to protect your neighborhood!</b></span></p><p><b>¡Actúe ahora para proteger su vecindario!&nbsp;</b></p>",
                  "x": "122px",
                  "y": "146px",
                  "width": "1362px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.<br></p><p><span><br></span></p><p><span><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span></span>{noticeurl}.</p>",
                  "x": "1665px",
                  "y": "540px",
                  "width": "983px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle6\"><b>Haga comentarios públicos a la TCEQ con respecto a esta solicitud de permiso Y hable con su representante estatal, {staterepname} al {staterepphone} y con el senador estatal {statesenatorname} al {statesenatorpphone} para solicitar una reunión pública antes del {publicactiondeadlinees}.</b></span></p><p><span class=\"cstyle6\"><br></span></p><p><span class=\"cstyle6\">La TCEQ debe organizar una reunión pública a petición de los legisladores estatales locales.</span><br></p>",
                  "x": "123px",
                  "y": "1061px",
                  "width": "1235px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      }
  ]
};

// From AAH template "MSW and landfills_generic_public meeting", ID 200749
const mswLandfillsGenericPublicMeeting = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.24000000000000007,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200749",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646357969.png",
                  "x": "174px",
                  "y": "433px",
                  "width": "109px",
                  "height": "85px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651918620.png",
                  "x": "1203px",
                  "y": "428px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656104108.jpg",
                  "x": "178px",
                  "y": "829px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle6\"><b>A LANDFILL IN YOUR NEIGHBORHOOD?</b></span><br></p>",
                  "x": "177px",
                  "y": "290px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle5\"><b>TAKE ACTION! ¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "294px",
                  "y": "425px",
                  "width": "897px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle7\"><b>¿UN VERTEDERO EN SU VECINDARIO?</b></span><br></p>",
                  "x": "180px",
                  "y": "590px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a landfill at {facilityaddress}</b> (Permit number {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Landfills can threaten your quality of life by bringing hazards such as odor, health-harming gases, noise, bugs, and water supply contamination.</span></p>",
                  "x": "1427px",
                  "y": "184px",
                  "width": "1190px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>un vertedero en {facilityaddress}</b> (número de permiso {permitnumber}).&nbsp;</p><p><br></p><p><span>Los vertederos pueden amenazar su calidad de vida al traer consigo peligros como el olor, los gases nocivos para la salud, el ruido, los insectos y la contaminación del suministro de agua.</span></p>",
                  "x": "1420px",
                  "y": "890px",
                  "width": "1186px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><i>You have received this notice because living in close proximity of the proposed facility may give you the right to contest the permit.</i></span></p><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span></p>",
                  "x": "176px",
                  "y": "1689px",
                  "width": "2430px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p><span class=\"cstyle9\"><b>{publicmeetingaddress}</b></span><br></p>",
                  "x": "311px",
                  "y": "564px",
                  "width": "1394px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle8\"><b>A public meeting will be held at: |&nbsp;Se llevará a cabo una reunión pública en:&nbsp;</b></span><br></p>",
                  "x": "120px",
                  "y": "146px",
                  "width": "1828px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#000000",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.</p><p><span><br></span></p><p><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span>{noticeurl}.</p>",
                  "x": "119px",
                  "y": "1494px",
                  "width": "1325px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>{publicmeetingdayen} / {publicmeetingdayes}</b></p><p><b>{publicmeetingdateen} / {publicmeetingdatees}</b></p><p><b>At {publicmeetingtimeen} / A las {publicmeetingtimeen}</b></p>",
                  "x": "102px",
                  "y": "266px",
                  "width": "1833px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>Representatives from the TCEQ and the permit applicant will be in attendance. We strongly encourage you to join your community in attending this meeting to express your concerns and ask questions about the industrial development proposed to be built in your neighborhood.</p><p><br></p><p>Asistirán representantes de la TCEQ y del solicitante del permiso. Le animamos encarecidamente a que se una a su comunidad asistiendo a esta reunión para expresar sus preocupaciones y hacer preguntas sobre el desarrollo industrial que se propone construir en su vecindario.</p>",
                  "x": "121px",
                  "y": "767px",
                  "width": "1330px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};

// From AAH template "MSW and landfills_AAH_initial", ID 200737
const mswLandfillsGenericInitial = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.24000000000000007,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200737",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646368034.png",
                  "x": "1687px",
                  "y": "1714px",
                  "width": "816px",
                  "height": "61px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646372384.png",
                  "x": "2526px",
                  "y": "1652px",
                  "width": "133px",
                  "height": "134px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920313.png",
                  "x": "1202px",
                  "y": "371px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920329.png",
                  "x": "177px",
                  "y": "374px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656103817.jpg",
                  "x": "168px",
                  "y": "765px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle4\"><b>A LANDFILL IN YOUR NEIGHBORHOOD?</b></span><br></p>",
                  "x": "165px",
                  "y": "212px",
                  "width": "1134px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle3\"><b>TAKE ACTION! ¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "287px",
                  "y": "369px",
                  "width": "910px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle5\"><b>¿UN VERTEDERO EN SU VECINDARIO?</b></span><br></p>",
                  "x": "168px",
                  "y": "547px",
                  "width": "1130px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a landfill at {facilityaddress}</b> (Permit number {permitnumber}).&nbsp;</p><p><br></p><p><span>Landfills can threaten your quality of life by bringing hazards such as odor, health-harming gases, noise, bugs, and water supply contamination.</span></p>",
                  "x": "1446px",
                  "y": "176px",
                  "width": "1151px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>un vertedero en {facilityaddress} </b>(número de permiso {permitnumber}).&nbsp;</p><p><br></p><p><span>Los vertederos pueden amenazar su calidad de vida al traer consigo peligros como el olor, los gases nocivos para la salud, el ruido, los insectos y la contaminación del suministro de agua.</span></p>",
                  "x": "1441px",
                  "y": "834px",
                  "width": "1161px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><i>Air Alliance Houston is sending you this notice because living in close proximity of the proposed facility may give you the right to contest the permit.</i></span></p><p><i><br></i></p><p><span><i>Air Alliance Houston le envía este aviso porque al vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span><br></p>",
                  "x": "171px",
                  "y": "1520px",
                  "width": "1135px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.1",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646370949.png",
                  "x": "2034px",
                  "y": "914px",
                  "width": "651px",
                  "height": "49px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646372026.png",
                  "x": "2168px",
                  "y": "508px",
                  "width": "384px",
                  "height": "378px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span><i>Air Alliance Houston is a 501(c)(3) non-profit organization working to reduce the public health impacts from air pollution and advance environmental justice.</i></span></p><p><i><br></i></p><p><span><i>Air Alliance Houston es una organización sin fines de lucro 501(c)(3) que trabaja para reducir los impactos de la contaminación del aire en la salud pública y promover la justicia ambiental.</i></span><br></p>",
                  "x": "116px",
                  "y": "1556px",
                  "width": "1341px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.0",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p></p><p>Air Alliance Houston can assist you in creating comments and may be able to support/represent you if you are interested in challenging this permit. <b>For more information, please leave us a message at 713.589.4733, <i>or </i>visit www.airalliancehouston.org/airmail-CBP,&nbsp;<i>or </i>scan the QR code.</b></p><p><b><br></b></p><p></p><p>Air Alliance Houston puede ayudarle a crear comentarios y quizá pueda apoyarle/representarle si está interesado en impugnar este permiso.<b> Para más información, déjenos un mensaje al 713.589.4733, o visite airalliancehouston.com/airmail-CBP, o escanee el código QR.</b></p>",
                  "x": "111px",
                  "y": "954px",
                  "width": "1341px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "37.5px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle1\"><b>Act now to protect your neighborhood!&nbsp;</b></span></p><p><span><b>¡Actúe ahora para proteger su vecindario!&nbsp;</b></span></p>",
                  "x": "107px",
                  "y": "105px",
                  "width": "1109px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>Submit public comments to the TCEQ regarding this permit application AND speak to your State Representative,&nbsp;</b><b>{staterepname}&nbsp;at {staterepphone}&nbsp;and State Senator, {statesenatorname} at {statesenatorpphone} to request a public meeting by {publicactiondeadlineen}.</b>&nbsp;The TCEQ must organize a public meeting at the request of local State legislators.&nbsp;</p><p><br></p><p><span><b>Haga comentarios públicos a la TCEQ con respecto a esta solicitud de permiso Y hable con su representante estatal, {staterepname} al {staterepphone} y con el senador estatal {statesenatorname} al {statesenatorpphone} para solicitar una reunión pública antes del&nbsp;{publicactiondeadlinees}.&nbsp;</b></span>La TCEQ debe organizar una reunión pública a petición de los legisladores estatales locales.&nbsp;</p>",
                  "x": "111px",
                  "y": "311px",
                  "width": "1823px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};

// From AAH template "	Storage and export terminals_generic_public meeting", ID 200733
const terminalsGenericPublicMeeting = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.32000000000000006,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200733",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646357969.png",
                  "x": "156px",
                  "y": "450px",
                  "width": "109px",
                  "height": "85px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651918620.png",
                  "x": "1183px",
                  "y": "454px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656103161.jpg",
                  "x": "162px",
                  "y": "870px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle17\"><b>Say NO to pollution and safety risks in YOUR neighborhood!</b></span><br></p>",
                  "x": "154px",
                  "y": "237px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle5\"><b>TAKE ACTION! ¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "274px",
                  "y": "445px",
                  "width": "897px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle18\"><b>¡Diga NO a la contaminación y a los riesgos de seguridad en SU vecindario!</b></span><br></p>",
                  "x": "159px",
                  "y": "611px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle13\">{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a&nbsp;<span class=\"cstyle15\">[add type]&nbsp;</span>terminal at {facilityaddress} </b>(Permit number {permitnumber}).&nbsp;</span></p><p><span class=\"cstyle13\"><br></span></p><p><span class=\"cstyle13\">Storage and export terminal operations release toxic air pollution that can harm your health, increasing the risk of asthma, cancers, birth defects, neurological and cardiovascular damage. The risk of fire or explosion from spills or uncontrolled releases is an additional concern.&nbsp;</span><br></p>",
                  "x": "1427px",
                  "y": "159px",
                  "width": "1190px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#F00000",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle12\">{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para<b> una terminal de<span class=\"cstyle14\">&nbsp;[add type]</span>&nbsp;en {facilityaddress} </b>(número de permiso {permitnumber}).</span></p><p><span class=\"cstyle12\"><br></span></p><p><span class=\"cstyle12\">Las operaciones de almacenamiento y de las terminales de exportación liberan una contaminación atmosférica tóxica que puede perjudicar su salud, aumentando el riesgo de asma, cánceres, defectos de nacimiento y daños neurológicos y cardiovasculares. El riesgo de incendio o explosión por derrames o vertidos incontrolados es una preocupación adicional.&nbsp;</span><br></p>",
                  "x": "1429px",
                  "y": "850px",
                  "width": "1186px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><i>You have received this notice because living in close proximity of the proposed facility may give you the right to contest the permit.</i></span></p><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span></p>",
                  "x": "176px",
                  "y": "1695px",
                  "width": "2430px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p><span class=\"cstyle20\"><b>{publicmeetingaddress}</b></span><br></p>",
                  "x": "311px",
                  "y": "564px",
                  "width": "1394px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle19\"><b>A public meeting will be held at: |&nbsp;Se llevará a cabo una reunión pública en:&nbsp;</b></span><br></p>",
                  "x": "120px",
                  "y": "146px",
                  "width": "1828px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.</p><p><span><br></span></p><p><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span>{noticeurl}.</p>",
                  "x": "119px",
                  "y": "1494px",
                  "width": "1325px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>{publicmeetingdayen} / {publicmeetingdayes}</b></p><p><b>{publicmeetingdateen} / {publicmeetingdatees}</b></p><p><b>At {publicmeetingtimeen} / A las {publicmeetingtimeen}</b></p>",
                  "x": "102px",
                  "y": "266px",
                  "width": "1833px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>Representatives from the TCEQ and the permit applicant will be in attendance. We strongly encourage you to join your community in attending this meeting to express your concerns and ask questions about the industrial development proposed to be built in your neighborhood.</p><p><br></p><p>Asistirán representantes de la TCEQ y del solicitante del permiso. Le animamos encarecidamente a que se una a su comunidad asistiendo a esta reunión para expresar sus preocupaciones y hacer preguntas sobre el desarrollo industrial que se propone construir en su vecindario.</p>",
                  "x": "121px",
                  "y": "767px",
                  "width": "1330px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle10",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle11",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle12",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle13",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle14",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle15",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle16",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle17",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle18",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle19",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle20",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};

// From AAH template "Storage and export terminals_generic_initial", ID 200731
const terminalsGenericInitial = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.32000000000000006,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200731",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920568.png",
                  "x": "1184px",
                  "y": "448px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920578.png",
                  "x": "159px",
                  "y": "449px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656103367.jpg",
                  "x": "165px",
                  "y": "865px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle24\"><b>Say NO to pollution and safety risks in YOUR neighborhood!</b></span><br></p>",
                  "x": "155px",
                  "y": "232px",
                  "width": "1130px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle0\"><b>TAKE ACTION!&nbsp;¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "276px",
                  "y": "441px",
                  "width": "896px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle25\"><b>¡Diga NO a la contaminación y a los riesgos de seguridad en SU vecindario!</b></span><br></p>",
                  "x": "158px",
                  "y": "606px",
                  "width": "1133px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle18\">{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a&nbsp;<span class=\"cstyle19\">[add type]</span>&nbsp;terminal at {facilityaddress}</b> (Permit number {permitnumber}).&nbsp;</span></p><p><span class=\"cstyle18\"><br></span></p><p><span class=\"cstyle18\">Storage and export terminal operations release toxic air pollution that can harm your health, increasing the risk of asthma, cancers, birth defects, neurological and cardiovascular damage. The risk of fire or explosion from spills or uncontrolled releases is an additional concern.&nbsp;</span></p>",
                  "x": "1427px",
                  "y": "160px",
                  "width": "1182px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#000000",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>una terminal de&nbsp;</b><span class=\"cstyle28\"><b>[add type]</b></span><b>&nbsp;en {facilityaddress} </b>(número de permiso {permitnumber}).</p><p><br></p><p><span>Las operaciones de almacenamiento y de las terminales de exportación liberan una contaminación atmosférica tóxica que puede perjudicar su salud, aumentando el riesgo de asma, cánceres, defectos de nacimiento y daños neurológicos y cardiovasculares. El riesgo de incendio o explosión por derrames o vertidos incontrolados es una preocupación adicional.&nbsp;</span></p>",
                  "x": "1427px",
                  "y": "850px",
                  "width": "1179px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"JsGRdQ\"><i>You have received this notice because living in close proximity of the proposed facility may give you the right to challenge the permit.</i></span></p><p></p><span><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span><br></p></span>",
                  "x": "84px",
                  "y": "1700px",
                  "width": "2601px",
                  "align": "center",
                  "font": "dejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p></p><p><span class=\"cstyle26\"><b>Submit public comments to the TCEQ regarding this permit application AND speak to your State Representative, {staterepname} at {staterepphone} and State Senator, {statesenatorname} at {statesenatorpphone} to request a public meeting by {publicactiondeadlineen}.</b></span></p><p><span class=\"cstyle26\"><br></span></p><p><span class=\"cstyle26\">The TCEQ must organize a public meeting at the request of State Officials.&nbsp;</span><br></p>",
                  "x": "123px",
                  "y": "403px",
                  "width": "1236px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><b>Act now to protect your neighborhood!</b></span></p><p><b>¡Actúe ahora para proteger su vecindario!&nbsp;</b></p>",
                  "x": "122px",
                  "y": "146px",
                  "width": "1362px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.<br></p><p><span><br></span></p><p><span><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span></span>{noticeurl}.</p>",
                  "x": "1665px",
                  "y": "540px",
                  "width": "983px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle27\"><b>Haga comentarios públicos a la TCEQ con respecto a esta solicitud de permiso Y hable con su representante estatal, {staterepname} al {staterepphone} y con el senador estatal {statesenatorname} al {statesenatorpphone} para solicitar una reunión pública antes del {publicactiondeadlinees}.</b></span></p><p><span class=\"cstyle27\"><br></span></p><p><span class=\"cstyle27\">La TCEQ debe organizar una reunión pública a petición de los legisladores estatales locales.</span><br></p>",
                  "x": "123px",
                  "y": "1061px",
                  "width": "1235px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle10",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle11",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle12",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle13",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#000000"
      },
      {
          "name": "cstyle14",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle15",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle16",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle17",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle18",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle19",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      },
      {
          "name": "cstyle20",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle21",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle22",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle23",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle24",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle25",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle26",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle27",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle28",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#F00000"
      }
  ]
};

// From AAH template "Refineries and petrochem_generic_public meeting", ID 200683
const petrochemGenericPublicMeeting = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.2800000000000001,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200683",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646357969.png",
                  "x": "156px",
                  "y": "454px",
                  "width": "109px",
                  "height": "85px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651918620.png",
                  "x": "1183px",
                  "y": "457px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656111362.jpg",
                  "x": "169px",
                  "y": "850px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle6\"><b>Say NO to pollution and safety risks in YOUR neighborhood!</b></span><br></p>",
                  "x": "159px",
                  "y": "247px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle5\"><b>TAKE ACTION! ¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "275px",
                  "y": "444px",
                  "width": "897px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle7\"><b>¡Diga NO a la contaminación y a los riesgos de seguridad en SU vecindario!</b></span><br></p>",
                  "x": "164px",
                  "y": "618px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a petrochemical plant at {facilityaddress}</b> (Permit number {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Petrochemical facilities release toxic air pollution that can harm your health, increasing the risk of asthma, cancers, birth defects, and neurological and cardiovascular damage.&nbsp;</span></p>",
                  "x": "1421px",
                  "y": "183px",
                  "width": "1190px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>una planta petroquímica en&nbsp;</b><b>{facilityaddress}</b> (número de permiso {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Las instalaciones petroquímicas liberan contaminación atmosférica tóxica que puede dañar su salud, aumentando el riesgo de asma, cánceres, defectos de nacimiento y daños neurológicos y cardiovasculares.&nbsp;</span></p>",
                  "x": "1424px",
                  "y": "908px",
                  "width": "1186px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><i>You have received this notice because living in close proximity of the proposed facility may give you the right to contest the permit.</i></span></p><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span></p>",
                  "x": "176px",
                  "y": "1689px",
                  "width": "2430px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p><span class=\"cstyle9\"><b>{publicmeetingaddress}</b></span><br></p>",
                  "x": "311px",
                  "y": "564px",
                  "width": "1394px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle8\"><b>A public meeting will be held at: |&nbsp;Se llevará a cabo una reunión pública en:&nbsp;</b></span><br></p>",
                  "x": "120px",
                  "y": "146px",
                  "width": "1828px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#000000",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.</p><p><span><br></span></p><p><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span>{noticeurl}.</p>",
                  "x": "119px",
                  "y": "1494px",
                  "width": "1325px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>{publicmeetingdayen} / {publicmeetingdayes}</b></p><p><b>{publicmeetingdateen} / {publicmeetingdatees}</b></p><p><b>At {publicmeetingtimeen} / A las {publicmeetingtimeen}</b></p>",
                  "x": "102px",
                  "y": "266px",
                  "width": "1833px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>Representatives from the TCEQ and the permit applicant will be in attendance. We strongly encourage you to join your community in attending this meeting to express your concerns and ask questions about the industrial development proposed to be built in your neighborhood.</p><p><br></p><p>Asistirán representantes de la TCEQ y del solicitante del permiso. Le animamos encarecidamente a que se una a su comunidad asistiendo a esta reunión para expresar sus preocupaciones y hacer preguntas sobre el desarrollo industrial que se propone construir en su vecindario.</p>",
                  "x": "121px",
                  "y": "767px",
                  "width": "1330px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};

// From AAH template "Refineries and petrochem_generic_initial", ID 200681
const petrochemGenericInitial = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": 0.2800000000000001,
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "200681",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920568.png",
                  "x": "1190px",
                  "y": "448px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920578.png",
                  "x": "166px",
                  "y": "449px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1656111345.jpg",
                  "x": "177px",
                  "y": "831px",
                  "width": "1134px",
                  "height": "638px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle7\"><b>Say NO to pollution and safety risks in YOUR neighborhood!</b></span><br></p>",
                  "x": "175px",
                  "y": "243px",
                  "width": "1130px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle0\"><b>TAKE ACTION!&nbsp;¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "279px",
                  "y": "441px",
                  "width": "896px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle8\"><b>¡Diga NO a la contaminación y a los riesgos de seguridad en SU vecindario!</b></span><br></p>",
                  "x": "172px",
                  "y": "606px",
                  "width": "1133px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a petrochemical plant at {facilityaddress}</b> (Permit number {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Petrochemical facilities release toxic air pollution that can harm your health, increasing the risk of asthma, cancers, birth defects, and neurological and cardiovascular damage.&nbsp;</span></p>",
                  "x": "1425px",
                  "y": "178px",
                  "width": "1182px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>una planta petroquímica en&nbsp;</b><b>{facilityaddress}</b>&nbsp;(número de permiso {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Las instalaciones petroquímicas liberan contaminación atmosférica tóxica que puede dañar su salud, aumentando el riesgo de asma, cánceres, defectos de nacimiento y daños neurológicos y cardiovasculares.&nbsp;</span></p>",
                  "x": "1425px",
                  "y": "904px",
                  "width": "1188px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"JsGRdQ\"><i>You have received this notice because living in close proximity of the proposed facility may give you the right to challenge the permit.</i></span></p><p></p><span><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span><br></p></span>",
                  "x": "84px",
                  "y": "1700px",
                  "width": "2601px",
                  "align": "center",
                  "font": "dejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p></p><p><span class=\"cstyle9\"><b>Submit public comments to the TCEQ regarding this permit application AND speak to your State Representative, {staterepname} at {staterepphone} and State Senator, {statesenatorname} at {statesenatorpphone} to request a public meeting by {publicactiondeadlineen}.</b></span></p><p><span class=\"cstyle9\"><br></span></p><p><span class=\"cstyle9\">The TCEQ must organize a public meeting at the request of State Officials.&nbsp;</span><br></p>",
                  "x": "123px",
                  "y": "403px",
                  "width": "1236px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><b>Act now to protect your neighborhood!</b></span></p><p><b>¡Actúe ahora para proteger su vecindario!&nbsp;</b></p>",
                  "x": "122px",
                  "y": "146px",
                  "width": "1362px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.<br></p><p><span><br></span></p><p><span><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span></span>{noticeurl}.</p>",
                  "x": "1665px",
                  "y": "540px",
                  "width": "983px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle10\"><b>Haga comentarios públicos a la TCEQ con respecto a esta solicitud de permiso Y hable con su representante estatal, {staterepname} al {staterepphone} y con el senador estatal {statesenatorname} al {statesenatorpphone} para solicitar una reunión pública antes del {publicactiondeadlinees}.</b></span></p><p><span class=\"cstyle10\"><br></span></p><p><span class=\"cstyle10\">La TCEQ debe organizar una reunión pública a petición de los legisladores estatales locales.</span><br></p>",
                  "x": "123px",
                  "y": "1061px",
                  "width": "1235px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle10",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};

// From AAH template "CBP_generic_public meeting", ID 173622
const cbpGenericPublicMeeting = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": "0.3",
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "173622",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1646357969.png",
                  "x": "179px",
                  "y": "438px",
                  "width": "109px",
                  "height": "85px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651918511.jpg",
                  "x": "179px",
                  "y": "841px",
                  "width": "1142px",
                  "height": "643px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651918620.png",
                  "x": "1206px",
                  "y": "441px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle8\"><b>A CONCRETE BATCH PLANT IN YOUR NEIGHBORHOOD?</b></span><br></p>",
                  "x": "171px",
                  "y": "217px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle5\"><b>TAKE ACTION! ¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "298px",
                  "y": "433px",
                  "width": "897px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle9\"><b>¿UNA PLANTA MEZCLADORA DE CONCRETO EN SU VECINDARIO?</b></span><br></p>",
                  "x": "176px",
                  "y": "601px",
                  "width": "1137px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for <b>a concrete batch plant at {facilityaddress}</b> (Permit number {permitnumber}).&nbsp;</p><p><br></p><p><span>Concrete batch plants can emit large amounts of harmful particulate matter pollution, increase truck traffic on residential streets, and become a noisy nuisance. These plants are typically permitted to operate 24/7.</span></p>",
                  "x": "1423px",
                  "y": "133px",
                  "width": "1190px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>una planta mezcladora de concreto en {facilityaddress}</b> (número de permiso {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Las plantas mezcladoras de concreto pueden emitir grandes cantidades de contaminación por partículas nocivas, aumentar el tráfico de camiones en las calles residenciales y convertirse en una molestia ruidosa. Estas plantas suelen tener permiso para funcionar las 24 horas del día.</span></p>",
                  "x": "1425px",
                  "y": "887px",
                  "width": "1186px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><i>You have received this notice because living in close proximity of the proposed facility may give you the right to contest the permit.</i></span></p><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span></p>",
                  "x": "176px",
                  "y": "1689px",
                  "width": "2430px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p><span class=\"cstyle7\"><b>{publicmeetingaddress}</b></span><br></p>",
                  "x": "311px",
                  "y": "564px",
                  "width": "1394px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle6\"><b>A public meeting will be held at: |&nbsp;Se llevará a cabo una reunión pública en:&nbsp;</b></span><br></p>",
                  "x": "120px",
                  "y": "146px",
                  "width": "1828px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#000000",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.</p><p><span><br></span></p><p><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span>{noticeurl}.</p>",
                  "x": "119px",
                  "y": "1494px",
                  "width": "1325px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>{publicmeetingdayen} / {publicmeetingdayes}</b></p><p><b>{publicmeetingdateen} / {publicmeetingdatees}</b></p><p><b>At {publicmeetingtimeen} / A las {publicmeetingtimeen}</b></p>",
                  "x": "102px",
                  "y": "266px",
                  "width": "1833px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>Representatives from the TCEQ and the permit applicant will be in attendance. We strongly encourage you to join your community in attending this meeting to express your concerns and ask questions about the industrial development proposed to be built in your neighborhood.</p><p><br></p><p>Asistirán representantes de la TCEQ y del solicitante del permiso. Le animamos encarecidamente a que se una a su comunidad asistiendo a esta reunión para expresar sus preocupaciones y hacer preguntas sobre el desarrollo industrial que se propone construir en su vecindario.</p>",
                  "x": "121px",
                  "y": "767px",
                  "width": "1330px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle5",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle6",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle7",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle8",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle9",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};

// From AAH template "CBP_generic_initial", ID 173620
const cbpGenericInitial = {
  "background": "",
  "thumbnail": "",
  "pdf": "",
  "backgroundScale": "auto",
  "scale": "0.3",
  "return_address": "Stannp, 3235 Grand Island Blvd, Grand Island, NY 14072",
  "address_format": "{full_name}<br>{job_title}<br>{company}<br>{address1} {address2}<br>{city} {state} {zipcode}",
  "duplex": "true",
  "white_clearzone": "true",
  "fonts": [],
  "id": "173620",
  "pages": [
      {
          "images": [
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920568.png",
                  "x": "1199px",
                  "y": "448px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1647920578.png",
                  "x": "189px",
                  "y": "449px",
                  "width": "104px",
                  "height": "80px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              },
              {
                  "src": "https://stannpstorage.blob.core.windows.net/account-files/21396-1651913840.jpg",
                  "x": "185px",
                  "y": "844px",
                  "width": "1144px",
                  "height": "644px",
                  "maskWidth": "0px",
                  "maskHeight": "0px",
                  "useMask": false,
                  "dynamicSource": "",
                  "type": "image",
                  "maskY": "0px",
                  "maskX": "0px"
              }
          ],
          "text": [
              {
                  "text": "<p><span class=\"cstyle3\"><b>A CONCRETE BATCH PLANT IN YOUR NEIGHBORHOOD?</b></span><br></p>",
                  "x": "194px",
                  "y": "232px",
                  "width": "1130px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle0\"><b>TAKE ACTION!&nbsp;¡TOME ACCIÓN!</b></span><br></p>",
                  "x": "298px",
                  "y": "441px",
                  "width": "896px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"cstyle4\"><b>¿UNA PLANTA MEZCLADORA DE CONCRETO EN SU VECINDARIO?</b></span><br></p>",
                  "x": "191px",
                  "y": "600px",
                  "width": "1133px",
                  "align": "center",
                  "font": "DejaVu Sans",
                  "size": "50px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "",
                  "x": "728px",
                  "y": "422px",
                  "width": "1000px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.6",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} is applying for a permit from the Texas Commission for Environmental Quality (TCEQ) for&nbsp;<b>a concrete batch plant at {facilityaddress}</b>&nbsp;(Permit number {permitnumber}).&nbsp;</p><p><br></p><p></p><p>Concrete batch plants can emit large amounts of harmful particulate matter pollution, increase truck traffic on residential streets, and become a noisy nuisance. These plants are typically permitted to operate 24/7.</p>",
                  "x": "1425px",
                  "y": "140px",
                  "width": "1182px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>{principalname} está solicitando un permiso de la Comisión de Calidad Ambiental de Texas (TCEQ, por sus siglas en inglés) para <b>una planta mezcladora de concreto en {facilityaddress}</b> (número de permiso {permitnumber}).&nbsp;</p><p><span><br></span></p><p><span>Las plantas mezcladoras de concreto pueden emitir grandes cantidades de contaminación por partículas nocivas, aumentar el tráfico de camiones en las calles residenciales y convertirse en una molestia ruidosa. Estas plantas suelen tener permiso para funcionar las 24 horas del día.</span></p>",
                  "x": "1419px",
                  "y": "881px",
                  "width": "1179px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.2",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span class=\"JsGRdQ\"><i>You have received this notice because living in close proximity of the proposed facility may give you the right to challenge the permit.</i></span></p><p></p><span><p><span><i>Ha recibido este aviso porque vivir en proximidad a la instalación propuesta puede darle derecho a impugnar el permiso.</i></span><br></p></span>",
                  "x": "84px",
                  "y": "1700px",
                  "width": "2601px",
                  "align": "center",
                  "font": "dejaVu Sans",
                  "size": "33.33333333333333px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff"
      },
      {
          "images": [],
          "text": [
              {
                  "text": "<p></p><p><span><b>Submit public comments to the TCEQ regarding this permit application AND speak to your State Representative, {staterepname} at {staterepphone} and State Senator, {statesenatorname} at {statesenatorpphone} to request a public meeting by {publicactiondeadlineen}.&nbsp;</b></span></p><p><span><br></span></p><p><span>The TCEQ must organize a public meeting at the request of State Officials.&nbsp;</span></p>",
                  "x": "123px",
                  "y": "403px",
                  "width": "1236px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "45.833333333333336px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><span><b>Act now to protect your neighborhood!</b></span></p><p><b>¡Actúe ahora para proteger su vecindario!&nbsp;</b></p>",
                  "x": "122px",
                  "y": "146px",
                  "width": "1362px",
                  "align": "left",
                  "font": "DejaVu Sans",
                  "size": "58.333333333333336px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#c76870",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p>For more information on this permit application and how to challenge it, go to {noticeurl}.<br></p><p><span><br></span></p><p><span><span>Para más información sobre esta solicitud de permiso y cómo impugnarla, vaya a&nbsp;</span></span>{noticeurl}.</p>",
                  "x": "1665px",
                  "y": "540px",
                  "width": "983px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "41.66666666666667px",
                  "style": "normal",
                  "line_height": "1.3",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              },
              {
                  "text": "<p><b>Haga comentarios públicos a la TCEQ con respecto a esta solicitud de permiso Y hable con su representante estatal, {staterepname} al {staterepphone} y con el senador estatal {statesenatorname} al {statesenatorpphone} para solicitar una reunión pública antes del {publicactiondeadlinees}.</b></p><p><br></p><p>La TCEQ debe organizar una reunión pública a petición de los legisladores estatales locales.</p>",
                  "x": "123px",
                  "y": "1061px",
                  "width": "1235px",
                  "align": "left",
                  "font": "dejaVu Sans",
                  "size": "46px",
                  "style": "normal",
                  "line_height": "1.4",
                  "colour": "#333333",
                  "url": "",
                  "fixed_height": false,
                  "fit_text": false,
                  "rotate": 0,
                  "empty_lines": true
              }
          ],
          "objects": [],
          "background_scale": "auto",
          "background_colour": "#ffffff",
          "background_size": "auto"
      }
  ],
  "styles": [
      {
          "name": "cstyle0",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle1",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle2",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#c76870"
      },
      {
          "name": "cstyle3",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      },
      {
          "name": "cstyle4",
          "font": null,
          "size": null,
          "style": null,
          "colour": "#333333"
      }
  ]
};