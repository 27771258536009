import { useSelector } from 'react-redux';
import {
  LAST_MONTH,
  LAST_WEEK,
  DATE_TYPE_TO_FRIENDLY_NAME,
  TODAY,
  LAST_TWO_MONTHS,
  LAST_YEAR,
} from '../constants/notices';
import { activeFilterSelector, countySelector, dateRangeSelector } from '../selectors/notices';
import moment from 'moment';
import { prettyEnglishList } from '../utils/helpers';
import { useFiltersSortActiveStatus } from './useFiltersSortActiveStatus';
import { ALL_COUNTIES, COUNTY_ID_TO_FRIENDLY_NAME } from '../constants/counties';

// Returns a string that describes what notices are currently being shown.
// For example:
// "Displaying Harris County notices from the last week; search & 4 filters active"
export const useNoticeListDescription = () => {
  const selectedCounty = useSelector(countySelector);
  const { selection } = useSelector(dateRangeSelector);
  const activeFilter = useSelector(activeFilterSelector);
  const {
    anyFilterActive,
    sortActive,
    nameSearchActive,
    checkboxAndCodeFiltersActive,
    numCheckboxAndCodeFiltersActive,
  } = useFiltersSortActiveStatus();

  const { customStart, customEnd } = useSelector(dateRangeSelector);

  // Get text representing the date selection
  // Ex: "the last month", "12-01-2021 to 01-01-2022"
  let dateString = '';
  if (
    selection === LAST_TWO_MONTHS ||
    selection === LAST_YEAR ||
    selection === LAST_MONTH ||
    selection === LAST_WEEK
  ) {
    // For month & week, use "the last month" & "the last week" strings
    dateString = `the ${DATE_TYPE_TO_FRIENDLY_NAME[selection].toLowerCase()}`;
  } else if (selection === TODAY) {
    // For today, just use "today"
    dateString = DATE_TYPE_TO_FRIENDLY_NAME[selection].toLowerCase();
  } else {
    // For a custom range, use formatted start & end dates
    dateString = `${moment(customStart).format('MM-DD-YYYY')} to ${moment(customEnd).format(
      'MM-DD-YYYY'
    )}`;
  }

  // We'll combine the sorting, search, & other filters into a formatted string,
  // first gathering them in an array
  const sortSearchAndOtherFilters = [];

  // Add the word "sorting", if a non-default sort is used
  if (sortActive) {
    sortSearchAndOtherFilters.push('sorting');
  }

  // Just the word "search", if search is active
  if (nameSearchActive) {
    sortSearchAndOtherFilters.push('search');
  }

  // If at least one filter is active, add the number of active filters
  // Ex: "3 filters", "3 other filters"
  if (checkboxAndCodeFiltersActive) {
    const filtersString = `${numCheckboxAndCodeFiltersActive} ${
      nameSearchActive ? 'other' : ''
    } filter${numCheckboxAndCodeFiltersActive > 1 ? 's' : ''}`;
    sortSearchAndOtherFilters.push(filtersString);
  }

  // Get a nicely formatted list of these items
  let sortSearchAndOtherFiltersStr = '';
  if (sortSearchAndOtherFilters.length > 0) {
    sortSearchAndOtherFiltersStr = `; ${prettyEnglishList(sortSearchAndOtherFilters)} active`;
  }

  // Put everything together!
  return (
    <>
      {`Displaying `}
      {activeFilter ? (
        `saved filter: ${activeFilter.name}`
      ) : (
        <>
          <span className="highlight">
            {selectedCounty === ALL_COUNTIES ? 'all' : COUNTY_ID_TO_FRIENDLY_NAME[selectedCounty]}
          </span>
          {` notices from `}
          <span className="highlight">{`${dateString}${sortSearchAndOtherFiltersStr}${
            anyFilterActive ? '.' : ''
          }`}</span>
        </>
      )}
    </>
  );
};
