import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setActiveDropdown } from '../../../actions/notices';
import bookmarkIconWhite from '../../../assets/icons/bookmark_white.svg';
import bookmarkIconPrimary from '../../../assets/icons/bookmark_primary.svg';
import closeIcon from '../../../assets/icons/close.svg';
import searchPrimaryIcon from '../../../assets/icons/searchPrimary.svg';
import searchWhiteIcon from '../../../assets/icons/searchWhite.svg';
import {
  HEADER_HEIGHT,
  MAX_DESKTOP_WIDTH,
  PRIMARY,
  PRIMARY_DARK,
  SHADOW_CRISP,
} from '../../../constants/cssVars';
import {
  DATE_RANGE_DROPDOWN_ID,
  ENGAGEMENT_TYPE_FILTERS,
  FILTERS_DROPDOWN_ID,
  INDUSTRY_TYPE_FILTERS,
  NOTICE_TYPE_FILTERS,
  PROGRAM_AREA_FILTERS,
  PUBLIC_ACTION_DEADLINE_FILTERS,
  SAVED_FILTERS_DROPDOWN_ID,
  SORT_DROPDOWN_ID,
  dateOptions,
  engagementOptions,
  industryTypeOptions,
  noticeTypeOptions,
  programAreaOptions,
  publicActionDeadlineOptions,
  sortOptions,
  sortOptionsNASACounty,
} from '../../../constants/notices';
import { useFiltersSortActiveStatus } from '../../../hooks/useFiltersSortActiveStatus';
import { currentUserSelector } from '../../../selectors/entities';
import { activeDropdownSelector, activeFilterSelector } from '../../../selectors/notices';
import { CountyFilter } from '../../CountyFilter';
import {
  CodeFilterGroup,
  DateRadioButtonField,
  FilterCheckboxGroup,
  SortRadioButtonField,
} from '../../FormFields';
import SavedFiltersMenu from './SavedFiltersMenu';

const FiltersWrapper = styled.div`
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin-top: 15px;
  }
`;

const CloseButton = styled.img`
  height: 14px;
  width: 14px;
`;

const CloseWrapper = styled.button`
  color: ${PRIMARY};
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  background-color: transparent;
  display: none;
  font-size: 0.95em;
  &:focus {
    box-shadow: none !important;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
  }
`;

const DropdownWrapper = styled.div`
  display: inline-block;
  margin-right: 5px;
  position: relative;
  flex-grow: 1;
`;

const DropdownButton = styled.button`
  font-size: 0.9em;
  border: none;
  background-color: ${({ active }) => (active ? PRIMARY : '#fff')};
  border: 1px solid ${PRIMARY};
  padding: 2px 6px;
  width: 100%;
  color: ${({ active }) => (active ? '#fff' : PRIMARY)};
  cursor: pointer;
  font-weight: 600;
  transition: all 0.1s linear;
  &:hover {
    color: ${({ active }) => (active ? '#fff' : PRIMARY_DARK)};
    background-color: ${({ active }) => (active ? PRIMARY : '#D3EDF3')};
    border: 1px solid ${PRIMARY};
  }
`;

const DropdownItems = styled.div`
  position: absolute;
  top: calc(30px + 5px);
  right: 0px;
  z-index: 400;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  font-size: 0.9em;
  min-width: 300px;
  max-height: calc(100vh - ${HEADER_HEIGHT}px - 170px);
  overflow: ${({ scroll }) => (scroll ? 'auto' : 'visible')};
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    position: fixed;
    top: 20vh;
    left: 5%;
    right: 5%;
    max-height: 70vh;
  }
`;

const IconButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0px 5px;
  border: 1px solid ${PRIMARY};
  background-color: ${({ active }) => (active ? PRIMARY : 'transparent')};
  cursor: pointer;
  margin-right: 5px;
  vertical-align: top;
  height: 30px;
  > img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: ${({ active }) => (active ? PRIMARY : '#D3ECF2')};
    border: 1px solid ${PRIMARY};
  }
`;

// Three filter, sort & date-range buttons at the top of the sidebar. Updates redux state.
const NoticeFilterAndSort = ({ setShowSearch, showSearch, nasaCounty }) => {
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);
  const activeFilter = useSelector(activeFilterSelector);
  const { dateRangeActive, sortActive, nameSearchActive, checkboxAndCodeFiltersActive } =
    useFiltersSortActiveStatus();

  const handleClick = dropdownId => () => {
    dispatch(setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId));
  };

  const closeDropdown = () => {
    dispatch(setActiveDropdown(null));
  };

  const currentUser = useSelector(currentUserSelector);

  return (
    <>
      <FiltersWrapper>
        <CountyFilter />
        <DropdownWrapper>
          <DropdownButton
            active={checkboxAndCodeFiltersActive || activeDropdown === FILTERS_DROPDOWN_ID ? 1 : 0}
            id={`${FILTERS_DROPDOWN_ID}_btn`}
            onClick={handleClick(FILTERS_DROPDOWN_ID)}
          >
            Filters
          </DropdownButton>
          {activeDropdown === FILTERS_DROPDOWN_ID && (
            <DropdownItems scroll={true} id={FILTERS_DROPDOWN_ID} style={{ minWidth: '320px' }}>
              <FilterCheckboxGroup
                checkboxOptions={publicActionDeadlineOptions}
                label="Public Action Deadline"
                name={PUBLIC_ACTION_DEADLINE_FILTERS}
              />
              <FilterCheckboxGroup
                checkboxOptions={programAreaOptions}
                label="Program Area"
                name={PROGRAM_AREA_FILTERS}
              />
              <FilterCheckboxGroup
                checkboxOptions={industryTypeOptions}
                label="Industry Type"
                name={INDUSTRY_TYPE_FILTERS}
              />
              <CodeFilterGroup />
              <FilterCheckboxGroup
                checkboxOptions={noticeTypeOptions}
                label="Notice Type"
                name={NOTICE_TYPE_FILTERS}
              />
              <FilterCheckboxGroup
                checkboxOptions={engagementOptions}
                label="Public Actions"
                name={ENGAGEMENT_TYPE_FILTERS}
              />
              <CloseWrapper aria-label="close" type="button" onClick={closeDropdown}>
                <CloseButton tabIndex="0" src={closeIcon} alt="" />
              </CloseWrapper>
            </DropdownItems>
          )}
        </DropdownWrapper>
        <DropdownWrapper>
          <DropdownButton
            active={sortActive || activeDropdown === SORT_DROPDOWN_ID ? 1 : 0}
            id={`${SORT_DROPDOWN_ID}_btn`}
            onClick={handleClick(SORT_DROPDOWN_ID)}
          >
            Sort
          </DropdownButton>
          {activeDropdown === SORT_DROPDOWN_ID && (
            <DropdownItems id={SORT_DROPDOWN_ID}>
              <SortRadioButtonField
                options={nasaCounty ? sortOptionsNASACounty : sortOptions}
                label="Sort by ..."
              />
              <CloseWrapper aria-label="close" type="button" onClick={closeDropdown}>
                <CloseButton tabIndex="0" src={closeIcon} alt="" />
              </CloseWrapper>
            </DropdownItems>
          )}
        </DropdownWrapper>
        <DropdownWrapper>
          <DropdownButton
            active={dateRangeActive || activeDropdown === DATE_RANGE_DROPDOWN_ID ? 1 : 0}
            id={`${DATE_RANGE_DROPDOWN_ID}_btn`}
            onClick={handleClick(DATE_RANGE_DROPDOWN_ID)}
          >
            Date Range
          </DropdownButton>
          {activeDropdown === DATE_RANGE_DROPDOWN_ID && (
            <DropdownItems id={DATE_RANGE_DROPDOWN_ID}>
              <DateRadioButtonField options={dateOptions} label="Date Range (by issuance date)" />
              <CloseWrapper aria-label="close" type="button" onClick={closeDropdown}>
                <CloseButton tabIndex="0" src={closeIcon} alt="" />
              </CloseWrapper>
            </DropdownItems>
          )}
        </DropdownWrapper>
        <IconButton
          active={showSearch || nameSearchActive ? 1 : 0}
          onClick={() => {
            setShowSearch(!showSearch);
          }}
        >
          <img src={showSearch || nameSearchActive ? searchWhiteIcon : searchPrimaryIcon} alt="" />
        </IconButton>
        {currentUser && (
          <DropdownWrapper>
            <IconButton
              active={activeDropdown === SAVED_FILTERS_DROPDOWN_ID || activeFilter}
              id={`${SAVED_FILTERS_DROPDOWN_ID}_btn`}
              onClick={handleClick(SAVED_FILTERS_DROPDOWN_ID)}
            >
              <img
                src={
                  activeDropdown === SAVED_FILTERS_DROPDOWN_ID || activeFilter
                    ? bookmarkIconWhite
                    : bookmarkIconPrimary
                }
                alt=""
              />
            </IconButton>
            {activeDropdown === SAVED_FILTERS_DROPDOWN_ID && (
              <DropdownItems id={SAVED_FILTERS_DROPDOWN_ID} style={{ padding: 0 }}>
                <SavedFiltersMenu />
              </DropdownItems>
            )}
          </DropdownWrapper>
        )}
      </FiltersWrapper>
    </>
  );
};

export default NoticeFilterAndSort;
