import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import aahNav from "../../assets/aah_nav.png";
import styled from "styled-components";
import { setEmailSubscribeModal } from "../../actions/modals";
import {
  showEmailSubscribeModalSelector,
  showEmailUnsubscribeOptionSelector,
  showAutopopulateEmailSelector,
  showAutopopulateCountiesSelector,
} from "../../selectors/modals";
import { Modal } from "../general/Modal";
import { Button, LinkLikeButton } from "../general/Button";
import Multiselect from "multiselect-react-dropdown";
import { COUNTIES_OPTIONS } from "../../constants/counties";
import {
  useUpdateEmailSubscription,
  useDeleteEmailSubscription,
} from "../../hooks/messagesAndRequests";
import { isValidEmail } from "../../utils/validators";
import { FieldWrapper } from "../FormFields";
import { accountInfoSelector } from "../../selectors/entities";

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`;

export const EmailSubscribeModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(showEmailSubscribeModalSelector);
  const showUnsubscribeOption = useSelector(showEmailUnsubscribeOptionSelector);
  const autopopulateEmail = useSelector(showAutopopulateEmailSelector);
  const autopopulateCounties = useSelector(showAutopopulateCountiesSelector);

  const [userEmail, setUserEmail] = useState(autopopulateEmail);
  const [selectedCounties, setSelectedCounties] =
    useState(autopopulateCounties);

  const [updateSubscription] = useUpdateEmailSubscription();
  const [unsubscribe] = useDeleteEmailSubscription();

  // used for the error message not to show up until the user has blurred the field
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [countiesBlurred, setCountiesBlurred] = useState(false);

  // checks if the user being edited is the current user logged in
  const { email } = useSelector(accountInfoSelector);
  const isCurrentUser = userEmail === email;

  useEffect(() => {
    setUserEmail(autopopulateEmail);
    setSelectedCounties(autopopulateCounties);
  }, [autopopulateEmail, autopopulateCounties]);

  const onClose = () => {
    dispatch(setEmailSubscribeModal(false));

    // remove the emailSubscription query param from the url
    const url = new URL(window.location.href);
    url.searchParams.delete("emailSubscription");
    window.history.replaceState(null, null, url);
  };

  const handleSelectCounties = (selectedCountiesList) => {
    setSelectedCounties(selectedCountiesList);
  };

  if (!showModal) {
    return null;
  }

  const handleUpdateSubscription = () => {
    const counties = selectedCounties.map((county) => county.value);
    updateSubscription({ userEmail, counties, isCurrentUser });
    onClose();
  };

  const handleUnsubscribe = () => {
    const confirmedDelete = window.confirm(
      `Are you sure you want to unsubscribe this user from all emails?`
    );
    if (confirmedDelete) {
      unsubscribe({ userEmail, isCurrentUser });
      onClose();
    }
  };

  return (
    <Modal
      closeOnOuterClick={true}
      onClose={onClose}
      headerImg={aahNav}
      style={{
        width: "min(95%, 550px)",
        backgroundImage: `url(${aahNav})`,
        backgroundRepeat: "repeat-x",
      }}
    >
      <Title>
        {showUnsubscribeOption
          ? "Update email subscription"
          : "New Email Subscription"}
      </Title>
      {!showUnsubscribeOption && (
        <p style={{ marginBottom: "20px" }}>
          Subscribe to receive weekly email updates on the latest permits issued
          in specific counties.
        </p>
      )}

      <FieldWrapper
        label="Email"
        id="user-email"
        error={emailBlurred && !isValidEmail(userEmail) && "Required"}
      >
        <input
          type="email"
          value={userEmail}
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          style={{ margin: "5px 0px 10px 0px", maxWidth: "525px" }}
          onBlur={() => !emailBlurred && setEmailBlurred(true)}
          disabled={showUnsubscribeOption}
        />
      </FieldWrapper>

      <FieldWrapper
        label="Counties"
        id="counties-email-subscription"
        error={countiesBlurred && selectedCounties.length === 0 && "Required"}
      >
        <div onBlur={() => setCountiesBlurred(true)} style={{ width: "525px" }}>
          <Multiselect
            options={COUNTIES_OPTIONS}
            selectedValues={selectedCounties}
            onSelect={handleSelectCounties}
            onRemove={handleSelectCounties}
            displayValue="label"
            showCheckbox
            closeIcon="cancel"
            avoidHighlightFirstOption
            keepSearchTerm
            emptyRecordMsg="No counties found"
            style={{
              searchBox: {
                borderRadius: "0px",
                minHeight: "39px",
                marginTop: "5px",
              },
              inputField: { height: "20px" },
              chips: { background: "#00A7D9" },
            }}
          />
        </div>
      </FieldWrapper>

      <Button
        onClick={handleUpdateSubscription}
        disabled={!isValidEmail(userEmail) || selectedCounties.length === 0}
        style={{ marginTop: "25px" }}
        type="submit"
      >
        {showUnsubscribeOption ? "Save Changes" : "Subscribe"}
      </Button>

      {showUnsubscribeOption && (
        <>
          <hr style={{ margin: "30px 0px" }}></hr>
          <Title>Unsubscribe</Title>
          <LinkLikeButton onClick={handleUnsubscribe}>
            Unsubscribe from all AirMail email subscriptions
          </LinkLikeButton>
        </>
      )}
    </Modal>
  );
};
