import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import aahNav from '../../assets/aah_nav.png';
import styled from 'styled-components'
import { setLearnMoreModal } from '../../actions/modals';
import { showLearnMoreModalSelector } from '../../selectors/modals';
import { Modal } from '../general/Modal';
import { TextLink } from '../general/Common';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

export const LearnMoreModal = () => {
  const showModal = useSelector(showLearnMoreModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setLearnMoreModal(false));
  }
  
  if (!showModal) {
    return null;
  }

  return (
    // Made some style changes to the modal style to make it work on mobile view
    <Modal closeOnOuterClick={true} onClose={onClose} headerImg={aahNav} wrapperStyle={{ overflow: 'auto' }} style={{ width: 'min(80%, 550px)', backgroundImage: `url(${aahNav})`, backgroundRepeat: 'repeat-x', wordWrap: 'break-word', margin: 'auto 0' }}>
      <Title>NASA API</Title>
      <div style={{ fontSize: '0.9em' }}>
        <p>
          AirMail utilizes a residential address database to provide the “Number of Residences in Area” and address lists for select counties. 
          At this time, these counties include Harris, Jefferson, Nueces, and San Patricio. 
          For all other counties, AirMail utilizes a NASA Socioeconomic Data and Applications Center (SEDAC) web service to estimate the “Number of Residences in Area”.
        </p>
        <p>
          The service provides population estimates based on a gridded population of the world. 
          It is not a count of residential addresses, and has limited specificity (approximately .5 miles). 
          You may notice that the “Number of Residence in Area” slider and other address related functionality is limited for these counties. 
          However, we hope the population estimate will still be useful! 
        </p>
        <p>
          You can learn more about the NASA SEDAC population estimation service here: <TextLink to="https://sedac.ciesin.columbia.edu/data/collection/gpw-v4">https://sedac.ciesin.columbia.edu/data/collection/gpw-v4</TextLink>
        </p>
      </div>
    </Modal>
  )
}