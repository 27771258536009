import moment from 'moment';
import {
  SET_RANGE,
  SET_FILTERS,
  SET_SORT,
  SET_DATE_RANGE,
  SET_ACTIVE_DROPDOWN,
  SET_COUNTY,
  SET_SEARCH,
  SET_FILTER_SORT_TO_DEFAULT,
  SET_NOTICES_LIST_VIEW,
  SET_NOTICES_FROM_SEARCH_PARAMS,
  SET_ACTIVE_FILTER,
} from '../actions/notices';
import { HARRIS } from '../constants/counties';
import {
  INDUSTRY_TYPE_FILTERS,
  NOTICE_TYPE_FILTERS,
  CONCRETE_BATCH,
  PETROCHEM_REFINERY,
  LANDFILL,
  GENERAL_MISC,
  CONTESTED_CASE_HEARING_REQ,
  NORI,
  NOAPD,
  ACTION_DEADLINE,
  NOA,
  NPM,
  SOAH,
  NPH,
  ENGAGEMENT_TYPE_FILTERS,
  PUBLIC_COMMENT,
  PUBLIC_MEETING_REQUEST,
  TERMINALS,
  PROGRAM_AREA_FILTERS,
  AIR_QUALITY,
  WATER_QUALITY,
  MUNICIPAL_SOLID_WASTE,
  NAME_SEARCH,
  NAICS_SEARCH,
  SIC_SEARCH,
  RN_SEARCH,
  NOR,
  MINOR_AMENDMENT,
  PERMIT_NUM_SEARCH,
  LAST_TWO_MONTHS,
  NOTICE_AND_COMMENT_HEARING_REQ,
  PUBLIC_ACTION_DEADLINE_FILTERS,
  DEADLINE_PASSED,
  DEADLINE_NOT_PASSED,
  CUSTOM_RANGE,
  DEADLINE_UNKNOWN,
} from '../constants/notices';
import { getDateRangeFromSelection } from '../utils/helpers';

const today = new Date();

export const defaultNoticesState = {
  activeFilter: null, // Whether a user is looking at a pre-saved filter
  range: 4,
  sort: ACTION_DEADLINE,
  dateRange: {
    selection: LAST_TWO_MONTHS,
    customStart: moment().subtract(2, 'months').toDate(), // only shown in UI with CUSTOM_RANGE selection
    customEnd: today, // only shown in UI with CUSTOM_RANGE selection
  },
  activeDropdown: null,
  county: HARRIS,
  listView: false,
  filters: {
    [INDUSTRY_TYPE_FILTERS]: {
      [CONCRETE_BATCH]: true,
      [PETROCHEM_REFINERY]: true,
      [LANDFILL]: true,
      [GENERAL_MISC]: true,
      [TERMINALS]: true,
    },
    [PROGRAM_AREA_FILTERS]: {
      [AIR_QUALITY]: true,
      [WATER_QUALITY]: true,
      [MUNICIPAL_SOLID_WASTE]: true,
    },
    [NOTICE_TYPE_FILTERS]: {
      [NORI]: true,
      [NOAPD]: true,
      [NOA]: true,
      [NPM]: true,
      [SOAH]: true,
      [NPH]: true,
      [NOR]: true,
      [MINOR_AMENDMENT]: true,
    },
    [ENGAGEMENT_TYPE_FILTERS]: {
      [PUBLIC_COMMENT]: true,
      [PUBLIC_MEETING_REQUEST]: true,
      [CONTESTED_CASE_HEARING_REQ]: true,
      [NOTICE_AND_COMMENT_HEARING_REQ]: true,
    },
    [PUBLIC_ACTION_DEADLINE_FILTERS]: {
      [DEADLINE_PASSED]: true,
      [DEADLINE_NOT_PASSED]: true,
      [DEADLINE_UNKNOWN]: true,
    },
  },
  searches: {
    [NAME_SEARCH]: '',
    [NAICS_SEARCH]: '',
    [SIC_SEARCH]: '',
    [RN_SEARCH]: '',
    [PERMIT_NUM_SEARCH]: '',
  },
};

const serializeDate = date => +date;
const deserializeDate = dateString => new Date(dateString);

/**
 * Recursively filters state to only the values that have changed
 * from the default values. This allows us to only write to the
 * query strings the values we actually need to update, keeping
 * the links from being too verbose.
 * Only primitive types of string, number and boolean are
 * supported for serialization. Supports nested objects
 */
const filterDefaultsAndSerialize = (obj, defaultObj) => {
  return Object.entries(obj).reduce((a, [k, v]) => {
    if (!Object.hasOwn(defaultObj, k)) {
      // We're trying to serialize something that doesn't
      // exist in the store. This should never happen, but
      // is a safegaurd.
      return a;
    }
    // Don't support serialization of null or undefined values,
    // because it's too difficult to do type checking on
    // deserialization
    if (v === null || v === undefined) {
      return a;
    }
    if (['string', 'number', 'boolean'].includes(typeof v)) {
      // State differs from default state, add it to
      // the object we will serialize
      if (v !== defaultObj[k]) {
        a[k] = v;
      }
    } else if (v instanceof Date && +v !== +defaultObj[k]) {
      // You can't JSON.stringify dates, so serialize first
      a[k] = serializeDate(v);
    } else if (Array.isArray(v)) {
      // Currently no array values in this store...
      return a;
    } else {
      const filtered = filterDefaultsAndSerialize(v, defaultObj[k]);
      if (Object.keys(filtered).length) {
        a[k] = filtered;
      }
    }
    return a;
  }, {});
};

export const serializeNoticeFilters = notices => {
  const { county, dateRange, filters, range, searches, sort } = notices;

  // These are the values in this state that we want to make
  // configurable via query string
  const stateToSerialize = {
    county,
    dateRange,
    filters,
    range,
    searches,
    sort,
  };

  return JSON.stringify(filterDefaultsAndSerialize(stateToSerialize, defaultNoticesState));
};

/**
 * Checks the types of our parsed query params. If the types
 * match the default types in the store, then we will update
 * our state with them. If not, they are ignored. We fill the
 * rest of state with the default state so that we only have
 * to store non-default values in the query string.
 */
const checkTypesAndDeserialize = (obj, defaultObj) => {
  return Object.entries(obj).reduce((a, [k, v]) => {
    // This key doesn't exist in the redux store, ignore it
    // This will happen if someone tries to inject a key in
    // the query params
    if (!Object.hasOwn(defaultObj, k)) {
      return a;
    }

    const defaultValue = defaultObj[k];
    const defaultValueType = typeof defaultValue;
    if (
      ['string', 'number', 'boolean'].includes(defaultValueType) &&
      typeof v === defaultValueType
    ) {
      // If the value in the store is a primitive type, and the incoming
      // type matches then add it to the state (non-mutating)
      return {
        ...a,
        [k]: v,
      };
    } else if (defaultValue instanceof Date && typeof v === 'number') {
      return {
        ...a,
        [k]: deserializeDate(v),
      };
    } else if (Array.isArray(defaultValue) && Array.isArray(v)) {
      // We don't support array values
      return {
        ...a,
      };
    } else {
      // If the value isn't a primitive or a date, then recursively
      // call this method to copy nested values
      return {
        ...a,
        [k]: checkTypesAndDeserialize(v, defaultValue),
      };
    }
  }, defaultObj);
};

const validateStateDateRange = state => {
  if (state.dateRange.selection === CUSTOM_RANGE) {
    // If we have a custom range as the query params selection then we don't have to update
    // the date range selection to keep things in sync.
    return state;
  }
  const expectedEndDate = state.dateRange.customEnd;
  const today = new Date();
  const isSameDay = moment(expectedEndDate).isSame(moment(today), 'day');
  if (isSameDay) {
    // In this case the end date is current so we can keep the UI showing the
    // date range selected (i.e. "Today", "Last Week" etc.)
    return state;
  }
  // The date range selection is out of sync with the actual timestamps.
  // This will happen if a shared link is viewed the following day. In this case
  // we override the date selection and set it to Custom Range
  return {
    ...state,
    dateRange: {
      ...state.dateRange,
      selection: CUSTOM_RANGE,
    },
  };
};

const noticesReducer = (state = defaultNoticesState, action) => {
  switch (action.type) {
    case SET_RANGE:
      return {
        ...state,
        range: action.payload,
      };
    case SET_SORT:
      return {
        ...state,
        activeFilter: null,
        sort: action.payload,
      };
    case SET_COUNTY:
      return {
        ...state,
        activeFilter: null,
        county: action.payload,
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        activeFilter: null,
        dateRange: action.payload,
      };
    case SET_ACTIVE_DROPDOWN:
      return {
        ...state,
        activeDropdown: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        activeFilter: null,
        searches: {
          ...state.searches,
          [action.payload.searchType]: action.payload.searchStr,
        },
      };
    case SET_NOTICES_LIST_VIEW:
      return {
        ...state,
        listView: action.payload,
      };
    case SET_FILTER_SORT_TO_DEFAULT:
      return {
        ...state,
        activeFilter: null,
        sort: defaultNoticesState.sort,
        dateRange: defaultNoticesState.dateRange,
        filters: defaultNoticesState.filters,
        searches: defaultNoticesState.searches,
      };
    case SET_FILTERS:
      return {
        ...state,
        activeFilter: null,
        filters: {
          ...state.filters,
          [action.payload.filterType]: {
            ...state.filters[action.payload.filterType],
            [action.payload.value]: action.payload.checked,
          },
        },
      };
    case SET_NOTICES_FROM_SEARCH_PARAMS:
      try {
        const defaultState = {
          county: defaultNoticesState.county,
          dateRange: defaultNoticesState.dateRange,
          filters: defaultNoticesState.filters,
          range: defaultNoticesState.range,
          searches: defaultNoticesState.searches,
          sort: defaultNoticesState.sort,
        };
        // Once we deserialize the state, we make sure that we set
        // the dateRange.selection value to CUSTOM_RANGE. This ensures
        // that when a user shares a link with search params we show the
        // same list of notices based on the timestamps in the params.
        const deserializedState = validateStateDateRange(
          checkTypesAndDeserialize(action.payload, defaultState)
        );
        return {
          ...state,
          ...deserializedState,
        };
      } catch (error) {
        // In the event we cannot successfully deserialize state,
        // log the error but return the current state so that the
        // app still loads.
        console.error(error);
        return state;
      }
    case SET_ACTIVE_FILTER:
      const parsedPayload = action.payload?.filter ? JSON.parse(action.payload.filter) : {};
      try {
        const defaultState = {
          county: defaultNoticesState.county,
          dateRange: defaultNoticesState.dateRange,
          filters: defaultNoticesState.filters,
          range: defaultNoticesState.range,
          searches: defaultNoticesState.searches,
          sort: defaultNoticesState.sort,
        };
        let deserializedState = checkTypesAndDeserialize(parsedPayload, defaultState);
        // Once we deserialize the state, we want to update the date timestamps based on the
        // saved date range. This ensures that if, say, a user saves a filter with a date
        // selection of TODAY, they will always see the notices from the day in which they
        // load their filter
        const dateRangeSelection = deserializedState.dateRange?.selection;
        if (dateRangeSelection) {
          const dateRange = getDateRangeFromSelection(dateRangeSelection);
          if (dateRange) {
            deserializedState = {
              ...deserializedState,
              dateRange: {
                ...deserializedState.dateRange,
                customStart: dateRange[0],
                customEnd: dateRange[1],
              },
            };
          }
        }
        return {
          ...state,
          ...deserializedState,
          activeFilter: action.payload,
        };
      } catch (error) {
        // In the event we cannot successfully deserialize state,
        // log the error but return the current state so that the
        // app still loads.
        console.error(error);
        return state;
      }
    default:
      return state;
  }
};

export default noticesReducer;
