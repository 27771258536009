import { useSelector } from 'react-redux';
import { noticesSelector } from '../selectors/entities';
import {
  ENGAGEMENT_TYPE_FILTERS,
  INDUSTRY_TYPE_FILTERS,
  NOTICE_TYPE_FILTERS,
  GENERAL_MISC,
  ACTION_DEADLINE,
  ACTION_DEADLINE_ASC,
  PROGRAM_AREA_FILTERS,
  ISSUANCE_DATE,
  NAME_SEARCH,
  RN_SEARCH,
  NAICS_SEARCH,
  SIC_SEARCH,
  PERMIT_NUM_SEARCH,
  PUBLIC_ACTION_DEADLINE_FILTERS,
  DEADLINE_PASSED,
  DEADLINE_NOT_PASSED,
  DEADLINE_UNKNOWN,
} from '../constants/notices';
import {
  countySelector,
  dateRangeSelector,
  filterValuesSelector,
  searchSelector,
  sortSelector,
} from '../selectors/notices';
import moment from 'moment';
import { useCurrentNotice } from './useCurrentNotice';
import { stringIsEmptyOrNull } from '../utils/helpers';
import { ALL_COUNTIES } from '../constants/counties';
import { getActionDeadlineHasPassed } from '../components/app/Map';

const sortFunction = (a, b, type) => {
  // For issuance date, sort low to high
  if (type === ISSUANCE_DATE) {
    if (a[type] < b[type]) {
      return -1;
    }
    if (a[type] > b[type]) {
      return 1;
    }
    return 0;
  }
  // For public action deadline (ascending), sort low to high on the "publicActionDeadline" field
  if (type === ACTION_DEADLINE_ASC) {
    // Null values always come last
    if (a[ACTION_DEADLINE === null] && b[ACTION_DEADLINE === null]) {
      return 0;
    }
    if (a[ACTION_DEADLINE === null]) {
      return 1;
    }
    if (b[ACTION_DEADLINE === null]) {
      return -1;
    }
    if (a[ACTION_DEADLINE] < b[ACTION_DEADLINE]) {
      return -1;
    }
    if (a[ACTION_DEADLINE] > b[ACTION_DEADLINE]) {
      return 1;
    }
    return 0;
  }
  // For public action deadline (descending) & all residences in radius queries, sort high to low
  if (type === ACTION_DEADLINE) {
    if (a[type] < b[type]) {
      return 1;
    }
    if (a[type] > b[type]) {
      return -1;
    }
    return 0;
  }

  // For public total comment, in a lot of cases because there are no public comments available on the TCEQ site
  // publicTotalComments will be undefined, the next two statements move these notices to the end
  if (a[type] === undefined && b[type] !== undefined) {
    return 1;
  }
  if (a[type] !== undefined && b[type] === undefined) {
    return -1;
  }

  // For number of residences in 0.25, 0.5, 1 and 2 miles, sort high to low
  // Null values (i.e. Unknown residents) will be sorted higher than 0 residents
  if (a[type] > b[type]) {
    return -1;
  }
  if (a[type] == null && b[type] === 0) {
    return -1;
  }
  if (a[type] === 0 && b[type] == null) {
    return 1;
  }
  if (a[type] < b[type]) {
    return 1;
  }
  return 0;
};

const containsSubstr = (str, substr) => {
  if (substr == null) {
    return true;
  }
  if (str == null) {
    return false;
  }
  return str.toLowerCase().includes(substr.toLowerCase());
};

// For NAICS & SIC code filtering
const filterByIndustryTypeCodeSearch = (notice, type, searchStr) => {
  // Don't out notice if the search term is null
  if (stringIsEmptyOrNull(searchStr)) {
    return true;
  }
  // Otherwise, check to see if industry type codes contain matching values for searchStr
  if (Array.isArray(notice.industryTypeCodes) && notice.industryTypeCodes.length > 0) {
    return notice.industryTypeCodes.some(
      ({ codeSystem, code }) => codeSystem === type && containsSubstr(code, searchStr)
    );
  }
  // If there are no industry type codes, don't count as a match
  return false;
};

// Given a date object, convert it to UTC.
// Used to compare filter-selected dates with notice object dates
export const getMomentUTC = dateObj => {
  const momentObj = moment(dateObj);
  const year = momentObj.get('year');
  const month = momentObj.get('month');
  const date = momentObj.get('date');
  const momentObjUTC = moment.utc({ year, month, date });
  return momentObjUTC;
};

// Returns the filtered & sorted notices based on current selection
export const useFilteredNotices = () => {
  const notices = useSelector(noticesSelector);
  const selectedNotice = useCurrentNotice();

  // Pull all the different pieces of redux state we'll use to filter down our notices
  const industryTypeFilters = useSelector(state =>
    filterValuesSelector(state, INDUSTRY_TYPE_FILTERS)
  );
  const programAreaFilters = useSelector(state =>
    filterValuesSelector(state, PROGRAM_AREA_FILTERS)
  );
  const noticeTypeFilters = useSelector(state => filterValuesSelector(state, NOTICE_TYPE_FILTERS));
  const publicActionDeadlineFilters = useSelector(state =>
    filterValuesSelector(state, PUBLIC_ACTION_DEADLINE_FILTERS)
  );
  const selectedCounty = useSelector(countySelector);
  const engagementFilters = useSelector(state =>
    filterValuesSelector(state, ENGAGEMENT_TYPE_FILTERS)
  );
  const allEngagementFiltersActive = Object.values(engagementFilters).every(
    engagementFilterValue => engagementFilterValue
  );
  const sortBy = useSelector(sortSelector);
  const { customStart, customEnd } = useSelector(dateRangeSelector);
  const searches = useSelector(searchSelector);
  const startDate = getMomentUTC(customStart);
  const endDate = getMomentUTC(customEnd);

  // Actually filter those notices!
  const getFilteredNotices = noticesToFilter =>
    noticesToFilter
      // County filter
      .filter(notice => (selectedCounty === ALL_COUNTIES ? true : notice.county === selectedCounty))
      // Date range filter defaults to issuance date but uses date notice mailed as a back up if the issuance is null
      .filter(notice => {
        const targetDate = moment(notice.issuanceDate || notice.dateNoticeMailed).utc();
        return targetDate.isBetween(startDate, endDate, 'days', '[]');
      })
      // Industry type filter
      .filter(notice => {
        // We want to include notice if it has at least one industry type code matching an active filter
        if (Array.isArray(notice.industryTypeCodes) && notice.industryTypeCodes.length > 0) {
          return notice.industryTypeCodes.some(({ category }) => industryTypeFilters[category]);
        }
        // if there are no industry type codes, only return notice if `General/Misc` is active
        return industryTypeFilters[GENERAL_MISC];
      })
      // Main search filter
      .filter(notice => {
        // If there is no active search, we can skip filtering
        if (!searches[NAME_SEARCH] || searches[NAME_SEARCH].length < 1) {
          return true;
        }
        // Otherwise, match on facility name, principal name, or industry type code name
        return (
          containsSubstr(notice.facilityName, searches[NAME_SEARCH]) ||
          containsSubstr(notice.principal, searches[NAME_SEARCH]) ||
          notice.industryTypeCodes.some(({ industryName }) =>
            containsSubstr(industryName, searches[NAME_SEARCH])
          )
        );
      })
      .filter(notice => programAreaFilters[notice.programArea])
      .filter(notice => noticeTypeFilters[notice.noticeType])
      .filter(notice => {
        if (
          publicActionDeadlineFilters[DEADLINE_PASSED] &&
          publicActionDeadlineFilters[DEADLINE_NOT_PASSED] &&
          publicActionDeadlineFilters[DEADLINE_UNKNOWN]
        ) {
          return true;
        }
        if (
          !publicActionDeadlineFilters[DEADLINE_PASSED] &&
          !publicActionDeadlineFilters[DEADLINE_NOT_PASSED] &&
          !publicActionDeadlineFilters[DEADLINE_UNKNOWN]
        ) {
          return false;
        }
        const hasPassed = getActionDeadlineHasPassed(notice);
        if (typeof hasPassed === 'boolean') {
          return hasPassed
            ? publicActionDeadlineFilters[DEADLINE_PASSED]
            : publicActionDeadlineFilters[DEADLINE_NOT_PASSED];
        }
        return publicActionDeadlineFilters[DEADLINE_UNKNOWN];
      })
      .filter(notice => {
        // If all filters are active, don't filter out by public engagement action
        // - so even if there are no public actions for a notice, it will still be returned.
        if (allEngagementFiltersActive) {
          return true;
        }
        // Check if any of the notice's actions are present in the active filters
        return (
          notice.publicActions && notice.publicActions.some(action => engagementFilters[action])
        );
      })
      .filter(notice => containsSubstr(notice.rnNumber, searches[RN_SEARCH]))
      .filter(notice => containsSubstr(notice.permitNumber, searches[PERMIT_NUM_SEARCH]))
      .filter(notice => filterByIndustryTypeCodeSearch(notice, 'NAICS', searches[NAICS_SEARCH]))
      .filter(notice => filterByIndustryTypeCodeSearch(notice, 'SIC', searches[SIC_SEARCH]))
      .sort((a, b) => sortFunction(a, b, sortBy));

  const filteredNotices = getFilteredNotices(notices);

  // If the currently selected notice is filtered out, add it back in
  if (selectedNotice != null && getFilteredNotices([selectedNotice]).length === 0) {
    filteredNotices.push(selectedNotice);
  }

  return filteredNotices;
};
