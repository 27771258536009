import * as html2canvas from 'html2canvas';

const initDownload = (dataUrl, name) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = dataUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const createScreenshot = (wrapperRef, onSceenshotComplete) => {
  const wrapperEl = wrapperRef.current;
  if (!wrapperEl) {
    // The DOM element should definitely exist, but protect against undefined
    throw new Error('Wrapper ref is null');
  }

  const leafletPane = wrapperEl.getElementsByClassName('leaflet-map-pane')[0];
  if (!leafletPane) {
    // Expect a map as part of the screenshot
    throw new Error('Unable to find leaflet map pane');
  }

  html2canvas(wrapperEl, {
    ignoreElements: el =>
      [...el.classList].includes('leaflet-control-geosearch') || // omit search bar
      [...el.classList].includes('leaflet-control-zoom') || // omit zoom controls
      el.tagName === 'BUTTON', // omit screenshot button
    logging: false,
    onclone: clone => {
      const tiles = [...clone.getElementsByClassName('leaflet-tile')];
      tiles.forEach(tile => {
        tile.style.opacity = 1;
      });
    },
    scale: 1, // Ensures that the size of the output is fixed despite different device pixel ratios
    useCORS: true, // Need to set this to true to load leaflet images
  }).then(screenshotCanvas => {
    const url = screenshotCanvas.toDataURL('image/png');
    initDownload(url, 'AirMail_notices_map');
    onSceenshotComplete();
    return;
  });
};
