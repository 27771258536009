import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { clearStore } from '../actions/clearStore';
import { SESSION_EXP_STORAGE_KEY } from '../constants/general';

// Returns function to clear state and redirect to the homepage with an error message
// For use when an unauthorized status is found
export const useHandleUnauthorized = (dest) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return () => {
    dispatch(clearStore());
    localStorage.removeItem(SESSION_EXP_STORAGE_KEY);
    toast.info("Session expired");
    navigate(`/${dest ? dest : 'login'}`);
  }
}
