export const stannpSliderSelector = state => state.general.showStannpSlider;
export const includeStateLegislatorsInAddressesSelector = state =>
  state.general.includeStateLegislatorsInAddresses;
export const ughSelector = state => state.general;
export const pinDroppedSelector = state => state.general.showPinDropped;
export const pinCoordinatesSelector = state => state.general.showPinCoordinates;
export const noticePinDropSelector = state => state.general.showNoticePinDrop;
export const customAddressesSelector = state => state.general.customAddresses;
export const selectedAddressLocationSelector = state => state.general.selectedAddressLocation;
export const visiblePlacesSelector = state => state.general.visiblePlaces;
