import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  SHADOW_MEDIUM,
  SIDEBAR_WIDTH,
  HEADER_HEIGHT,
  GREY_MEDIUM,
  MAX_DESKTOP_WIDTH,
  LEFT_SIDEBAR_WIDTH,
} from '../../constants/cssVars';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { listViewSelector } from '../../selectors/notices';
import { onMobile } from '../../utils/helpers';

const SidebarWrapper = styled.section`
  width: ${({ left }) => (left ? LEFT_SIDEBAR_WIDTH : SIDEBAR_WIDTH)}px;
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  ${({ left }) => (left ? 'left' : 'right')}: 0px;
  background-color: #fff;
  z-index: 500;
  box-shadow: ${SHADOW_MEDIUM};
  border-left: 2px solid ${GREY_MEDIUM};
  box-sizing: border-box;
  display: block;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: ${({ active, showMapUnderneath }) => (active || showMapUnderneath ? 'block' : 'none')};
    position: ${({ showMapUnderneath }) => (showMapUnderneath ? 'relative' : 'absolute')};
    top: ${({ showMapUnderneath }) => (showMapUnderneath ? '0' : HEADER_HEIGHT)}px;
    width: 100%;
  }
`;

const NoticesSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing border-box;
  padding: 15px 15px 0 15px;
  height: ${({ showMapUnderneath }) =>
    showMapUnderneath ? 'auto' : `calc(100vh - ${HEADER_HEIGHT}px)`};
`;

const SidebarContent = styled.div`
  display: block;
  height: calc(100vh - ${HEADER_HEIGHT}px - 20px);
  padding: 10px 15px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: ${HEADER_HEIGHT * 3.5}px;
  }
`;

export const NoticesSidebar = ({ children, left, width }) => {
  const showList = useSelector(listViewSelector);

  const currentNotice = useCurrentNotice();
  // Show the map underneath the details if on mobile and on notice details page
  const showMapUnderneath = currentNotice != null && onMobile();

  return (
    <SidebarWrapper
      active={showList ? 1 : 0}
      showMapUnderneath={showMapUnderneath}
      width={width}
      left={left}
    >
      <NoticesSidebarContent showMapUnderneath={showMapUnderneath}>
        {children}
      </NoticesSidebarContent>
    </SidebarWrapper>
  );
};

export const Sidebar = ({ children, left, width }) => {
  return (
    <SidebarWrapper active={1} width={width} left={left}>
      <SidebarContent>{children}</SidebarContent>
    </SidebarWrapper>
  );
};
