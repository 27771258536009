import Circle from '@arcgis/core/geometry/Circle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { setIncludeStateLegislatorsInAddresses, setStannpSlider } from '../../actions/general';
import {
  setCreateCampaignModal,
  setEditNoticeModal,
  setIndustryTypeCodesModal,
} from '../../actions/modals';
import { getAddressesQuery } from '../../actions/queries';
import alertIcon from '../../assets/icons/alert-icon.svg';
import backIcon from '../../assets/icons/back_arrow.svg';
import chevronPrimary from '../../assets/icons/chevron_primary.svg';
import questionMarkIcon from '../../assets/icons/question_mark.svg';
import { COUNTIES_WITH_AAH_ORGANIZATION, COUNTIES_WITH_ADDRESSES } from '../../constants/counties';
import { ERROR, GREY_DARK, GREY_MEDIUM_DARK, HEADER_HEIGHT, PRIMARY_DARK, PRIMARY } from '../../constants/cssVars';
import {
  AAH_URL_BY_INDUSTRY_TYPE,
  GENERAL_MISC,
  NOTICE_TYPE_TO_FULL_NAME,
  NPM,
  NPH,
  SOAH,
  PROGRAM_AREA_TO_FRIENDLY_NAME,
  PUBLIC_ACTION_TO_FULL_NAME,
  PUBLIC_COMMENT,
} from '../../constants/notices';
import {
  useGetNumberOfAddresses,
  useGetNumberOfAddressesNASACounty,
  useGetNumberOfPlaces,
} from '../../hooks/messagesAndRequests';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { useDebounce } from '../../hooks/useDebounce';
import { useRange } from '../../hooks/useRange';
import { useNoticeParamString } from '../../hooks/useSyncNoticeFilterParams';
import { currentUserSelector } from '../../selectors/entities';
import { includeStateLegislatorsInAddressesSelector } from '../../selectors/general';
import { showEditNoticeModal } from '../../selectors/modals';
import { getCategoriesForNotice, getPublicCommentUrl } from '../../utils/helpers';
import { ReduxCheckbox } from '../FormFields';
import { Button, ButtonLikeLink, LinkLikeButton } from '../general/Button';
import { TextLink } from '../general/Common';
import { Tooltip } from '../general/Tooltip';
import { AddressDownloadButton } from './AddressDownloadButton';
import { MailMergeDownloadButton } from './MailMergeDownloadButton';
import { getActionDeadlineHasPassed } from './Map';
import { NoticesSidebar } from './Sidebar';
import ShareSocial from './ShareSocial';

const SliderLabels = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  > div {
    display: inline-block;
    font-size: 0.8em;
    color: ${GREY_MEDIUM_DARK};
  }
`;

const Footer = styled.div`
  width: 100%;
  width: calc(100% - 20px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  text-align: center;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  > button {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  > button:last-child {
    font-size: 0.9em;
    color: #a53a18;
  }
`;

const SmallText = styled.div`
  margin: 10px 0px;
  color: ${GREY_DARK};
  font-size: 0.9em;
`;

const Text = styled.p`
  margin: 0px;
  margin-bottom: 4px;
  font-size: 0.95em;
  color: ${GREY_DARK};
  > b {
    font-size: 1.1em;
  }
  ${({ enhancedBold }) =>
    enhancedBold
      ? `
  > b {
    margin: 0px 3px;
    font-size: 1.6em;
  }`
      : ''}
`;

const ContentWrapper = styled.div`
  max-height: calc(100vh - 50px - ${HEADER_HEIGHT}px);
  overflow: scroll;
  > p {
    font-size: 0.9em;
  }
  > h4 {
    margin-bottom: 10px;
  }
`;

const ActionsUl = styled.ul`
  font-size: 0.9em;
  color: ${GREY_DARK};
  margin: 0px;
  > li {
    margin: 0px;
    padding: 0px;
  }
`;

const BackArrow = styled.img`
  width: 30px;
  height: 20px;
`;

const IconButton = styled.button`
  border: none;
  background-color: ${({ active }) => (active ? PRIMARY : '#fff')};
  border: 1px solid ${PRIMARY};
  padding: 0 2px;
  width: 30px;
  height: 30px;
  color: ${({ active }) => (active ? '#fff' : PRIMARY)};
  cursor: pointer;
  transition: all 0.1s linear;
  &:hover {
    color: ${({ active }) => (active ? '#fff' : PRIMARY_DARK)};
    background-color: ${({ active }) => (active ? PRIMARY : '#D3EDF3')};
    border: 1px solid ${PRIMARY};
  }
`;

const Organizing = styled.div`
  border: 1px solid ${PRIMARY_DARK};
  border-radius: 4px;
  padding: 6px 6px 6px 8px;
  display: flex;
  margin: 4px 0 6px;
  img {
    display: inline;
    width: 16px;
    height: 16px;
  }
  p {
    font-size: 0.75em;
    line-height: 1em;
    margin: 0;
    padding-left: 6px;
  }
`;

const PermitDetailsAbout = styled.div`
  border: 1px solid #919191;
  border-radius: 50%;
  display: inline-block;
  width: 9px;
  height: 9px;
  padding: 2px;
  margin-left: 8px;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    > div {
      left: 50%;
      transform: translateY(-6px);
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

// Helper function to get the string coordinates of the area where the NASA API will search for addresses
// Parameters: lng and lat (center point) and a radius in miles
// Returns: string of coordinates in the format "[[lng,lat],[lng,lat]]" with the brackets and commas in ASCII characters
const getAreaCoordinates = (lng, lat, rangeInMiles) => {
  const circle = new Circle({
    center: [lng, lat],
    radius: rangeInMiles,
    numberOfPoints: 99,
    radiusUnit: 'miles',
  });

  const circleString = JSON.stringify(circle.toJSON()['rings'][0]);
  const asciiCodes = {
    '[': '%5B',
    ']': '%5D',
    ',': '%2C',
  };
  return circleString.replaceAll(/[[\],]/g, match => asciiCodes[match]);
};

export const RadiusSlider = ({ coordinates, usingAddressApproximations }) => {
  let [range, setRange, rangeInMiles] = useRange(usingAddressApproximations);

  // Number of residences in current range, to be updated by a query to the BE
  const [numResidences, setNumResidences] = useState(0);
  const [numPlaces, setNumPlaces] = useState(0);
  const [debouncing, setDebouncing] = useState(false);

  // Number of residences fetched from the BE for the 4 counties with addresses
  const [sendRequest, loading] = useGetNumberOfAddresses(respBody => {
    setNumResidences(respBody.numAddresses);
    setDebouncing(false);
  });

  const [sendPlacesRequest, placesLoading] = useGetNumberOfPlaces(respBody => {
    setNumPlaces(respBody.numPlaces);
    setDebouncing(false);
  });

  // Number of residences fetched from the NASA API
  const [sendNASACountyRequest, loadingNASACounty] = useGetNumberOfAddressesNASACounty(respBody => {
    setNumResidences(
      respBody['results'][0]['value']['estimates']['gpw-v4-population-count-rev10_2020']['SUM']
    );
    setDebouncing(false);
  });

  useEffect(() => {
    // Checks if this is a NASA county to set range in 0.5 mile increments
    if (usingAddressApproximations) {
      // Sets radius to 3 miles max (otherwise if you select 3 miles in a non-NASA county first, it will show 6 miles)
      if (range > 6) {
        setRange(6);
      }
    }
  }, [coordinates, rangeInMiles]);

  useDebounce(
    () => {
      // Checks if this is a NASA county to set range in 0.5 mile increments
      if (usingAddressApproximations) {
        // A string of coordinates that form the area where the NASA API will calculate the number of residences
        const circleCoordinates = getAreaCoordinates(coordinates[0], coordinates[1], rangeInMiles);
        sendNASACountyRequest({
          circle: circleCoordinates,
        });
      } else {
        sendRequest({
          lng: coordinates[0],
          lat: coordinates[1],
          radiusMiles: rangeInMiles,
        });
      }
      sendPlacesRequest({
        lng: coordinates[0],
        lat: coordinates[1],
        radiusMiles: rangeInMiles,
      });
    },
    () => setDebouncing(true),
    400,
    [coordinates, rangeInMiles]
  );

  const rangeInMilesText = `${rangeInMiles} mile${rangeInMiles === 1 ? '' : 's'}`;

  return (
    <>
      <div style={{ width: '95%', fontSize: '0.95em' }}>
        <Text>
          Use the slider below to adjust the size of the circle on the map & calculate the number of{' '}
          {usingAddressApproximations ? 'residents' : 'residences'} in the circle's area.
        </Text>
        <Text>
          Selected radius: <b>{rangeInMilesText}</b>
        </Text>
        <input
          type="range"
          value={range}
          onChange={e => setRange(e.target.value)}
          style={{ marginBottom: '0px' }}
          max={usingAddressApproximations ? 6 : 12}
          min={0}
          step={1}
          list="tickmarks"
        />
        <SliderLabels>
          <div>0</div>
          <div>1</div>
          <div>2</div>
          <div>3</div>
        </SliderLabels>
        {loadingNASACounty || loading || placesLoading || debouncing ? (
          <Text style={{ height: '4em', boxSizing: 'border-box', paddingTop: '10px' }}>
            <i>Loading ...</i>
          </Text>
        ) : (
          <Text enhancedBold={true}>
            There are approximately <b>{numResidences}</b>{' '}
            {usingAddressApproximations ? 'residents' : 'residences'} within {rangeInMilesText} of
            this facility.
            <br />
            There are also <b>{numPlaces}</b> child care centers, hospitals, nursing homes, and
            schools within {rangeInMilesText} of this facility. Click the pin icon on the map to
            view the locations of these places of concern.
          </Text>
        )}
      </div>
    </>
  );
};

const StannpInstructionsButton = () => {
  const dispatch = useDispatch();
  return <Button onClick={() => dispatch(setStannpSlider(true))}>View Stannp Instructions</Button>;
};

// Shows a chunk of text as a link if "showAsLink" boolean is true, otherwise displays as plain text
const ConditionalLinkDisplay = ({ text, to, showAsLink }) => {
  if (showAsLink) {
    return <TextLink to={to}>{text}</TextLink>;
  }
  return text;
};

export const NoticeDetailsHeader = ({ notice, renderForExport = false }) => {
  const [detailViewActive, setDetailViewActive] = useState(false);
  const dispatch = useDispatch();

  const details = [
    ['Principal', notice.principal],
    [
      'Type',
      getCategoriesForNotice(notice).join(', '),
      <>
        {` `}
        {getCategoriesForNotice(notice).length > 0 ? (
          <LinkLikeButton
            style={{ fontWeight: 400, fontStyle: 'italic' }}
            onClick={() => dispatch(setIndustryTypeCodesModal(true))}
          >
            (view codes)
          </LinkLikeButton>
        ) : (
          'None'
        )}
      </>,
    ],
    ['Residences within one mile', notice.residencesInMile],
    ['Places of concern within one mile', notice.placesInMile],
  ];

  const detailsMore = [
    ['Notice Type', NOTICE_TYPE_TO_FULL_NAME[notice.noticeType]],
    [
      'Issuance Date',
      notice.issuanceDate
        ? moment(new Date(notice.issuanceDate)).utc().format('MM/DD/YYYY')
        : `Issuance date unknown (Mailed on ${moment(new Date(notice.issuanceDate))
            .utc()
            .format('MM/DD/YYYY')})`,
    ],
    [
      'Permit Number',
      notice.permitInformationLink ? (
        <TextLink style={{ fontSize: '0.9em' }} to={notice.permitInformationLink}>
          {notice.permitNumber}
        </TextLink>
      ) : (
        notice.permitNumber
      ),
    ],
    ['Program Area', PROGRAM_AREA_TO_FRIENDLY_NAME[notice.programArea]],
  ];

  return (
    <>
      <h3 style={{ margin: '10px 0px 0px 0px' }}>{notice.facilityName}</h3>
      <SmallText style={{ margin: '0px 0px 10px 0px' }}>
        <span style={{ fontStyle: 'italic' }}>{notice.address}</span>
      </SmallText>
      {notice.important && (
        <Organizing>
          <img src={alertIcon} alt="important icon" />
          <p>There is currently organizing around this permit.</p>
        </Organizing>
      )}
      {!notice.location && (
        <SmallText style={{ margin: '-10px 0px 10px 0px' }}>
          <span style={{ color: ERROR, fontStyle: 'italic' }}>
            Please note: We are unable to determine the exact location of this facility
          </span>
        </SmallText>
      )}
      {details.map(([key, detail, detailExtended]) => (
        <SmallText key={key} style={{ margin: '0px' }}>
          {`${key}: ${detail}`}
          {!renderForExport && detailExtended}
        </SmallText>
      ))}
      {!renderForExport && (
        <>
          <SmallText style={{ margin: '0px' }}>
            <TextLink style={{ fontSize: '0.9em' }} to={notice.noticeUrl}>
              View Notice PDF
            </TextLink>
          </SmallText>
          {notice.permitInformationLink && (
            <SmallText style={{ alignItems: 'center', display: 'flex', margin: '0px' }}>
              <TextLink style={{ fontSize: '0.9em' }} to={notice.permitInformationLink}>
                Facility History
              </TextLink>
              <PermitDetailsAbout>
                <Tooltip content="Click to view facility history including any prior violations or complaints.">
                  <img src={questionMarkIcon} aria-label="about" alt="" />
                </Tooltip>
              </PermitDetailsAbout>
            </SmallText>
          )}

          <LinkLikeButton
            style={{ fontSize: '0.9em', fontWeight: 400 }}
            onClick={() => setDetailViewActive(!detailViewActive)}
          >
            Show more notice details
            <img
              style={{
                height: '0.9em',
                margin: '0 0 0 4px',
                transform: `translateY(3px)rotate(${detailViewActive ? '0' : '-90'}deg)`,
                width: '1em',
              }}
              src={chevronPrimary}
              alt="show-more"
            />
          </LinkLikeButton>
          {detailViewActive && (
            <>
              {detailsMore.map(([key, detail]) => (
                <SmallText key={key} style={{ margin: '0px' }}>
                  {`${key}: `}
                  {detail}
                </SmallText>
              ))}
              <SmallText style={{ margin: '0px' }}>
                {`RN Number: ${notice.rnNumber} `}
                <i>
                  <TextLink to={notice.rnLink}>(view entity)</TextLink>
                </i>
              </SmallText>
            </>
          )}
        </>
      )}
    </>
  );
};

export const PublicMeetingNotice = ({ notice, renderForExport = false }) => {
  const isPublicMeeting = notice.noticeType === NPM;
  const isPublicHearing = notice.noticeType === NPH;
  const isSoahHearing = notice.noticeType === SOAH;
  return (
    <>
      {(isPublicMeeting || isPublicHearing || isSoahHearing) && (
        <>
          <h4>
            {isSoahHearing ? 'SOAH' : 'Public'} {isPublicMeeting ? 'Meeting' : 'Hearing'}
          </h4>
          {notice.publicMeetingDayEn != null && (
            <SmallText>
              A {isSoahHearing ? 'SOAH' : 'public'} {isPublicMeeting ? 'meeting' : 'hearing'} is
              occurring for this facility on{' '}
              <strong>
                {notice.publicMeetingDayEn} {notice.publicMeetingDateEn},{' '}
                {notice.publicMeetingTimeEn}
              </strong>{' '}
              at {notice.publicMeetingAddress}.
            </SmallText>
          )}
          {!renderForExport && (
            <SmallText>
              Please check the <TextLink to={notice.noticeUrl}>notice PDF</TextLink> for details.
            </SmallText>
          )}
        </>
      )}
    </>
  );
};

export const PublicActions = ({ notice, renderForExport = false }) => {
  const publicActionDeadlinePassed = getActionDeadlineHasPassed(notice);

  // Checks whether this notice is located in a county that AAH organizes public actions
  const countyWithAAHOrganization = COUNTIES_WITH_AAH_ORGANIZATION.includes(notice.county);

  // Grab the first non general industry type code to use for the AAH url link; if one doesn't exist, use general/other link
  const nonGeneralIndustryCodes = notice.industryTypeCodes.filter(
    itc => itc.category !== GENERAL_MISC
  );
  const primaryIndustryType =
    nonGeneralIndustryCodes.length > 0 ? nonGeneralIndustryCodes[0].category : GENERAL_MISC;

  return (
    <>
      <h4>Available Public Actions</h4>
      {notice.publicActionDeadline ? (
        publicActionDeadlinePassed ? (
          <SmallText>
            Based on our approximation by notice issuance date, the opportunity for public actions{' '}
            <strong>has passed</strong>. We approximated the deadline to be{' '}
            {moment(new Date(notice.publicActionDeadline)).format('MM/DD/YYYY')}. The available
            public actions included:
          </SmallText>
        ) : (
          <SmallText>
            The public has until{' '}
            <Tooltip content="*This is an approximation based on the notice issuance date.">
              <b>{moment(new Date(notice.publicActionDeadline)).utc().format('MM/DD/YYYY')}</b>*
            </Tooltip>{' '}
            to participate in the following actions:
          </SmallText>
        )
      ) : (
        <SmallText>
          Please note that we are unable to determine the deadline for available public actions for
          this notice. Please view the <TextLink to={notice.noticeUrl}>notice PDF</TextLink> for
          more information.
        </SmallText>
      )}
      {notice.publicActions ? (
        <ActionsUl>
          {notice.publicActions
            .sort(action => (action === PUBLIC_COMMENT ? -1 : 1))
            .map((action, index) => {
              return (
                <li key={`${index}_action_${notice._id}`}>
                  {action === PUBLIC_COMMENT &&
                  notice.publicActionDeadline &&
                  !publicActionDeadlinePassed &&
                  !renderForExport ? (
                    <TextLink to={getPublicCommentUrl(notice)}>
                      {PUBLIC_ACTION_TO_FULL_NAME[action]}
                    </TextLink>
                  ) : (
                    PUBLIC_ACTION_TO_FULL_NAME[action]
                  )}
                </li>
              );
            })}
        </ActionsUl>
      ) : (
        <div style={{ fontSize: '0.9em', color: GREY_DARK }}>
          No public actions were detected by our scraper for this notice.
          {!renderForExport && (
            <>
              Please review the
              <TextLink to={notice.noticeUrl}>notice PDF</TextLink> to confirm if any public actions
              are available.
            </>
          )}
        </div>
      )}
      {!renderForExport && (
        <>
          <SmallText>
            For <strong>full information and instructions</strong> on available actions, please read
            the <TextLink to={notice.noticeUrl}>notice pdf</TextLink>.
          </SmallText>
          <SmallText>
            See Air Alliance Houston's{' '}
            <TextLink
              to={'https://airalliancehouston.org/public-resources/public-engagement-guides/'}
            >
              public engagement guides
            </TextLink>{' '}
            for resources on reading notices, submitting comments, & requesting hearings.
            {countyWithAAHOrganization && (
              <>
                {' '}
                Check if AAH is currently{' '}
                <TextLink to={AAH_URL_BY_INDUSTRY_TYPE[primaryIndustryType]}>
                  organizing for this facility here
                </TextLink>
                .
              </>
            )}
          </SmallText>
        </>
      )}
    </>
  );
};

export const StateLegislators = ({ notice, renderForExport = false }) => {
  return (
    <>
      <h4>Contact Your State Legislators</h4>
      <SmallText>
        State legislators can request a TCEQ public meeting on behalf of their district. The
        legislators in this facility's district are:
      </SmallText>
      {(notice.stateLegislators || []).map((legislatorObj, idx) => {
        return (
          <SmallText key={`state_leg_${idx}`}>
            <div style={{ fontWeight: 600 }}>{legislatorObj.officialName}</div>
            <div>
              State {legislatorObj.title}, District {legislatorObj.district}
            </div>
            {!renderForExport && <div>{legislatorObj.address}</div>}
            <div>{legislatorObj.phone}</div>
            <div>{legislatorObj.email}</div>
          </SmallText>
        );
      })}
    </>
  );
};

const PublicEngagement = ({ notice, isLoggedIn = false }) => {
  return (
    <div style={{ marginBottom: isLoggedIn ? '50px' : '0px' }}>
      <h4 style={{ marginBottom: '5px' }}>Public Comments and Requests</h4>
      <SmallText style={{ fontStyle: 'italic' }}>
        AirMail updates these numbers once a week. For current numbers, please visit the TCEQ
        website <TextLink to="https://www14.tceq.texas.gov/epic/eCID/">here</TextLink>
      </SmallText>
      {/* If the notice contains less than 5 comments, provide instructions on how to find more comment info on the TCEQ site */}
      {!notice.publicCommentsUrl && (
        <SmallText style={{ fontStyle: 'italic' }}>
          On the TCEQ site form, enter at least the Principal name from above, the County, and then
          select both options under Step Three.
        </SmallText>
      )}
      <SmallText>
        <b>{notice.publicTotalComments || 'No'}</b> comments/requests have been submitted.{' '}
        {/* The url will only display when there are more than 5 comments */}
        {notice.publicCommentsUrl && <TextLink to={notice.publicCommentsUrl}>View all</TextLink>}
      </SmallText>
      {isLoggedIn && notice.publicTotalComments > 0 && (
        // Display comment subcategories only if they exist
        <ActionsUl>
          {notice.publicWrittenComments > 0 && (
            <li>{notice.publicWrittenComments} written comments</li>
          )}
          {notice.publicOralComments > 0 && (
            <li>{notice.publicOralComments} oral comments at public meetings</li>
          )}
          {notice.publicMeetingRequests > 0 && (
            <li>{notice.publicMeetingRequests} public meeting requests</li>
          )}
          {notice.publicHearingRequests > 0 && (
            <li>{notice.publicHearingRequests} public hearing requests</li>
          )}
          {/* Other =  Mailing List Add, Request For Reconsideration / Public meeting, etc. */}
          {notice.publicOtherComments > 0 && (
            <li>{notice.publicOtherComments} other comments/requests</li>
          )}
        </ActionsUl>
      )}
    </div>
  );
};

const NoticeDetailsSidebarPublic = ({
  notice,
  publicActionDeadlineHasNotPassed,
  usingAddressApproximations,
}) => {
  return (
    <ContentWrapper>
      <NoticeDetailsHeader notice={notice} />
      <PublicMeetingNotice notice={notice} />
      <PublicActions notice={notice} />
      <StateLegislators notice={notice} />

      {notice.location && (
        <>
          <h4>Number of {usingAddressApproximations ? 'Residents' : 'Residences'} in Area</h4>{' '}
          <RadiusSlider
            coordinates={notice.location.coordinates}
            usingAddressApproximations={usingAddressApproximations}
          />
        </>
      )}
      <PublicEngagement notice={notice} />
      <div
        style={{ width: '80%', height: publicActionDeadlineHasNotPassed ? '100px' : '40px' }}
      ></div>
    </ContentWrapper>
  );
};

const NoticeDetailsSidebarLoggedIn = ({
  addressQueryIsFinished,
  includeStateLegislators,
  notice,
  publicActionDeadlineHasNotPassed,
  usingAddressApproximations,
}) => {
  const dispatch = useDispatch();

  return (
    <ContentWrapper>
      <NoticeDetailsHeader notice={notice} />
      {notice.location && (
        <>
          <h4>Number of {usingAddressApproximations ? 'Residents' : 'Residences'} in Area</h4>
          <RadiusSlider
            coordinates={notice.location.coordinates}
            usingAddressApproximations={usingAddressApproximations}
          />
        </>
      )}
      {!usingAddressApproximations && <MailMergeDownloadButton />}
      {!usingAddressApproximations && notice.location && (
        <AddressDownloadButton
          isLoading={!addressQueryIsFinished}
          style={{ fontSize: '0.9em' }}
          linkLikeButton={true}
        />
      )}
      {!usingAddressApproximations && notice.location && (
        <ReduxCheckbox
          label="Add state legislators to address list"
          value="includeStateLegislatorsInAddresses"
          checked={includeStateLegislators}
          onChange={() => dispatch(setIncludeStateLegislatorsInAddresses(!includeStateLegislators))}
          wrapperStyle={{ fontSize: '0.9em' }}
        />
      )}
      <PublicMeetingNotice notice={notice} />
      <PublicActions notice={notice} />
      <StateLegislators loggedIn={true} notice={notice} />
      <PublicEngagement notice={notice} isLoggedIn={true} />
      <div
        style={{ width: '80%', height: publicActionDeadlineHasNotPassed ? '100px' : '40px' }}
      ></div>
    </ContentWrapper>
  );
};

export const NoticeDetailsSidebar = () => {
  const dispatch = useDispatch();
  const includeStateLegislators = useSelector(includeStateLegislatorsInAddressesSelector);
  const notice = useCurrentNotice();
  const currentUser = useSelector(currentUserSelector);
  const adminView = currentUser && currentUser.admin;
  const { aahOrApp } = useParams();
  // Returns whether the public action deadline is after the current date
  const publicActionDeadlineHasNotPassed = !getActionDeadlineHasPassed(notice);

  // Checks whether this notice is located in a county that uses the NASA API
  const usingAddressApproximations = !COUNTIES_WITH_ADDRESSES.includes(notice.county);

  // Checks whether this notice is located in a county that AAH organizes public actions
  const countyWithAAHOrganization = COUNTIES_WITH_AAH_ORGANIZATION.includes(notice.county);

  const [, , rangeInMiles] = useRange();
  // Only query for addresses (more than number) if there is a logged in user
  const [{ isFinished }] = useRequest(
    notice && currentUser && notice.location
      ? getAddressesQuery({
          noticeId: notice._id,
          lng: notice.location.coordinates[0],
          lat: notice.location.coordinates[1],
          radiusMiles: rangeInMiles,
        })
      : null
  );
  // count as finished if query is finished or was never sent (because there is no logged in user)
  const addressQueryIsFinished = notice && currentUser ? isFinished : true;

  const noticeParams = useNoticeParamString();

  const editNoticeModalOpen = useSelector(showEditNoticeModal);

  return (
    <NoticesSidebar>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to={`/${aahOrApp}/notices/?${noticeParams}`}>
          <BackArrow src={backIcon} alt="" aria-label="back" />
        </Link>
        <div style={{ display: 'flex', gap: '8px' }}>
          {currentUser && (
            <IconButton
              active={editNoticeModalOpen}
              onClick={event => {
                event.preventDefault();
                dispatch(setEditNoticeModal({ notice }));
              }}
            >
              <FontAwesomeIcon icon={faPencil} />
            </IconButton>
          )}
          <ShareSocial shareHeaderText="Share this notice" />
        </div>
      </div>
      <div>
        {currentUser ? (
          <NoticeDetailsSidebarLoggedIn
            addressQueryIsFinished={addressQueryIsFinished}
            includeStateLegislators={includeStateLegislators}
            notice={notice}
            publicActionDeadlineHasNotPassed={publicActionDeadlineHasNotPassed}
            usingAddressApproximations={usingAddressApproximations}
          />
        ) : (
          <NoticeDetailsSidebarPublic
            notice={notice}
            publicActionDeadlineHasNotPassed={publicActionDeadlineHasNotPassed}
            usingAddressApproximations={usingAddressApproximations}
          />
        )}
        <Footer>
          {/* For admin users in counties that don't use the NASA API, show "Send mailers" button in footer */}
          {adminView && !usingAddressApproximations && notice.location && (
            <Button
              onClick={() => dispatch(setCreateCampaignModal(true))}
              isLoading={!addressQueryIsFinished}
            >
              Send mailers
            </Button>
          )}
          {/* For non-admin users, show Stannp instructions button in footer */}
          {currentUser && !adminView && <StannpInstructionsButton notice={notice} />}
          {/* For public (no login) users, show link to public comment button (if the deadline hasn't passed) in footer*/}
          {!currentUser && publicActionDeadlineHasNotPassed && (
            <ButtonLikeLink to={getPublicCommentUrl(notice)}>Submit public comment</ButtonLikeLink>
          )}
        </Footer>
      </div>
    </NoticesSidebar>
  );
};
