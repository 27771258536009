export const SET_STANNP_SLIDER = 'SET_STANNP_SLIDER';
export const SET_INCLUDE_STATE_LEGISLATORS_IN_ADDRESSES =
  'SET_INCLUDE_STATE_LEGISLATORS_IN_ADDRESSES';
export const SET_PIN_DROPPED = 'SET_PIN_DROPPED';
export const SET_PIN_COORDINATES = 'SET_PIN_COORDINATES';
export const SET_NOTICE_PIN_DROP = 'SET_NOTICE_PIN_DROP';
export const ADD_STANNP_PIN_DROP = 'ADD_STANNP_PIN_DROP';
export const SET_CUSTOM_ADDRESSES = 'SET_CUSTOM_ADDRESSES';
export const SET_SELECTED_ADDRESS_LOCATION = 'SET_SELECTED_ADDRESS_LOCATION';
export const SET_VISIBLE_PLACES = 'SET_VISIBLE_PLACES';

export const setStannpSlider = showSlider => {
  return { type: SET_STANNP_SLIDER, payload: showSlider };
};

export const setIncludeStateLegislatorsInAddresses = includeStateReps => {
  return { type: SET_INCLUDE_STATE_LEGISLATORS_IN_ADDRESSES, payload: includeStateReps };
};

export const setPinDropped = pinDropped => {
  return { type: SET_PIN_DROPPED, payload: pinDropped };
};

export const setPinCoordinates = coordinates => {
  return { type: SET_PIN_COORDINATES, payload: coordinates };
};

export const setNoticePinDrop = (coordinates, stateLegislators) => {
  return { type: SET_NOTICE_PIN_DROP, payload: { coordinates, stateLegislators } };
};

export const addStannpPinDrop = stannpData => {
  return { type: ADD_STANNP_PIN_DROP, payload: stannpData };
};

export const setCustomAddresses = customAddresses => {
  return { type: SET_CUSTOM_ADDRESSES, payload: customAddresses };
};

export const setSelectedAddressLocation = location => {
  return { type: SET_SELECTED_ADDRESS_LOCATION, payload: location };
};

export const setVisiblePlaces = visiblePlaces => {
  return { type: SET_VISIBLE_PLACES, payload: visiblePlaces };
};
