export const GREY_DARKEST = '#121212';
export const GREY_DARK = '#444444';
export const GREY_DARKER = '#252525';
export const GREY_MEDIUM_DARK = '#686868';
export const GREY_MEDIUM = '#CECECE';
export const GREY_MEDIUM_LIGHT = '#dedede';
export const GREY_LIGHT = '#F0F0F0';
export const GREY_LIGHTER = '#F7F7F7';
export const GREY_LIGHTEST = '#FAFAFA';
export const WHITE = '#FFFFFF';
export const TABLE_GREY = '#E9E9E9';

// AAH COLORS
export const GALE_GREY = '#A1BAC3';
export const VAPOR_GREY = '#E6E7E8';
export const TEMPEST = '#18718B';

export const PRIMARY = '#00A7D9';
export const PRIMARY_LIGHT = '#4DC2E1';
export const PRIMARY_DARK = '#0481B0';
export const SECONDARY = '#D3EDF3';
export const SECONDARY_LIGHT = '#7284D6';
export const TERTIARY = '#4F2489';
export const TERTIARY_LIGHT = '#8968B6';
export const HIGHLIGHT = '#FFC20A';
export const ERROR = '#BD2D2D';

export const BG_LIGHTER = '#F9FAFA';
export const BG_PRIMARY = '#F2F6F6';
export const BG_DARKER = '#F2F6F6';

export const SHADOW_MEDIUM = '0 3px 6px rgba(0,0,0,.14)';
export const SHADOW_LIGHT = '0 2px 2px rgba(0,0,0,.06)';
export const SHADOW_CRISP = '0 2px 4px rgba(0,0,0,.12)';
export const SHADOW_CRISP_DARK = '0 2px 4px rgba(0,0,0,.2)';
export const SHADOW_DARK = '0 4px 8px rgba(0,0,0,.2)';
export const SHADOW_MENU = '-3px 0px 8px rgba(0,0,0,.2)';
export const SHADOW_NONE = '0px 0px 0px rgba(0,0,0,0)';

export const HEADER_HEIGHT = 40;
export const MAIN_CONTENT_WIDTH = 1000;
export const MAX_DESKTOP_WIDTH = MAIN_CONTENT_WIDTH + 20;
export const SIDEBAR_WIDTH = 400;
export const LEFT_SIDEBAR_WIDTH = 250;

// JA LOGO
export const JA_LOGO_HEIGHT = 27;
export const JA_BAR_WIDTH = 200;
