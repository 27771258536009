import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { noticeByIdSelector } from '../selectors/entities';
import qs from 'query-string'
import { useEffect } from 'react';
import { countySelector } from '../selectors/notices';
import { setCounty } from '../actions/notices';

// Selects the current notice from url query param
export const useCurrentNotice = () => {
  const dispatch = useDispatch()
  const selectedCounty = useSelector(countySelector)
  const location = useLocation()
  const queryParams = qs.parse(location.search)
  const notice = useSelector(state => noticeByIdSelector(state, queryParams.notice_id));

  // If on initial load, there is a notice specified (someone is visiting a direct link),
  // update the county to match the notice's county
  useEffect(() => {
    if (notice && notice.county !== selectedCounty) {
      dispatch(setCounty(notice.county))
    }
  }, [])

  return notice;
}
