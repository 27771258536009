import { useEffect } from "react";

// Source: https://dev.to/rajeshroyal/how-to-make-a-custom-debounce-hook-in-react-js-4gcc
export const useDebounce = (delayedCallback, immediateCallback, delay, deps) => {
  useEffect(() => {
    immediateCallback && immediateCallback()
    const handler = setTimeout(() => delayedCallback(), delay);

    return () => clearTimeout(handler);
    // using || operator because 
    // if its optional then it can be undefined.
  }, [...(deps || []), delay]);
};