import L from 'leaflet';

import { PLACES_ICONS } from '../../../constants/map';

export const getMapIcon = (actionDeadlineHasPassed, important) => {
  return L.divIcon({
    html: ``,
    className: actionDeadlineHasPassed
      ? 'cluster-marker-grey'
      : important
      ? 'cluster-marker-important'
      : 'cluster-marker-blue',
    iconSize: L.point(14, 14, true),
  });
};

export const getPlaceOfInterestIcon = type => {
  const iconInfo = PLACES_ICONS[type];
  if (!iconInfo) {
    return null;
  }

  return L.icon({
    iconUrl: iconInfo.icon,
    iconSize: [20, 20],
  });
};
