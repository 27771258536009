import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import alertIcon from '../../assets/icons/alert-icon.svg';
import { setEditNoticeModal } from '../../actions/modals';
import { showEditNoticeModal } from '../../selectors/modals';
import { Modal, ModalFooter } from '../general/Modal';
import { GREY_LIGHTER } from '../../constants/cssVars';
import { Button, ButtonWrapper, LinkLikeButton } from '../general/Button';
import { CheckboxField, FieldWrapper, TextField } from '../FormFields';
import { useEditNoticeDetails } from '../../hooks/messagesAndRequests';

const PNarrow = styled.p`
  margin: 5px 0px;
`;

const OrganizingWrapper = styled.div`
  label {
    margin-bottom: 4px;
  }
`;

/**
 * Returns an object with all the changes made to the notice, formatted properly for the
 * server to ingest.
 */
const getChangesMade = ({ initialValues, values }) => {
  const changes = {};
  if (values.important !== Boolean(initialValues.important)) {
    changes.important = values.important;
  }
  if (+values.publicActionDeadline !== +initialValues.publicActionDeadline) {
    changes.publicActionDeadline = `${moment(values.publicActionDeadline).format(
      'YYYY-MM-DD'
    )}T00:00:00.000Z`;
  }
  if (values.facilityNameEdited.trim() !== initialValues.facilityNameEdited.trim()) {
    changes.facilityNameEdited = values.facilityNameEdited.trim();
  }
  return changes;
};

/**
 * Component responsible for tracking which of the parent values have changed,
 * and formatting fields so that only relvant, properly-formatted fields are sent
 * to the server
 */
const NotifyFormikChanges = ({ initialValues, setChangesMade, values }) => {
  useEffect(() => {
    const changes = getChangesMade({ initialValues, values });
    setChangesMade(Boolean(Object.values(changes).length));
  }, [initialValues, setChangesMade, values]);
  return null;
};

export const EditNoticeModal = () => {
  const notice = useSelector(showEditNoticeModal);
  const dispatch = useDispatch();

  const [changesMade, setChangesMade] = useState(false);

  const closeWithWarning = () => {
    if (changesMade) {
      if (
        window.confirm(
          'You have made unsaved changes to this notice, are you sure you would like to close and revert your changes?'
        )
      ) {
        dispatch(setEditNoticeModal({ notice: null }));
        return;
      }
      return;
    }
    dispatch(setEditNoticeModal({ notice: null }));
  };

  const [submitEdits, submitting] = useEditNoticeDetails(() =>
    dispatch(setEditNoticeModal({ notice: null }))
  );

  if (!notice) {
    return null;
  }

  const initialValues = {
    facilityNameEdited: notice.facilityName,
    important: Boolean(notice.important),
    publicActionDeadline: moment(notice.publicActionDeadline).utc().local(true).toDate(),
  };

  return (
    <Modal
      closeOnOuterClick={true}
      onClose={closeWithWarning}
      wrapperStyle={{ overflow: 'auto' }}
      style={{
        width: 'min(95%, 550px)',
        wordWrap: 'break-word',
        margin: 'auto 0',
      }}
    >
      <div style={{ fontSize: '0.9em', marginTop: '8px' }}>
        <h3 style={{ margin: '0 0 8px 0' }}>{notice.facilityName}</h3>
        <PNarrow style={{ margin: '5px 0' }}>{notice.principal}</PNarrow>
        <PNarrow style={{ margin: '5px 0' }}>
          Issued on {moment(new Date(notice.issuanceDate)).utc().format('MM/DD/YYYY')}
        </PNarrow>
        <div
          style={{
            background: GREY_LIGHTER,
            padding: '16px',
            borderRadius: '8px',
            marginTop: '24px',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '16px' }}>Edit Notice Details</div>
          <Formik
            initialValues={initialValues}
            onSubmit={values => {
              const changes = getChangesMade({ initialValues, values });
              if (Object.values(changes).length === 0) {
                dispatch(setEditNoticeModal({ notice: null }));
                return;
              }
              let facilityNameReverted = false;
              if (changes.facilityNameEdited === notice.facilityNameRaw) {
                facilityNameReverted = true;
              }
              submitEdits({
                noticeId: notice._id,
                ...changes,
                ...(facilityNameReverted ? { facilityNameEdited: '' } : {}),
              });
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <>
                  <TextField
                    name="facilityNameEdited"
                    label={
                      <>
                        Facility Display Name
                        {notice.facilityNameRaw !== values.facilityNameEdited && (
                          <LinkLikeButton
                            onClick={() => {
                              setFieldValue('facilityNameEdited', notice.facilityNameRaw);
                            }}
                            style={{ fontWeight: 'normal', marginLeft: '8px' }}
                          >
                            Reset
                          </LinkLikeButton>
                        )}
                      </>
                    }
                  />
                  <FieldWrapper label={`Public action deadline`} style={{ marginBottom: '24px' }}>
                    <DatePicker
                      name={'publicActionDeadline'}
                      onChange={val => {
                        setFieldValue('publicActionDeadline', val);
                      }}
                      shouldCloseOnSelect={true}
                      selected={values.publicActionDeadline}
                    />
                  </FieldWrapper>
                  <OrganizingWrapper>
                    <CheckboxField
                      name="important"
                      testId="important"
                      label="Currently organizing around this permit"
                    />
                    <div
                      style={{
                        fontSize: '0.75em',
                        marginLeft: '34px',
                      }}
                    >
                      When checked, these notices are shown with a
                      <img
                        style={{
                          width: '16px',
                          height: '16px',
                          margin: '0 4px',
                          transform: 'translateY(2px)',
                        }}
                        src={alertIcon}
                        alt="important icon"
                      />
                      marker on the map and are also highlighted in the notice list.
                    </div>
                  </OrganizingWrapper>
                  <ModalFooter style={{ marginTop: '28px', justifyContent: 'center' }}>
                    <ButtonWrapper>
                      <Button
                        disabled={!changesMade}
                        isLoading={submitting}
                        onClick={handleSubmit}
                        type="submit"
                      >
                        Update Notice Details
                      </Button>
                    </ButtonWrapper>
                  </ModalFooter>
                  <NotifyFormikChanges
                    initialValues={initialValues}
                    setChangesMade={setChangesMade}
                    values={values}
                  />
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
