import {
  SET_ABOUT_US_MODAL,
  SET_EMAIL_SUBSCRIBE_MODAL,
  SET_NEW_USER_MODAL,
  SET_CREATE_CAMPAIGN_MODAL,
  SET_INDUSTRY_TYPE_CODES_MODAL,
  SET_CUSTOM_ADDRESSES_MODAL,
  SET_LEARN_MORE_MODAL,
  SET_EDIT_NOTICE_MODAL,
} from '../actions/modals';

// displays the email subscription modal on start if the query param
// contains emailSubscription=true, otherwise it displays the about us modal
let defaultStateAboutUsModal = false;
let defaultStateEmailSubscribeModal = false;
window.location.search.includes('emailSubscription=true')
  ? (defaultStateEmailSubscribeModal = true)
  : (defaultStateAboutUsModal = true);

const defaultState = {
  showNewUserModal: null,
  showAboutUsModal: defaultStateAboutUsModal,
  autopopulateEmail: '',
  autopopulateCounties: [],
  showEmailSubscribeModal: defaultStateEmailSubscribeModal,
  showCreateCampaignModal: false,
  showIndustryTypeCodesModal: false,
  showCustomAddressesModal: false,
  showLearnMoreModal: false,
  showEmailUnsubscribeOption: false,
  showEditNoticeModal: null, // Holds the notice if the modal is showing
};

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_NEW_USER_MODAL:
      return {
        ...state,
        showNewUserModal: action.payload,
      };
    case SET_ABOUT_US_MODAL:
      return {
        ...state,
        showAboutUsModal: action.payload,
      };
    case SET_EMAIL_SUBSCRIBE_MODAL:
      return {
        ...state,
        showEmailSubscribeModal: action.payload.showModal,
        autopopulateEmail: action.payload.autopopulateEmail,
        autopopulateCounties: action.payload.autopopulateCounties,
        showEmailUnsubscribeOption: action.payload.showEmailUnsubscribeOption,
      };
    case SET_CREATE_CAMPAIGN_MODAL:
      return {
        ...state,
        showCreateCampaignModal: action.payload,
      };
    case SET_INDUSTRY_TYPE_CODES_MODAL:
      return {
        ...state,
        showIndustryTypeCodesModal: action.payload,
      };
    case SET_CUSTOM_ADDRESSES_MODAL:
      return {
        ...state,
        showCustomAddressesModal: action.payload,
      };
    case SET_LEARN_MORE_MODAL:
      return {
        ...state,
        showLearnMoreModal: action.payload,
      };
    case SET_EDIT_NOTICE_MODAL:
      return {
        ...state,
        showEditNoticeModal: action.payload,
      };
    default:
      return state;
  }
};

export default modalsReducer;
