import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDropdown } from '../../actions/notices';
import { activeDropdownSelector } from '../../selectors/notices';

// If the user clicks outside of the currently active dropdown or presses escape, close it.
export const CloseActiveDropdown = () => {
  const activeDropdownId = useSelector(activeDropdownSelector);
  const dispatch = useDispatch();

  // Close dropdown on outside click
  const handleClick = useCallback(
    e => {
      if (activeDropdownId) {
        const dropdownElement = document.getElementById(activeDropdownId);
        // Let the button close the dropdown if clicked
        const dropdownButton = document.getElementById(`${activeDropdownId}_btn`);
        // Don't let clicking on the react datepicker close the dropdown
        const reactDatepickerElts = document.getElementsByClassName('react-datepicker-popper');

        if (
          dropdownElement &&
          !dropdownElement.contains(e.target) &&
          dropdownButton &&
          !dropdownButton.contains(e.target) &&
          (reactDatepickerElts.length === 0 || !reactDatepickerElts[0].contains(e.target)) &&
          !e.target.classList.contains('react-datepicker__navigation-icon') &&
          !e.target.classList.contains('react-datepicker__navigation')
        ) {
          dispatch(setActiveDropdown(null));
        }
      }
    },
    [activeDropdownId, dispatch]
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    // Close dropdown on escape press
    const closeOnEscapePress = event => {
      if (event.key === 'Escape') {
        dispatch(setActiveDropdown(null));
      }
    };
    window.addEventListener('keydown', closeOnEscapePress);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePress);
    };
  }, [dispatch]);

  return null;
};
