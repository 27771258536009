import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setNewUserModal } from '../../actions/modals';
import { useAddUser } from '../../hooks/messagesAndRequests';
import { showNewUserModalSelector } from '../../selectors/modals';
import { validateAddUser } from '../../utils/validators';
import { Button } from '../general/Button';
import { MultiFieldLine, OptionsList, SelectField, TextField } from '../FormFields';
import { Modal } from '../general/Modal';
import { COUNTIES_OPTIONS, HARRIS } from '../../constants/counties';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

export const NewUserModal = () => {
  const showModal = useSelector(showNewUserModalSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setNewUserModal(false));
  }
  
  const [submit, submitting] = useAddUser(onClose);

  if (!showModal) {
    return null;
  }

  return (

    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Formik
        initialValues={{
          county: HARRIS,
        }}
        validate={validateAddUser}
        enableReinitialize={true}
        onSubmit={submit}>
        {({ handleSubmit, errors, touched, submitCount }) => {
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (<>
            <Title>Add New User</Title>
            <MultiFieldLine columns='auto auto'>
              <TextField label="First name" name="firstName" error={getError('firstName')} />
              <TextField label="Last name" name="lastName" error={getError('lastName')} />
            </MultiFieldLine>
            <MultiFieldLine columns='auto auto'>
              <SelectField label="County" name="county" error={getError('county')}>
                <OptionsList options={COUNTIES_OPTIONS} name='county' hideSelectOne={true} />
              </SelectField>
              <TextField label="Email" name="email" type="email" error={getError('email')} />
            </MultiFieldLine>
            <br/>
            <Button isLoading={submitting} type="submit" onClick={handleSubmit}>Submit</Button>
          </>)}}
      </Formik>
    </Modal>
  )
}