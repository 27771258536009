import {
  SET_INCLUDE_STATE_LEGISLATORS_IN_ADDRESSES,
  SET_STANNP_SLIDER,
  SET_PIN_DROPPED,
  SET_PIN_COORDINATES,
  SET_NOTICE_PIN_DROP,
  ADD_STANNP_PIN_DROP,
  SET_CUSTOM_ADDRESSES,
  SET_SELECTED_ADDRESS_LOCATION,
  SET_VISIBLE_PLACES,
} from '../actions/general';
import { PLACES_ICONS } from '../constants/map';

const defaultState = {
  showStannpSlider: false,
  includeStateLegislatorsInAddresses: false,
  showPinDropped: false,
  showPinCoordinates: [],
  showNoticePinDrop: {},
  customAddresses: null,
  selectedAddressLocation: null,
  visiblePlaces: Object.fromEntries(Object.keys(PLACES_ICONS).map(icon => [icon, false])),
};

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STANNP_SLIDER:
      return {
        ...state,
        showStannpSlider: action.payload,
      };
    case SET_INCLUDE_STATE_LEGISLATORS_IN_ADDRESSES:
      return {
        ...state,
        includeStateLegislatorsInAddresses: action.payload,
      };
    case SET_PIN_DROPPED:
      return {
        ...state,
        showPinDropped: action.payload,
      };
    case SET_PIN_COORDINATES:
      return {
        ...state,
        showPinCoordinates: action.payload,
      };
    case SET_NOTICE_PIN_DROP:
      return {
        ...state,
        showNoticePinDrop: {
          location: {
            coordinates: action.payload.coordinates,
          },
          stateLegislators: action.payload.stateLegislators,
        },
      };
    case ADD_STANNP_PIN_DROP:
      return {
        ...state,
        showNoticePinDrop: { ...state.showNoticePinDrop, ...action.payload },
      };
    case SET_CUSTOM_ADDRESSES:
      return {
        ...state,
        customAddresses: action.payload,
      };
    case SET_SELECTED_ADDRESS_LOCATION:
      return {
        ...state,
        selectedAddressLocation: action.payload,
      };
    case SET_VISIBLE_PLACES:
      return {
        ...state,
        visiblePlaces: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
