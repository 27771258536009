import { useDispatch, useSelector } from 'react-redux';
import { rangeSelector } from '../selectors/notices';
import { setRange } from '../actions/notices';

// Selects the current range (distance in miles) from the query params
// Returns [range, setRange]
// NB: We don't always bother passing nonAddressCounty when we know the range
// wont be used for nonAddressCounties (i.e. when the UI won't be present for
// nonAddressCounties). But it most cases it should be passed.
export const useRange = (nonAddressCounty = false) => {
  const range = useSelector(rangeSelector);
  const dispatch = useDispatch();

  const updateRange = newValue => {
    if (newValue > 0) {
      dispatch(setRange(newValue));
    }
  };

  // Counties without addresses use the NASA SEDAC api, which only provides 1/2 mile granularity.
  // For counties with addresses, we use 1/4 mile granularity in the radius slider.
  const rangeInMiles = nonAddressCounty ? range / 2 : range / 4;

  // TODO: may want to update range to be per-notice
  const updateRangeInMiles = newValueInMiles => {
    if (newValueInMiles > 0) {
      dispatch(setRange(newValueInMiles * 4));
    }
  };

  return [range, updateRange, rangeInMiles, updateRangeInMiles];
};
