import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { validateResetPassword } from '../utils/validators';
import { useResetPasswordQuery } from '../hooks/messagesAndRequests';
import { IntroText, MainContent, Tile } from './Login';
import { Button } from './general/Button.js';
import { TextField } from './FormFields.js';
import { PRIMARY_DARK, SECONDARY } from '../constants/cssVars';

const Wrapper = styled.div`
  width: 100%;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams();

  const [submit, submitting] = useResetPasswordQuery(() => {
    navigate(`/login`);
  }, resetPasswordToken) 

  return (
    <Wrapper>
      <MainContent>
        <Tile borderColor={SECONDARY}>
          <Formik
            validate={validateResetPassword}
            initialValues={{ password: '' }}
            onSubmit={submit}>
            {({ handleSubmit, errors, touched, values, submitCount }) => {
              const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
              return (
                <>
                  <IntroText color={PRIMARY_DARK}>
                    Reset Password
                  </IntroText>
                  <p style={{margin: '0px 0px 10px 0px', padding: '0px', fontSize: '17px'}}>
                    Please enter your new password below.
                  </p>
                  <TextField label="New password" error={getError('password')}  style={{ margin: '5px 0px '}} type="password" name="password" />
                  <TextField label="Confirm new password" error={getError('passwordConfirmation')}  style={{ margin: '5px 0px '}} type="password" name="passwordConfirmation" />
                  <ButtonWrapper>
                    <Button onClick={handleSubmit} isLoading={submitting}>
                      Save new password
                    </Button>
                  </ButtonWrapper>
                </>
            )}}
          </Formik>
        </Tile>
      </MainContent>
    </Wrapper>
  )
}