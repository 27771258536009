export const HARRIS = 'HARRIS';
export const NUECES = 'NUECES';
export const JEFFERSON = 'JEFFERSON';
export const SAN_PATRICIO = 'SAN PATRICIO';
export const ALL_COUNTIES = 'ALL COUNTIES';

export const COUNTIES_LIST = [
  'Foard',
  'Blanco',
  'Hockley',
  'Llano',
  'Swisher',
  'Titus',
  'Navarro',
  'Brazoria',
  'Hidalgo',
  'Archer',
  'Refugio',
  'Andrews',
  'Galveston',
  'De Witt',
  'Gonzales',
  'Wharton',
  'Fannin',
  'Madison',
  'Moore',
  'Tyler',
  'Lipscomb',
  'Burleson',
  'Jim Hogg',
  'Throckmorton',
  'Kleberg',
  'Shelby',
  'Garza',
  'Karnes',
  'Reeves',
  'Houston',
  'El Paso',
  'Carson',
  'Medina',
  'Wichita',
  'Chambers',
  'Donley',
  'Hopkins',
  'Lampasas',
  'Falls',
  'Stephens',
  'Travis',
  'Roberts',
  'Crosby',
  'Kerr',
  'Wood',
  'Jack',
  'Marion',
  'Hale',
  'Jeff Davis',
  'Tarrant',
  'Waller',
  'Reagan',
  'Smith',
  'Van Zandt',
  'Cooke',
  'Zavala',
  'Hall',
  'Kendall',
  'Goliad',
  'Yoakum',
  'Knox',
  'McLennan',
  'Brewster',
  'Panola',
  'Bandera',
  'Parmer',
  'Rockwall',
  'Trinity',
  'Bosque',
  'Coke',
  'Franklin',
  'Hill',
  'Calhoun',
  'Irion',
  'Midland',
  'Brown',
  'Wilson',
  'Oldham',
  'Freestone',
  'Sabine',
  'Deaf Smith',
  'Angelina',
  'Kent',
  'Red River',
  'Kinney',
  'Denton',
  'Sutton',
  'Guadalupe',
  'Winkler',
  'Dimmit',
  'Wise',
  'Hemphill',
  'Potter',
  'Childress',
  'Willacy',
  'Bastrop',
  'Kimble',
  'Scurry',
  'Ochiltree',
  'Hansford',
  'Hartley',
  'Cameron',
  'Matagorda',
  'Burnet',
  'Rains',
  'Hays',
  'Mason',
  'Ward',
  'Delta',
  'Nueces',
  'Callahan',
  'Bell',
  'Cherokee',
  'Ellis',
  'Lubbock',
  'Terrell',
  'Crockett',
  'Atascosa',
  'Ector',
  'Brazos',
  'Aransas',
  'Dallas',
  'Howard',
  'Motley',
  'San Augustine',
  'Maverick',
  'Erath',
  'Dickens',
  'Caldwell',
  'Dawson',
  'Camp',
  'Duval',
  'Hunt',
  'Mills',
  'Val Verde',
  'Pecos',
  'Baylor',
  'Gregg',
  'Cochran',
  'Jasper',
  'Hamilton',
  'Castro',
  'Sterling',
  'Starr',
  'Crane',
  'Morris',
  'Briscoe',
  'Tom Green',
  'Menard',
  'Parker',
  'Walker',
  'Brooks',
  'La Salle',
  'Edwards',
  'Lee',
  'Robertson',
  'Young',
  'Bee',
  'Clay',
  'San Jacinto',
  'Uvalde',
  'Haskell',
  'Somervell',
  'Bexar',
  'Grayson',
  'Bailey',
  'Jones',
  'Jefferson',
  'Rusk',
  'Glasscock',
  'Gillespie',
  'Fisher',
  'Henderson',
  'Shackelford',
  'Palo Pinto',
  'Wilbarger',
  'Floyd',
  'Orange',
  'Runnels',
  'Coleman',
  'Martin',
  'Wheeler',
  'Culberson',
  'Mitchell',
  'Hudspeth',
  'Upshur',
  'Liberty',
  'Williamson',
  'Nacogdoches',
  'Loving',
  'Jim Wells',
  'Upton',
  'Newton',
  'Lamar',
  'Polk',
  'Collin',
  'Hardeman',
  'McMullen',
  'Cottle',
  'Frio',
  'Gray',
  'Dallam',
  'Kenedy',
  'Fort Bend',
  'Armstrong',
  'Collingsworth',
  'Cass',
  'Sherman',
  'Terry',
  'Limestone',
  'Real',
  'Lynn',
  'Lavaca',
  'Nolan',
  'Gaines',
  'Montgomery',
  'Concho',
  'Hardin',
  'Harris',
  'Leon',
  'Bowie',
  'Kaufman',
  'Anderson',
  'San Saba',
  'Schleicher',
  'Colorado',
  'Milam',
  'Live Oak',
  'Borden',
  'McCulloch',
  'Johnson',
  'Montague',
  'Stonewall',
  'Comal',
  'Eastland',
  'Comanche',
  'King',
  'Washington',
  'Webb',
  'Fayette',
  'Harrison',
  'Presidio',
  'Austin',
  'Randall',
  'Hood',
  'Victoria',
  'Hutchinson',
  'Coryell',
  'Jackson',
  'Taylor',
  'Zapata',
  'San Patricio',
  'Grimes',
  'Lamb',
];

export const COUNTIES_WITH_ADDRESSES = [
  'HARRIS',
  'NUECES',
  'SAN PATRICIO',
  'JEFFERSON',
  'GALVESTON',
  'BRAZORIA',
  'FORT BEND',
  'MONTGOMERY',
  'CHAMBERS',
  'ORANGE',
  'WALLER',
  'HARDIN',
];
export const COUNTIES_WITH_AAH_ORGANIZATION = [
  'HARRIS',
  'NUECES',
  'SAN PATRICIO',
  'JEFFERSON',
  'GALVESTON',
  'BRAZORIA',
  'FORT BEND',
  'WALLER',
  'MONTGOMERY',
  'LIBERTY',
  'CHAMBERS',
  'HARDIN',
  'ORANGE',
];

export const COUNTIES_OPTIONS = COUNTIES_LIST.sort().map(county => ({
  label: `${county} County`,
  value: county.toUpperCase(),
}));

export const COUNTIES_OPTIONS_WITH_ALL_COUNTIES = [
  { label: 'All counties', value: ALL_COUNTIES },
  ...COUNTIES_OPTIONS,
];

export const COUNTY_ID_TO_FRIENDLY_NAME = COUNTIES_LIST.reduce((idToName, county) => {
  idToName[county.toUpperCase()] = `${county} County`;
  return idToName;
}, {});

export const COUNTY_TO_CENTER_COORDS = {
  [ALL_COUNTIES]: [-98.88491973912744, 31.631531242257648],
  FOARD: [-99.77871109, 33.97408519],
  BLANCO: [-98.39974086, 30.26636128],
  HOCKLEY: [-102.3430919, 33.60750375],
  LLANO: [-98.68387398, 30.70566579],
  SWISHER: [-101.734951, 34.53064989],
  TITUS: [-94.96509786, 33.2160906],
  NAVARRO: [-96.47248134, 32.04695429],
  BRAZORIA: [-95.45578479, 29.19345387],
  HIDALGO: [-98.18088681, 26.396627],
  ARCHER: [-98.68783437, 33.61539176],
  REFUGIO: [-97.16546961, 28.32681413],
  ANDREWS: [-102.6378839, 32.30506283],
  GALVESTON: [-94.96474975, 29.39424415],
  'DE WITT': [-97.35666976, 29.08201599],
  GONZALES: [-97.49279927, 29.45641495],
  WHARTON: [-96.22197375, 29.2778362],
  FANNIN: [-96.10656755, 33.59396919],
  MADISON: [-95.92818441, 30.96564645],
  MOORE: [-101.8929556, 35.83768863],
  TYLER: [-94.37651432, 30.7712607],
  LIPSCOMB: [-100.27317, 36.27778432],
  BURLESON: [-96.62109399, 30.49246139],
  'JIM HOGG': [-98.6972921, 27.04340499],
  THROCKMORTON: [-99.21236832, 33.17753907],
  KLEBERG: [-97.72889778, 27.43274395],
  SHELBY: [-94.14495261, 31.79242191],
  GARZA: [-101.2984496, 33.17991074],
  KARNES: [-97.85960621, 28.90493782],
  REEVES: [-103.693143, 31.32307061],
  HOUSTON: [-95.42225367, 31.31764137],
  'EL PASO': [-106.2352229, 31.7686226],
  CARSON: [-101.3542037, 35.40346802],
  MEDINA: [-99.11008331, 29.35520493],
  WICHITA: [-98.70153389, 33.98421585],
  CHAMBERS: [-94.60891308, 29.73912276],
  DONLEY: [-100.8140501, 34.96540246],
  HOPKINS: [-95.56414173, 33.14933822],
  LAMPASAS: [-98.24142711, 31.19627791],
  FALLS: [-96.93579511, 31.25317017],
  STEPHENS: [-98.83612016, 32.73584155],
  TRAVIS: [-97.78194654, 30.33423319],
  ROBERTS: [-100.8135689, 35.8385577],
  CROSBY: [-101.2998612, 33.61459873],
  KERR: [-99.35016969, 30.06153035],
  WOOD: [-95.38206498, 32.78634374],
  JACK: [-98.1725509, 33.23345819],
  MARION: [-94.35725239, 32.79797929],
  HALE: [-101.8269082, 34.07051782],
  'JEFF DAVIS': [-104.1403539, 30.71559322],
  TARRANT: [-97.29116473, 32.77185236],
  WALLER: [-95.98768942, 30.01093722],
  REAGAN: [-101.5230108, 31.36617698],
  SMITH: [-95.26909511, 32.37514553],
  'VAN ZANDT': [-95.8364862, 32.56368961],
  COOKE: [-97.21247642, 33.638465],
  ZAVALA: [-99.76102001, 28.86530621],
  HALL: [-100.6809876, 34.53074134],
  KENDALL: [-98.71160511, 29.9446397],
  GOLIAD: [-97.42646082, 28.65708563],
  YOAKUM: [-102.8278792, 33.17293501],
  KNOX: [-99.74144313, 33.60614755],
  MCLENNAN: [-97.20184883, 31.55234489],
  BREWSTER: [-103.2518906, 29.81205836],
  PANOLA: [-94.30552858, 32.16222886],
  BANDERA: [-99.24637282, 29.74724753],
  PARMER: [-102.784594, 34.53014507],
  ROCKWALL: [-96.40780584, 32.89786769],
  TRINITY: [-95.13551682, 31.0885069],
  BOSQUE: [-97.63437613, 31.90050476],
  COKE: [-100.5298747, 31.88849293],
  FRANKLIN: [-95.21841437, 33.17550691],
  HILL: [-97.1324142, 31.99079033],
  CALHOUN: [-96.64708119, 28.48966896],
  IRION: [-100.9824126, 31.30393203],
  MIDLAND: [-102.0315032, 31.86921148],
  BROWN: [-98.99989649, 31.77432313],
  WILSON: [-98.08621015, 29.17315896],
  OLDHAM: [-102.6029949, 35.40497586],
  FREESTONE: [-96.14918535, 31.70486443],
  SABINE: [-93.85170439, 31.34321941],
  'DEAF SMITH': [-102.6048162, 34.96602014],
  ANGELINA: [-94.611742, 31.25475875],
  KENT: [-100.7791031, 33.18284492],
  'RED RIVER': [-95.0501886, 33.62074539],
  KINNEY: [-100.4178441, 29.35000302],
  DENTON: [-97.11681154, 33.205574],
  SUTTON: [-100.5382263, 30.49747152],
  GUADALUPE: [-97.94850308, 29.58257236],
  WINKLER: [-103.0484794, 31.85008609],
  DIMMIT: [-99.760574, 28.42161168],
  WISE: [-97.65444124, 33.21585061],
  HEMPHILL: [-100.2704628, 35.83750155],
  POTTER: [-101.8940484, 35.40128927],
  CHILDRESS: [-100.2075664, 34.52929257],
  WILLACY: [-97.64303323, 26.47132899],
  BASTROP: [-97.31187705, 30.10353518],
  KIMBLE: [-99.74892716, 30.48676251],
  SCURRY: [-100.9165071, 32.74720063],
  OCHILTREE: [-100.815653, 36.27846334],
  HANSFORD: [-101.3545624, 36.27753179],
  HARTLEY: [-102.6028835, 35.83996755],
  CAMERON: [-97.51499968, 26.13847728],
  MATAGORDA: [-95.98533866, 28.86711039],
  BURNET: [-98.18252382, 30.78843382],
  RAINS: [-95.79347545, 32.87004212],
  HAYS: [-98.03104087, 30.05782657],
  MASON: [-99.2263026, 30.71742293],
  WARD: [-103.1024636, 31.509424],
  DELTA: [-95.67221685, 33.38623601],
  NUECES: [-97.61767326, 27.72621549],
  CALLAHAN: [-99.37359692, 32.2977738],
  BELL: [-97.47790776, 31.03778267],
  CHEROKEE: [-95.16515234, 31.83695632],
  ELLIS: [-96.79467417, 32.34839264],
  LUBBOCK: [-101.8205745, 33.6101944],
  TERRELL: [-102.0764533, 30.22502597],
  CROCKETT: [-101.4118364, 30.72291931],
  ATASCOSA: [-98.52741302, 28.89418432],
  ECTOR: [-102.5430907, 31.86908156],
  BRAZOS: [-96.3022943, 30.6610425],
  ARANSAS: [-96.99258951, 28.12709562],
  DALLAS: [-96.77781862, 32.76653746],
  HOWARD: [-101.4355097, 32.30610232],
  MOTLEY: [-100.7798961, 34.07409017],
  'SAN AUGUSTINE': [-94.16809762, 31.39427005],
  MAVERICK: [-100.3144638, 28.74260943],
  ERATH: [-98.21722756, 32.23640361],
  DICKENS: [-100.7788138, 33.61663428],
  CALDWELL: [-97.61983479, 29.83677935],
  DAWSON: [-101.9476136, 32.74258137],
  CAMP: [-94.97860185, 32.97321771],
  DUVAL: [-98.50889672, 27.68133382],
  HUNT: [-96.08550014, 33.123531],
  MILLS: [-98.59552736, 31.49524259],
  'VAL VERDE': [-101.15171, 29.89300587],
  PECOS: [-102.7235743, 30.78100044],
  BAYLOR: [-99.21351964, 33.61648147],
  GREGG: [-94.81723694, 32.48036452],
  COCHRAN: [-102.8285264, 33.60410808],
  JASPER: [-94.02495849, 30.74391255],
  HAMILTON: [-98.11066654, 31.70479181],
  CASTRO: [-102.2617608, 34.53000304],
  STERLING: [-101.0500699, 31.82786516],
  STARR: [-98.73834895, 26.56212627],
  CRANE: [-102.5156425, 31.42846287],
  MORRIS: [-94.73205137, 33.11368507],
  BRISCOE: [-101.2085769, 34.53041048],
  'TOM GREEN': [-100.4620678, 31.4044442],
  MENARD: [-99.82061472, 30.88981785],
  PARKER: [-97.80472194, 32.77852261],
  WALKER: [-95.57224172, 30.73899492],
  BROOKS: [-98.21874127, 27.0315669],
  'LA SALLE': [-99.1034773, 28.34621032],
  EDWARDS: [-100.3048937, 29.98248216],
  LEE: [-96.96568735, 30.31065065],
  ROBERTSON: [-96.51272568, 31.02705415],
  YOUNG: [-98.68781396, 33.17670764],
  BEE: [-97.74116811, 28.41736675],
  CLAY: [-98.208958, 33.78379717],
  'SAN JACINTO': [-95.16683273, 30.57948905],
  UVALDE: [-99.76203107, 29.35669829],
  HASKELL: [-99.73029401, 33.17824805],
  SOMERVELL: [-97.77456553, 32.22229511],
  BEXAR: [-98.51966309, 29.44884523],
  GRAYSON: [-96.67764864, 33.62684371],
  BAILEY: [-102.8299069, 34.0684915],
  JONES: [-99.87850599, 32.73970429],
  JEFFERSON: [-94.1708778, 29.88425832],
  RUSK: [-94.76169577, 32.10803224],
  GLASSCOCK: [-101.520742, 31.8694238],
  GILLESPIE: [-98.94627245, 30.31800246],
  FISHER: [-100.4019503, 32.74305155],
  HENDERSON: [-95.85356847, 32.21188078],
  SHACKELFORD: [-99.35403034, 32.7360276],
  'PALO PINTO': [-98.31299522, 32.75316856],
  WILBARGER: [-99.24089568, 34.08044953],
  FLOYD: [-101.3033037, 34.07239775],
  ORANGE: [-93.89653794, 30.12807126],
  RUNNELS: [-99.97624799, 31.83105393],
  COLEMAN: [-99.45362579, 31.77329421],
  MARTIN: [-101.9512464, 32.30605847],
  WHEELER: [-100.2697634, 35.40129002],
  CULBERSON: [-104.5177421, 31.44709817],
  MITCHELL: [-100.9211128, 32.30636024],
  HUDSPETH: [-105.3869428, 31.45613738],
  UPSHUR: [-94.94132011, 32.7362827],
  LIBERTY: [-94.8120925, 30.15153187],
  WILLIAMSON: [-97.60105467, 30.64834941],
  NACOGDOCHES: [-94.6158776, 31.61604108],
  LOVING: [-103.5799063, 31.84912973],
  'JIM WELLS': [-98.0898649, 27.73131099],
  UPTON: [-102.0430005, 31.36853957],
  NEWTON: [-93.74462993, 30.78619977],
  LAMAR: [-95.57110784, 33.66746186],
  POLK: [-94.82994949, 30.79250963],
  COLLIN: [-96.57248871, 33.18789055],
  HARDEMAN: [-99.7453851, 34.28865227],
  MCMULLEN: [-98.56842256, 28.35351091],
  COTTLE: [-100.2786983, 34.0776571],
  FRIO: [-99.10826004, 28.86726774],
  GRAY: [-100.812475, 35.40130666],
  DALLAM: [-102.6021647, 36.27777107],
  KENEDY: [-97.68137783, 26.92409448],
  'FORT BEND': [-95.77090562, 29.52763253],
  ARMSTRONG: [-101.3572412, 34.96522076],
  COLLINGSWORTH: [-100.2700733, 34.96498493],
  CASS: [-94.34348771, 33.07750446],
  SHERMAN: [-101.8933822, 36.27765824],
  TERRY: [-102.335157, 33.17373729],
  LIMESTONE: [-96.58056718, 31.54541898],
  REAL: [-99.82215881, 29.83182711],
  LYNN: [-101.8160235, 33.17685487],
  LAVACA: [-96.93020993, 29.38437003],
  NOLAN: [-100.4060989, 32.3034718],
  GAINES: [-102.6351246, 32.74074537],
  MONTGOMERY: [-95.50301411, 30.30022409],
  CONCHO: [-99.86413426, 31.32645211],
  HARDIN: [-94.39021394, 30.33238431],
  HARRIS: [-95.39782106, 29.85967144],
  LEON: [-95.99554292, 31.29655719],
  BOWIE: [-94.42324298, 33.44572786],
  KAUFMAN: [-96.28779341, 32.59927808],
  ANDERSON: [-95.65251774, 31.81321543],
  'SAN SABA': [-98.81754295, 31.15520543],
  SCHLEICHER: [-100.538097, 30.89661056],
  COLORADO: [-96.52630821, 29.6208542],
  MILAM: [-96.97683495, 30.78625299],
  'LIVE OAK': [-98.12474493, 28.3514375],
  BORDEN: [-101.4317533, 32.7436916],
  MCCULLOCH: [-99.34754972, 31.19896752],
  JOHNSON: [-97.36660504, 32.37899918],
  MONTAGUE: [-97.72466993, 33.67496328],
  STONEWALL: [-100.255259, 33.17842158],
  COMAL: [-98.27814516, 29.80816362],
  EASTLAND: [-98.8325713, 32.32739258],
  COMANCHE: [-98.55841538, 31.9490639],
  KING: [-100.2558584, 33.61641863],
  WASHINGTON: [-96.40356575, 30.2145299],
  WEBB: [-99.33170164, 27.76102524],
  FAYETTE: [-96.91968047, 29.87683207],
  HARRISON: [-94.37155736, 32.54814898],
  PRESIDIO: [-104.2405552, 29.99977786],
  AUSTIN: [-96.27791829, 29.88711223],
  RANDALL: [-101.8968527, 34.96594288],
  HOOD: [-97.83280338, 32.43107878],
  VICTORIA: [-96.97176602, 28.79640594],
  HUTCHINSON: [-101.3547257, 35.84000866],
  CORYELL: [-97.7991883, 31.39087578],
  JACKSON: [-96.57883268, 28.95603133],
  TAYLOR: [-99.89003941, 32.30138229],
  ZAPATA: [-99.16860134, 27.00089072],
  'SAN PATRICIO': [-97.51859857, 28.00922186],
  GRIMES: [-95.98557334, 30.54360689],
  LAMB: [-102.3517095, 34.06861242],
};
