import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button } from '../general/Button';
import { GENERAL_MISC, industryTypeOptions, NPH, NPM, SOAH } from '../../constants/notices';
import { getTemplateJSONByType } from '../../utils/stannpTemplates';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';

const TemplateCodeCopyWrapper = styled.div`
  label {
    font-size: 0.9em;
  }
  select {
    font-size: 0.9em;
    max-width: 300px;
    margin-bottom: 10px;
    display: block;
  }

  button {
    margin-top: 20px;
  }
`
 
// Returns facility type / public meeting selectors and a button that will copy the corresponding Stannp template code to user's clipboard
export const TemplateCodeCopyButton = () => {
  const notice = useCurrentNotice();

  // Default public meeting input to true for public meeting/hearing notices
  const isPublicMeetingType = notice.noticeType === NPM || notice.noticeType === NPH || notice.noticeType === SOAH
  // Use the first non-"GENERAL_MISC" industry type to use as default value for facility type; otherwise, recommend general/other template
  const nonGeneralIndustryTypes = notice.industryTypeCodes.filter(itc => itc.category !== GENERAL_MISC)
  const defaultFacilityType = nonGeneralIndustryTypes.length > 0 ? nonGeneralIndustryTypes[0].category : GENERAL_MISC;

  const [publicMeetingInput, setPublicMeetingInput] = useState(isPublicMeetingType ? 'yes' : 'no');
  const [facilityTypeInput, setFacilityTypeInput] = useState(defaultFacilityType);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    setCopied(true);

    const templateJson = getTemplateJSONByType(facilityTypeInput, publicMeetingInput === 'yes');
    // Since we want the user to be able to create a new design from this template, the AAH template ID must be removed
    const idRemovedTemplateJson = { ...templateJson, id: 0 };
    navigator.clipboard.writeText(JSON.stringify(idRemovedTemplateJson));
    toast.success("Copied to clipboard!")
  }

  useEffect(() => {
      if (copied) {
        const timer = setTimeout(() => setCopied(false), 1000);
        return () => {
          clearTimeout(timer);
        };
      }
    },
    [copied]
  );

  return <TemplateCodeCopyWrapper>

    <label for="facilityType">Facility type</label>
    <select name="facilityType" id="facilityType" onChange={(e) => setFacilityTypeInput(e.target.value)} value={facilityTypeInput}>
      {industryTypeOptions.map((industryTypeOption) => <option value={industryTypeOption.value}>{industryTypeOption.label}</option>)}
    </select>

    <label for="publicMeeting">Is this notifying a public meeting or hearing?</label>
    <select name="publicMeeting" id="publicMeeting" onChange={(e) => setPublicMeetingInput(e.target.value)} value={publicMeetingInput}>
      <option value="no">No</option>
      <option value="yes">Yes</option>
    </select>

    <Button onClick={copyToClipboard}>
      Copy Template Text To Clipboard
    </Button>
  </TemplateCodeCopyWrapper>
}