import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { setNoticesFromSearchParams } from '../actions/notices';
import { filtersQuerySelector } from '../selectors/notices';

export const useNoticeParamString = () => {
  const [searchParams] = useSearchParams();
  const noticeParams = searchParams.get('notices');

  const newNoticeParams = new URLSearchParams();
  newNoticeParams.set('notices', noticeParams);

  return noticeParams ? newNoticeParams.toString() : '';
};

/**
 * Keeps the filter query params in sync with the store
 */
export const useSyncNoticeFilterParams = () => {
  const dispatch = useDispatch();

  const filterQueryParams = useSelector(filtersQuerySelector);
  let [searchParams, setSearchParams] = useSearchParams();

  // Used to know if we need to either update the query params
  // or hydrate the store
  const lastParams = useRef(filterQueryParams);

  // Update search params if the filterQueryParams have changed
  useEffect(() => {
    if (lastParams.current === filterQueryParams) {
      return;
    }

    // Keep track of the params we're updating. If params have updated,
    // we don't need to re-trigger a hydration event, so we'll use this to
    // discern between param udpates from the store vs param updates from
    // browser load/navigation
    lastParams.current = filterQueryParams;

    setSearchParams((searchParams) => {
      searchParams.set('notices', filterQueryParams);
      return searchParams;
    });
  }, [filterQueryParams, setSearchParams]);

  // Hydrate redux state on load or change of search params i.e. browser navigation
  useEffect(() => {
    const notices = searchParams.get('notices') || '{}';
    if (notices !== lastParams.current) {
      let parsedNotices = null;
      try {
        parsedNotices = JSON.parse(notices);
      } catch (error) {
        console.error(error);
      }
      if (parsedNotices) {
        lastParams.current = notices;
        dispatch(setNoticesFromSearchParams(parsedNotices));
      }
    }
  }, [dispatch, searchParams]);
};
