import React from 'react';
import styled from 'styled-components';
import { SHADOW_LIGHT } from '../../constants/cssVars';
import warningIcon from '../../assets/icons/error.svg';
import { LinkLikeButton } from '../general/Button';
import { useDispatch } from 'react-redux';
import { setLearnMoreModal } from '../../actions/modals';

const WarningFlagIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  margin: 15px 0px;
  background-color: #ffcc3e;
  color: #785c01;
  font-size: 0.82em;
  box-shadow: ${SHADOW_LIGHT};
`;

const WarningText = styled.p`
  margin: 0px;
`;

export const WarningFlag = ({ iconStyle = {}, showLearnMore, style = {}, text }) => {
  const dispatch = useDispatch();

  return (
    <WarningFlagIcon style={style}>
      <img
        src={warningIcon}
        style={{ height: '25px', width: '25px', marginTop: '5px', ...iconStyle }}
        alt="Warning"
      />
      <WarningText>
        {text || 'The number of people in this area is an approximation.'}{' '}
        {showLearnMore && (
          <LinkLikeButton
            onClick={() => dispatch(setLearnMoreModal(true))}
            style={{ color: '#785c01' }}
          >
            Learn more
          </LinkLikeButton>
        )}
      </WarningText>
    </WarningFlagIcon>
  );
};
