import React, { useEffect } from 'react';
import styled from 'styled-components';
import closeIcon from '../../assets/icons/close.svg';
import FocusTrap from 'focus-trap-react';
import { BG_PRIMARY, MAX_DESKTOP_WIDTH, PRIMARY, PRIMARY_DARK } from '../../constants/cssVars';

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(100, 100, 100, 0.45);
`;

const DEFAULT_MAX_WIDTH = 550;

const ModalWrapper = styled.div`
  position: relative;
  margin: 10px;
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  max-height: 95vh;
  overflow-y: scroll;
  box-sizing: border-box;
  width: ${({ maxWidth }) => maxWidth || DEFAULT_MAX_WIDTH}px;
  width: min(${({ maxWidth }) => maxWidth || DEFAULT_MAX_WIDTH}px, 95%);
  @media only screen and (max-width: ${({ maxWidth }) => (maxWidth || DEFAULT_MAX_WIDTH) + 50}px) {
    width: 95%;
  }
  @media only screen and (max-width: 760px) {
    padding: 30px 12px 12px 12px;
  }
`;

const CloseButton = styled.img`
  margin-left: 10px;
  height: 18px;
  width: 18px;
`;

const CloseWrapper = styled.button`
  color: ${PRIMARY};
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.95em;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    top: 12px;
    right: 12px;
  }
  &:focus {
    box-shadow: none !important;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: ${({ isRTL }) => (isRTL ? 'flex-start' : 'flex-end')};
  margin-top: 20px;
  > a {
    margin-left: 10px;
  }
`;

export const ModalHeader = styled.h2`
  font-weight: 600;
  color: ${PRIMARY_DARK};
  margin: 15px 0px 10px 0px;
  border-bottom: 2px solid ${BG_PRIMARY};
  padding-bottom: 5px;
  > span {
    color: ${PRIMARY};
  }
`;

export const Modal = ({ children, onClose, style, maxWidth, wrapperStyle, closeOnOuterClick }) => {
  const closeOnEscapePress = event => {
    if (event.key === 'Escape') {
      onClose();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', closeOnEscapePress);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePress);
    };
  }, [closeOnEscapePress]);

  return (
    <OuterWrapper style={wrapperStyle} onClick={() => (closeOnOuterClick ? onClose() : null)}>
      <FocusTrap>
        <ModalWrapper style={style} maxWidth={maxWidth} onClick={e => e.stopPropagation()}>
          <CloseWrapper aria-label="close" type="button" onClick={onClose}>
            close
            <CloseButton src={closeIcon} alt="" />
          </CloseWrapper>
          {children}
        </ModalWrapper>
      </FocusTrap>
    </OuterWrapper>
  );
};
