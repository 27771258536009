import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { BG_LIGHTER, GREY_DARK, GREY_LIGHT, GREY_LIGHTER, GREY_MEDIUM, GREY_MEDIUM_LIGHT, PRIMARY, PRIMARY_DARK, PRIMARY_LIGHT, SECONDARY, SHADOW_MEDIUM } from '../../constants/cssVars';

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-size: 1em;
  color: ${({ color }) => color || SECONDARY};
`;

export const StyledInternalLink = styled(HashLink)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ color }) => color || SECONDARY};
`;

export const TextLink = ({ to, color = PRIMARY, children }) => {
  return (
    <StyledLink color={color} target="_blank" rel="noreferrer noopener" href={to}>
      {children}
    </StyledLink>
  )
}

export const StyledInput = styled.input`
  border: 1px solid ${GREY_MEDIUM};
  border-radius: 0px;
  padding: 5px 15px !important;
  &:focus {
    outline: none !important;
    border: 1px solid #1591BF;
    border-radius: 0px;
    box-shadow: 0 0 2px 2px rgb(93, 196, 255);
  }
`

export const TableWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  box-shadow: ${SHADOW_MEDIUM};
`

export const Table = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
`

export const TableHeader = styled.th`
  text-align: left;
  color: ${GREY_DARK};
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  padding: 15px 20px;
`

export const TableRow = styled.tr`
  ${({ clickable }) => clickable ? `
    cursor: pointer;
    &:hover {
      > td {
        background-color: ${BG_LIGHTER};
      }
    }
    border-bottom: 1px solid ${GREY_LIGHT};
  ` : ''}
`

export const TableData = styled.td`
  background-color: #fff;
  max-height: 100px;
  max-width: 250px;
  overflow: scroll;
  font-size: 1em;
  border: none;
  padding: 15px 20px;
  color: ${PRIMARY_DARK};
  font-weight: 600;
`

export const TableHeaderInner = styled.div`
  display: flex;
  align-items: center;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div:first-child {
    margin-right: auto;
  }
  > div:last-child {
    margin-left: 10px;
  }
  color: ${GREY_DARK};
  border-radius: 8px 8px 0px 0px;
`;

export const TableFooter = styled.div`
  background-color: ${GREY_LIGHTER};
  color: ${GREY_DARK};
  border-radius: 0px 8px 8px 8px;
  > div {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
