import L from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Pane,
  Tooltip,
  Circle,
  CircleMarker,
  useMap,
  Marker,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';
import { useRequest } from 'redux-query-react';
import styled from 'styled-components';

import {
  setPinCoordinates,
  setPinDropped,
  setSelectedAddressLocation,
} from '../../../actions/general';
import { setNoticesListView } from '../../../actions/notices';
import { getPlacesOfInterestQuery } from '../../../actions/queries';
import alertIcon from '../../../assets/icons/alert-icon.svg';
import {
  ALL_COUNTIES,
  COUNTIES_WITH_ADDRESSES,
  COUNTY_TO_CENTER_COORDS,
} from '../../../constants/counties';
import {
  HEADER_HEIGHT,
  MAX_DESKTOP_WIDTH,
  GREY_MEDIUM_DARK,
  PRIMARY_DARK,
  SIDEBAR_WIDTH,
} from '../../../constants/cssVars';
import { NOTICE_TYPE_TO_FULL_NAME } from '../../../constants/notices';
import { useCurrentNotice } from '../../../hooks/useCurrentNotice';
import { getMomentUTC, useFilteredNotices } from '../../../hooks/useFilteredNotices';
import { useRange } from '../../../hooks/useRange';
import { useNoticeParamString } from '../../../hooks/useSyncNoticeFilterParams';
import { currentUserSelector, placesOfInterestSelector } from '../../../selectors/entities';
import {
  pinDroppedSelector,
  pinCoordinatesSelector,
  selectedAddressLocationSelector,
  visiblePlacesSelector,
} from '../../../selectors/general.js';
import { countySelector, listViewSelector } from '../../../selectors/notices';
import { getMetersFromMiles, onMobile } from '../../../utils/helpers';
import { JALogo } from '../JALogo';
import { CustomControls } from './CustomControls';
import { getMapIcon, getPlaceOfInterestIcon } from './Icons';

import '../../../../node_modules/leaflet-geosearch/dist/geosearch.css';

require('dotenv').config();

const MAPBOX_URL = process.env.REACT_APP_MAPBOX_URL;

const noop = () => {};

const MIN_ZOOM_LEVEL_FOR_CONCERN_ICONS = 13;

// https://www.freecodecamp.org/news/how-to-set-up-a-custom-mapbox-basemap-with-gatsby-and-react-leaflet/

// Need to flip coordinates for leaflet
const prepCoords = coords => [coords[1], coords[0]];

const MapWrapper = styled.div`
  width: 100%;
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  position: relative;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: ${({ active, showMapUnderneath }) => (active || showMapUnderneath ? 'block' : 'none')};
    width: ${({ renderForExport }) =>
      renderForExport ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%'};
  }
`;

const mapContainerStying = (renderForExport, short) => ({
  height: renderForExport
    ? '900px'
    : short
    ? `calc(100vh - ${HEADER_HEIGHT}px - 350px)`
    : `calc(100vh - ${HEADER_HEIGHT}px)`,
  minHeight: '20vh',
  position: 'relative',
  top: '0px',
});

const PopupContent = styled.div`
  min-width: 250px;
  font-size: 1.2em;
  h3,
  h4,
  p {
    margin: 0px;
    margin-bottom: ${({ concernPopup }) => (concernPopup ? '0px' : '5px')};
    white-space: normal;
  }
`;

const ListButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 500;
  font-size: 1em;
  background-color: white;
  border: 1px solid ${PRIMARY_DARK};
  padding-top: 3px;
  color: ${PRIMARY_DARK};
  display: none;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: ${({ showMapUnderneath }) => (showMapUnderneath ? 'none' : 'block')};
  }
`;

const GENERAL_COUNTY_ZOOM = 10;
const ALL_COUNTIES_ZOOM = 6.2;
const NOTICE_VIEW_ZOOM = 13;

// Match the controls ui for leaflet
const PinButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 30px;
  z-index: 400;

  width: 140px;
  height: 40px;
  background-color: white;
  font-size: 15px;
  font-weight: bold;
  color: #0481b0;
  border: 2px solid #0481b0;
  border-radius: 10px;

  :hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const Organizing = styled.div`
  border: 1px solid ${PRIMARY_DARK};
  border-radius: 4px;
  padding: 6px 6px 2px 8px;
  display: flex;
  margin-bottom: 6px;
  img {
    display: inline;
    width: 16px;
    height: 16px;
    transform: translateY(6px);
  }
  p {
    padding-left: 8px;
    line-height: 1em;
  }
`;

const INIT_ZOOM = 10;

const getDaysTillPublicActionDeadline = notice => {
  if (!notice.publicActionDeadline) {
    return null;
  }
  const currentDate = getMomentUTC(new Date());
  const endDate = moment(notice.publicActionDeadline).utc();
  const differenceBetweenDays = endDate.diff(currentDate, 'days');
  return differenceBetweenDays;
};

export const DaysTillPublicActionDeadline = ({ notice }) => {
  const daysTillDeadline = getDaysTillPublicActionDeadline(notice);
  const deadlineText =
    daysTillDeadline === null
      ? 'Unknown public action deadline'
      : daysTillDeadline < 0
      ? 'Public action deadline has passed'
      : daysTillDeadline === 0
      ? 'Public action deadline is today!'
      : `${daysTillDeadline} days until public action deadline`;
  return (
    <p
      style={{
        color: daysTillDeadline < 0 || daysTillDeadline === null ? GREY_MEDIUM_DARK : PRIMARY_DARK,
        fontStyle: 'italic',
      }}
    >
      {deadlineText}
    </p>
  );
};

/**
 * @returns {boolean | null} whether the action deadline is before the current date;
 * if they publicActionDeadline is unknown will return null
 */
export const getActionDeadlineHasPassed = notice => {
  return notice.publicActionDeadline && getDaysTillPublicActionDeadline(notice) < 0;
};

const MapMarker = ({ notice }) => {
  const navigate = useNavigate();
  const { aahOrApp } = useParams();
  const noticeParams = useNoticeParamString();
  const noticeQs = noticeParams ? `&${noticeParams}` : '';
  const actionDeadlineHasPassed = getActionDeadlineHasPassed(notice);
  const important = notice.important;

  if (!notice.location) {
    return null;
  }
  return (
    <Marker
      icon={getMapIcon(actionDeadlineHasPassed, important)}
      position={prepCoords(notice.location.coordinates)}
      // <CircleMarker
      //   radius={7}
      //   color={actionDeadlineHasPassed ? '#777777' : '#07335c'}
      //   fillColor={actionDeadlineHasPassed ? '#C7C7C7' : PRIMARY}
      //   weight={2}
      //   fillOpacity={1}
      //   opacity={1}
      //   center={prepCoords(notice.location.coordinates)}
      eventHandlers={{
        click: () => {
          navigate(`/${aahOrApp}/notices/?notice_id=${notice._id}${noticeQs}`);
        },
      }}
    >
      <Tooltip pane="tooltips">
        <PopupContent>
          <h3>{notice.facilityName}</h3>
          {notice.important && (
            <Organizing>
              <img src={alertIcon} alt="important icon" />
              <p>There is currently organizing around this permit.</p>
            </Organizing>
          )}
          <DaysTillPublicActionDeadline notice={notice} />
          <p>{NOTICE_TYPE_TO_FULL_NAME[notice.noticeType]}</p>
          <p>{notice.principal}</p>
          <p>{notice.address}</p>
        </PopupContent>
      </Tooltip>
    </Marker>
  );
};

const AdjustableCircle = ({ renderer }) => {
  const currentNotice = useCurrentNotice();

  const usingAddressApproximations = useMemo(
    () => (currentNotice ? !COUNTIES_WITH_ADDRESSES.includes(currentNotice.county) : undefined),
    [currentNotice]
  );
  const [, , rangeInMiles] = useRange(usingAddressApproximations);

  if (!currentNotice || !currentNotice.location) {
    return null;
  }

  return (
    <Circle
      center={prepCoords(currentNotice.location.coordinates)}
      fillColor="#00A7D9"
      weight={0}
      radius={getMetersFromMiles(rangeInMiles)}
      renderer={renderer}
    />
  );
};

const ZoomOnLoad = ({ animate, renderForExport }) => {
  const map = useMap();
  const currentNotice = useCurrentNotice();
  const currentCounty = useSelector(countySelector);
  const selectedAddressLocation = useSelector(selectedAddressLocationSelector);

  useEffect(() => {
    if (currentNotice != null && currentNotice.location) {
      if (selectedAddressLocation && renderForExport) {
        map.flyToBounds(
          [
            [prepCoords(currentNotice.location.coordinates)],
            [selectedAddressLocation.lat, selectedAddressLocation.lon],
          ],
          {
            animate,
            maxZoom: NOTICE_VIEW_ZOOM,
          }
        );
      } else {
        // update range to be the one given by that permit app
        map.flyTo(prepCoords(currentNotice.location.coordinates), NOTICE_VIEW_ZOOM, {
          animate,
        });
      }
    } else {
      map.flyTo(
        prepCoords(COUNTY_TO_CENTER_COORDS[currentCounty]),
        currentCounty === ALL_COUNTIES ? ALL_COUNTIES_ZOOM : GENERAL_COUNTY_ZOOM,
        { animate: true }
      );
    }
  }, [map, currentNotice, currentCounty]);

  return null;
};

export const Map = ({ animate = true, onReady = () => {}, renderForExport = false }) => {
  const showMap = !useSelector(listViewSelector);
  const dispatch = useDispatch();
  const pinDropped = useSelector(pinDroppedSelector);

  const currentNotice = useCurrentNotice();
  // Show the map underneath the details if on mobile and on notice details page
  const showMapUnderneath = currentNotice != null && onMobile();

  const currentCounty = useSelector(countySelector);
  const { aahOrApp } = useParams();

  // Disable two-finger scrolling for AAH's embed view
  const allowScrollWheelZoom = aahOrApp !== 'aah';

  // Check if on an Android device (if so, will disable "trackResize" since it's buggy on Android)
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isAndroid = /android/i.test(userAgent);

  // Listen to width of window so we can update the address control
  const [width, setWidth] = useState(window.innerWidth);
  const updateMedia = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const isDesktop = width > 700;

  // Depending on the zoom level we will show/hide places
  const [showPlacesIcons, setShowPlacesIcons] = useState(false);

  let mapContainerProps = {
    center: prepCoords(COUNTY_TO_CENTER_COORDS[currentCounty]),
    style: mapContainerStying(renderForExport, false),
    dragging: !onMobile(),
    scrollWheelZoom: allowScrollWheelZoom,
    zoom: GENERAL_COUNTY_ZOOM,
    zoomControl: false,
    preferCanvas: false,
    trackResize: !isAndroid,
  };

  // If we want to show the map underneath the content for a small screen, overwrite some props
  if (showMapUnderneath && !renderForExport) {
    mapContainerProps = {
      ...mapContainerProps,
      style: mapContainerStying(false, true),
      dragging: false,
    };
  }

  const onTileLoad = onReady || noop;

  return (
    <MapWrapper
      active={showMap}
      showMapUnderneath={showMapUnderneath}
      renderForExport={renderForExport}
    >
      {/* If a pin has been dropped, the ListButton wording will be different  */}
      <ListButton
        onClick={() => dispatch(setNoticesListView(true))}
        showMapUnderneath={showMapUnderneath}
      >
        {pinDropped ? 'Pin Details' : 'Notice List'}
      </ListButton>

      <MapContainer {...mapContainerProps}>
        <LeafletMapContent
          animate={animate}
          isDesktop={isDesktop}
          onTileLoad={onTileLoad}
          renderForExport={renderForExport}
          setShowPlacesIcons={setShowPlacesIcons}
          showPlacesIcons={showPlacesIcons}
        />
      </MapContainer>

      {currentCounty !== ALL_COUNTIES && (
        <CustomControls isDesktop={isDesktop} showPlacesIcons={showPlacesIcons} />
      )}
    </MapWrapper>
  );
};

const AddressSearch = ({ isDesktop }) => {
  const map = useMap();
  const dispatch = useDispatch();

  useEffect(() => {
    const search = new GeoSearchControl({
      provider: new OpenStreetMapProvider({ params: { countrycodes: 'us' } }),
      position: 'topleft',
      notFoundMessage: 'Sorry, that address could not be found.',
      style: isDesktop ? 'bar' : 'button',
      retainZoomLevel: true,
    });

    // GeoSearchControl doesn't expose a callback for handling when markers
    // are removed from the map, so we have to do it ourselves
    search.resetButton.addEventListener('click', () => {
      dispatch(setSelectedAddressLocation(null));
    });

    map.on('geosearch/showlocation', data => {
      const lat = data?.location?.raw?.lat;
      const lon = data?.location?.raw?.lon;
      if (!lat || !lon) {
        return;
      }
      dispatch(setSelectedAddressLocation({ lat: +lat, lon: +lon }));
    });

    // add controls
    map.addControl(search);
    const zoom = L.control.zoom({ position: 'topleft' }).addTo(map);

    // remove controls
    return () => {
      map.removeControl(search);
      map.removeControl(zoom);

      // queries existing markers/shadows from a search
      const marker = document.querySelector('.leaflet-marker-pane .leaflet-marker-icon');
      const shadow = document.querySelector('.leaflet-shadow-pane .leaflet-marker-shadow');

      if (marker) {
        marker.remove();
      }

      if (shadow) {
        shadow.remove();
      }
    };
  }, [isDesktop, map]);
};

const LeafletMapContent = ({
  animate,
  isDesktop,
  renderForExport,
  onTileLoad,
  setShowPlacesIcons,
  showPlacesIcons,
}) => {
  const dispatch = useDispatch();
  const map = useMap();
  const currentNotice = useCurrentNotice();
  const [, , rangeInMiles] = useRange();
  const currentCounty = useSelector(countySelector);
  const pinDropped = useSelector(pinDroppedSelector);
  const pinCoordinates = useSelector(pinCoordinatesSelector);
  const currentUser = useSelector(currentUserSelector);
  const selectedAddressLocation = useSelector(selectedAddressLocationSelector);

  const isAdmin = currentUser && currentUser.admin;

  // Updated as user interacts with the map, the bounds are used so that
  // we know which places markers to set
  const [mapBounds, setMapBounds] = useState(null);

  useEffect(() => {
    const setBBoxDebounced = debounce(bounds => {
      setMapBounds([
        [bounds.getWest(), bounds.getSouth()],
        [bounds.getEast(), bounds.getNorth()],
      ]);
    }, 500);
    map.on('moveend', _ => {
      const zoom = map.getZoom();
      if (zoom >= MIN_ZOOM_LEVEL_FOR_CONCERN_ICONS) {
        setShowPlacesIcons(true);
        setBBoxDebounced(map.getBounds());
      } else {
        setShowPlacesIcons(false);
      }
    });
  }, [map, setShowPlacesIcons]);

  // Allow user to drop a pin only if they are an admin user
  const dropPin = () => {
    // Toast informing user that they can double click anywhere on the map to drop a pin
    toast.info('You can double click anywhere on the map to drop a pin!', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      transition: Slide,
    });

    // Clicking on the map will create a pin at the location and zoom in
    map.on('dblclick', e => {
      const { lat, lng } = e.latlng;
      dispatch(setPinCoordinates([lat, lng]));
      dispatch(setPinDropped(true));

      // zoom in to show the new pin dropped
      map.flyTo([lat, lng], 13, { animate: true });

      // disables double-click so new pins can't be created until current pin is deleted
      map.off('dblclick');
    });
  };

  const stopDropPin = () => {
    // zoom out to show the county's center coordinates
    map.flyTo(prepCoords(COUNTY_TO_CENTER_COORDS[currentCounty]), INIT_ZOOM, {
      animate: true,
    });

    // remove pin
    dispatch(setPinDropped(false));
  };

  const canvasRenderer = useMemo(() => L.canvas(), []);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        eventHandlers={{ load: onTileLoad }}
        url={MAPBOX_URL}
      />
      <AdjustableCircle renderer={canvasRenderer} />
      {!renderForExport && <AddressSearch isDesktop={isDesktop} />}
      <JALogo />

      {!pinDropped && (
        <MapMarkers
          mapBounds={mapBounds}
          renderForExport={renderForExport}
          showPlacesIcons={showPlacesIcons}
        />
      )}

      {isAdmin && !pinDropped && !currentNotice && (
        <PinButton onClick={dropPin}>Create Pin</PinButton>
      )}

      {pinDropped && (
        <>
          <Circle
            center={pinCoordinates}
            fillColor="#00A7D9"
            weight={0}
            radius={getMetersFromMiles(rangeInMiles)}
            renderer={canvasRenderer}
          >
            <CircleMarker
              radius={7}
              color="black"
              fillColor="black"
              weight={2}
              fillOpacity={1}
              opacity={1}
              center={pinCoordinates}
              renderer={canvasRenderer}
            />
          </Circle>

          <PinButton onClick={stopDropPin}>Delete Pin</PinButton>
        </>
      )}

      {renderForExport && selectedAddressLocation && (
        <Marker position={[selectedAddressLocation.lat, selectedAddressLocation.lon]} />
      )}

      <ZoomOnLoad animate={animate} renderForExport={renderForExport} />
    </>
  );
};

const MapMarkers = ({ mapBounds, renderForExport, showPlacesIcons }) => {
  const filteredNotices = useFilteredNotices();
  const currentNotice = useCurrentNotice();
  const placesOfInterest = useSelector(placesOfInterestSelector);
  const visiblePlaces = useSelector(visiblePlacesSelector);

  const hasAVisiblePlace = useMemo(
    () => Object.values(visiblePlaces).includes(true),
    [visiblePlaces]
  );

  // We only request places icons if at least one of the place types is checked in the UI, if
  // we're zoomed in enough to want to show places, and we have mapBounds (i.e. the map has loaded)
  useRequest(
    showPlacesIcons && hasAVisiblePlace && mapBounds
      ? getPlacesOfInterestQuery({ bounds: mapBounds })
      : null
  );

  // If we're exporting the notice, only show the marker that corresponds to that notice
  const exportableFilter = renderForExport
    ? notice => notice._id === currentNotice._id
    : () => true;

  return (
    <>
      {/* Lower z-index pane for grey markers that have had their public action deadlines passed */}
      <Pane name="grey-markers" style={{ zIndex: 401 }}>
        {filteredNotices
          .filter(notice => getActionDeadlineHasPassed(notice))
          .filter(exportableFilter)
          .map(notice => (
            <MapMarker notice={notice} key={`notice-marker-${notice._id}`} />
          ))}
      </Pane>
      {/* Higher z-index pane for blue markers that have active public action periods*/}
      <Pane name="blue-markers" style={{ zIndex: 402 }}>
        {filteredNotices
          .filter(notice => !getActionDeadlineHasPassed(notice))
          .filter(exportableFilter)
          .map(notice => (
            <MapMarker notice={notice} key={`notice-marker-${notice._id}`} />
          ))}
      </Pane>
      {/* Places of interest */}
      {(showPlacesIcons || renderForExport) && (
        <Pane name="places-of-interest" style={{ zIndex: 400 }}>
          {placesOfInterest.map(
            place =>
              visiblePlaces[place.type] && <PlaceOfInterestMarker place={place} key={place._id} />
          )}
        </Pane>
      )}
      {/* Even higher z-index pane for tooltips! :) */}
      <Pane name="tooltips" style={{ zIndex: 403 }} />
    </>
  );
};

const PlaceOfInterestMarker = ({ place }) => {
  const { name, type, coordinates } = place;

  return (
    <Marker icon={getPlaceOfInterestIcon(type)} position={[coordinates[1], coordinates[0]]}>
      <Tooltip pane="tooltips">
        <PopupContent concernPopup={true}>
          <h4>{name}</h4>
          <p>Category: {type}</p>
        </PopupContent>
      </Tooltip>
    </Marker>
  );
};
