import React from 'react';
import { Button, LinkLikeButton } from '../general/Button';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { useCSVDownload } from '../../hooks/useCSVDownload';
import moment from 'moment';
import { getMonthFromIndex } from '../../utils/helpers';
import { useCurrentAddresses } from '../../hooks/useCurrentAddresses';
import { useSelector } from 'react-redux';
import { noticePinDropSelector, pinDroppedSelector } from '../../selectors/general';

// Address download button
const AddressDownloadBtn = ({
  notice,
  linkLikeButton,
  style,
  includeStannpVariables,
  isLoading,
}) => {
  const addresses = useCurrentAddresses(notice);

  let csvHeaderString = [
    'title',
    'firstname',
    'lastname',
    'Address 1',
    'Address 2',
    'Address 3',
    'City',
    'State',
    'Zipcode',
    'Country',
  ].join(',');
  let csvContentString = addresses
    .map(({ title, firstName, lastName, addressLineOne, addressLineTwo, city, state, zip }) => {
      return [
        title,
        firstName,
        lastName,
        addressLineOne,
        addressLineTwo,
        '',
        city,
        state,
        zip,
        'US',
      ]
        .map(str => (str ? `"${str}"` : ''))
        .join(',');
    })
    .join('\n');

  let fullCsvString = [csvHeaderString, csvContentString].join('\n');

  // Update CSV string to include all stannp variables if boolean is true
  if (includeStannpVariables) {
    // Create the public action deadline strings from the current noticeObj's publicActionDeadline
    let actionDeadline = '';
    let monthInSpanish = '';
    let publicActionDeadlineEn = '';
    let publicActionDeadlineEs = '';
    if (notice.publicActionDeadline) {
      actionDeadline = moment(new Date(notice.publicActionDeadline)).utc();
      monthInSpanish = getMonthFromIndex(parseInt(actionDeadline.format('M')), true);
      publicActionDeadlineEn = actionDeadline.format('MMMM Do, YYYY');
      publicActionDeadlineEs = `el ${actionDeadline.format(
        'D'
      )} de ${monthInSpanish} de ${actionDeadline.format('YYYY')}`;
    }
    const stateRepObj = notice.stateLegislators.find(
      stateLegObj => stateLegObj.title === 'Representative'
    );
    const stateSenatorObj = notice.stateLegislators.find(
      stateLegObj => stateLegObj.title === 'Senator'
    );
    const stateRepName = stateRepObj && stateRepObj.officialName;
    const stateRepPhone = stateRepObj && stateRepObj.phone;
    const stateSenatorName = stateSenatorObj && stateSenatorObj.officialName;
    const stateSenatorPhone = stateSenatorObj && stateSenatorObj.phone;

    // Convert address data to a CSV string
    const csvHeaderString = [
      'title',
      'firstname',
      'lastname',
      'Address 1',
      'Address 2',
      'Address 3',
      'City',
      'State',
      'Zipcode',
      'Country',
      'publicactiondeadlineen',
      'publicactiondeadlinees',
      'facilityaddress',
      'facilityname',
      'principalname',
      'permitnumber',
      'publicmeetingdayen',
      'publicmeetingdateen',
      'publicmeetingtimeen',
      'publicmeetingdayes',
      'publicmeetingdatees',
      'publicmeetingtimees',
      'publicmeetingaddress',
      'staterepname',
      'staterepphone',
      'statesenatorname',
      'statesenatorpphone',
      'noticeurl',
    ].join(',');
    const csvContentString = addresses
      .map(({ title, firstName, lastName, addressLineOne, addressLineTwo, city, state, zip }) => {
        return [
          title,
          firstName,
          lastName,
          addressLineOne,
          addressLineTwo,
          '',
          city,
          state,
          zip,
          'US',
          publicActionDeadlineEn,
          publicActionDeadlineEs,
          notice.address,
          notice.facilityName,
          notice.principal,
          notice.permitNumber,
          notice.publicMeetingDayEn,
          notice.publicMeetingDateEn,
          notice.publicMeetingTimeEn,
          notice.publicMeetingDayEs,
          notice.publicMeetingDateEs,
          notice.publicMeetingTimeEs,
          notice.publicMeetingAddress,
          stateRepName,
          stateRepPhone,
          stateSenatorName,
          stateSenatorPhone,
          notice.tinyNoticeUrl,
        ]
          .map(str => (str ? `"${str}"` : ''))
          .join(',');
      })
      .join('\n');
    fullCsvString = [csvHeaderString, csvContentString].join('\n');
  }

  const downloadCSV = useCSVDownload(fullCsvString, 'addresses.csv');

  const ButtonComponent = linkLikeButton ? LinkLikeButton : Button;
  return (
    <ButtonComponent isLoading={isLoading} style={style} onClick={downloadCSV}>
      Download residence addresses
    </ButtonComponent>
  );
};

// Address download button wrapper; only show it if a notice is selected
export const AddressDownloadButton = ({
  isLoading,
  linkLikeButton,
  style,
  includeStannpVariables,
}) => {
  const currentNotice = useCurrentNotice();
  const pinDropNotice = useSelector(noticePinDropSelector);
  const pinDropped = useSelector(pinDroppedSelector);

  const notice = pinDropped ? pinDropNotice : currentNotice;

  if (!notice) {
    return null;
  }

  return (
    <AddressDownloadBtn
      notice={notice}
      style={style}
      isLoading={isLoading}
      linkLikeButton={linkLikeButton}
      includeStannpVariables={includeStannpVariables}
    />
  );
};
