import { Formik, FieldArray, Form } from 'formik';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomAddressesModal } from '../../actions/modals';
import { showCustomAddressesModalSelector } from '../../selectors/modals';
import { MultiFieldLine, RadioButtonField, ReduxCheckbox, TextField } from '../FormFields';
import { Modal, ModalFooter, ModalHeader } from '../general/Modal';
import { Button, InvertedButton, LinkLikeButton } from '../general/Button';
import { ERROR, GREY_LIGHT, GREY_LIGHTEST, GREY_MEDIUM_LIGHT } from '../../constants/cssVars';
import { validateCustomAddresses } from '../../utils/validators';
import { setCustomAddresses } from '../../actions/general';
import { customAddressesSelector } from '../../selectors/general';

const emptyAddress = {
  lastName: '',
  addressLineOne: '', 
  addressLineTwo: '',
  city: '',
  state: '',
  zip: ''
}

const AddressField = styled.div`
  background-color: ${GREY_LIGHTEST};
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  padding: 10px 0px 26px 15px;
  margin-bottom: 10px;
  font-size: 0.9em;
  .fieldWrapper {
    margin: 5px 0px;
  }
  position: relative;
`

const RemoveButtonWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`

const ContentWrapper = styled.div`
  min-height: 400px;
  overflow: auto;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${GREY_LIGHT};
  padding-bottom: 10px;
`

const FullErrorMsg = styled.div`
  color: ${ERROR};
  text-align: right;
  font-size: 0.9em;
  margin-bottom: -10px;
  margin-top: 5px;
`

export const CustomAddressesModal = () => {
  const showModal = useSelector(showCustomAddressesModalSelector);
  const dispatch = useDispatch();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const initCustomAddresses = useSelector(customAddressesSelector);
  const initValues = {
    customAddresses: initCustomAddresses ? [...initCustomAddresses] : [{ ...emptyAddress }]
  }

  const onClose = () => {
    dispatch(setCustomAddressesModal(false));
  }

  const onSubmit = (values, errors) => {
    if (Object.keys(errors).length < 1) {
      dispatch(setCustomAddresses(values.customAddresses))
      onClose();
    }
    setSubmitAttempted(true);
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} maxWidth={700}>
      <Formik
        initialValues={initValues}
        onSubmit={onSubmit}
        render={({ values }) => {
          const errors = validateCustomAddresses(values);
          const getError = (name, index) => submitAttempted && errors[index] && errors[index][name];
          const numAddressesWithErrors = Object.keys(errors).length;
          return (
          <>
            <ModalHeader style={{marginTop: '0px', marginBottom: '20px'}}>
              Add custom addresses
            </ModalHeader>
            <FieldArray
              name="customAddresses"
              render={arrayHelpers => (
                <>
                <ContentWrapper>
                  {values.customAddresses && values.customAddresses.length > 0 && (
                    values.customAddresses.map((address, index) => (
                      <AddressField key={index}>
                        <MultiFieldLine columns="auto auto auto">
                          <TextField name={`customAddresses.${index}.lastName`} label="Name" error={getError('lastName', index)} />
                          <TextField name={`customAddresses.${index}.addressLineOne`} label="Address (Line 1)*" error={getError('addressLineOne', index)} />
                          <TextField name={`customAddresses.${index}.addressLineTwo`} label="Address (Line 2)" error={getError('addressLineTwo', index)}/>
                        </MultiFieldLine>
                        <MultiFieldLine columns="auto auto auto">
                          <TextField name={`customAddresses.${index}.city`} label="City*" error={getError('city', index)}  />
                          <TextField name={`customAddresses.${index}.state`} label="State*" error={getError('state', index)}  />
                          <TextField name={`customAddresses.${index}.zip`} label="Zipcode*" error={getError('zip', index)}  />
                        </MultiFieldLine>
                        <RemoveButtonWrapper>
                          <LinkLikeButton
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            Remove
                          </LinkLikeButton>
                        </RemoveButtonWrapper>
                      </AddressField>
                    ))
                  )}
                  <LinkLikeButton type="button" onClick={() => arrayHelpers.push({...emptyAddress})}>
                    Add address +
                  </LinkLikeButton>
                </ContentWrapper>
                {(numAddressesWithErrors > 0 && submitAttempted) && <FullErrorMsg>Please fix errors in {numAddressesWithErrors} address{(numAddressesWithErrors > 1) ? 'es' : ''}</FullErrorMsg>}
                  <ModalFooter>
                    <InvertedButton type="button" onClick={onClose}>Cancel</InvertedButton>
                    <Button type="button" onClick={() => onSubmit(values, errors)}>Save</Button>
                  </ModalFooter>
                </>
              )}
            />
          </>
        )}}
      />
    </Modal>
  )
} 