import React, { useState } from 'react';
import styled from 'styled-components';
import { GREY_MEDIUM_DARK, MAX_DESKTOP_WIDTH, HEADER_HEIGHT } from '../../constants/cssVars';
import { COUNTIES_OPTIONS, COUNTY_ID_TO_FRIENDLY_NAME } from '../../constants/counties';
import { Button, LinkLikeButton } from '../general/Button';
import Select from 'react-select';
import { useUpdateCountyQuery } from '../../hooks/messagesAndRequests';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding-top: ${HEADER_HEIGHT * 4}px;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 35px;
  p {
    margin: 5px 0px 15px 0px;
    span {
      display: block;
      font-weight: 600;
      margin-bottom: 3px;
      font-size: 0.9em;
      color: ${GREY_MEDIUM_DARK};
    }
  }
  font-size: 0.95em;
`;

export const Account = ({ fullName, userEmail, primaryCounty }) => {
  const [updateCounty, loading] = useUpdateCountyQuery(respBody => {
    setEditMode(false);
  });

  const [editMode, setEditMode] = useState(false);
  const [newPrimaryCounty, setNewPrimaryCounty] = useState(primaryCounty);

  const handleNewPrimaryCounty = () => {
    updateCounty({ county: newPrimaryCounty });
  };

  const handleCountyClick = e => {
    const countyId = e.value;
    setNewPrimaryCounty(countyId);
  };

  return (
    <Wrapper>
      <div style={{ width: '1000px' }}>
        <h2 style={{ marginTop: '0px' }}>Your Account</h2>
        <>
          <TextWrapper>
            <p>
              <span>Name</span> {fullName}
            </p>
            <p>
              <span>Email</span> {userEmail}
            </p>
            <div>
              <span
                style={{
                  fontWeight: '600',
                  marginBottom: '3px',
                  fontSize: '0.9em',
                  color: `${GREY_MEDIUM_DARK}`,
                }}
              >
                Primary county
              </span>
              {!editMode ? (
                <>
                  <div style={{ display: 'flex' }}>
                    <p style={{ margin: '5px 0px 15px 0px' }}>
                      {COUNTY_ID_TO_FRIENDLY_NAME[primaryCounty]}
                    </p>
                    <LinkLikeButton
                      onClick={() => setEditMode(true)}
                      style={{ marginLeft: '10px', width: '65px', height: '35px' }}
                    >
                      Edit
                    </LinkLikeButton>
                  </div>

                  <span style={{ fontSize: '15px', fontStyle: 'italic' }}>
                    The primary county is the default county view in the map
                  </span>
                </>
              ) : (
                <div style={{ marginTop: '10px' }}>
                  <Select
                    styles={{ container: base => ({ ...base, width: '275px' }) }}
                    options={COUNTIES_OPTIONS}
                    onChange={handleCountyClick}
                    value={{
                      label: COUNTY_ID_TO_FRIENDLY_NAME[newPrimaryCounty],
                      value: newPrimaryCounty,
                    }}
                  />
                  <div style={{ display: 'flex', marginTop: '15px' }}>
                    <Button
                      onClick={handleNewPrimaryCounty}
                      wide
                      disabled={newPrimaryCounty === primaryCounty}
                      isLoading={loading}
                    >
                      Save
                    </Button>
                    <LinkLikeButton
                      onClick={() => setEditMode(false)}
                      style={{ marginLeft: '30px' }}
                    >
                      Cancel
                    </LinkLikeButton>
                  </div>
                </div>
              )}
            </div>
          </TextWrapper>
        </>
        {/* : <DotLoader />} */}
      </div>
    </Wrapper>
  );
};
