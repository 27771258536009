import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/ja-logo-horizontal.svg'

const LogoWrapper = styled.div`
  box-sizing: border-box;
  height: 27px;
  padding: 5px 10px;
  color: #fff;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 2px;
  text-align: center;
`;

const Logo = styled.img`
  margin-left: 4px;
  margin-bottom: -4px;
  width: 120.33px;
  height: 19px;
  z-index: 31;
  position: relative;
`

export const JALogo = () => (
  <a href="https://www.januaryadvisors.com/" rel="noreferrer noopener" target="_blank">
  <LogoWrapper>
    Developed by 
    <Logo src={logo} alt="January Advisors" />
  </LogoWrapper>
  </a>
)