import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { NoticesSidebar } from './Sidebar';
import { useRange } from '../../hooks/useRange';
import { useDispatch, useSelector } from 'react-redux';
import { includeStateLegislatorsInAddressesSelector, pinCoordinatesSelector } from '../../selectors/general.js';
import { Button } from '../general/Button';
import { RadiusSlider } from './NoticeDetailsSidebar';
import { Link } from 'react-router-dom';
import backIcon from '../../assets/icons/back_arrow.svg'
import { setIncludeStateLegislatorsInAddresses, setNoticePinDrop, setPinDropped } from '../../actions/general';
import { setNoticesListView } from '../../actions/notices';
import { ReduxCheckbox } from '../FormFields';
import { getAddressesQuery, getElectedOfficialsQuery } from '../../actions/queries';
import { useRequest } from 'redux-query-react';
import { stateLegislatorsSelector } from '../../selectors/entities';
import { GREY_DARK, HEADER_HEIGHT } from '../../constants/cssVars';
import { setCreateCampaignModal } from '../../actions/modals';
import { AddressDownloadButton } from './AddressDownloadButton';
import { WarningFlag } from './WarningFlag';
import { COUNTIES_WITH_ADDRESSES } from '../../constants/counties';

const BackArrow = styled.img`
  width: 30px;
  height: 20px;
`

const SmallText = styled.div`
  margin: 10px 0px;
  color: ${GREY_DARK};
  font-size: 0.9em;
`

const ContentWrapper = styled.div`
  max-height: calc(100vh - 50px - ${HEADER_HEIGHT}px);
  overflow: auto;
`

const getCounty = async(reverseCoordinates) => {
  // Using the OpenStreetMap API to get the county
  const response = await fetch(`https://nominatim.openstreetmap.org/reverse.php?lat=${reverseCoordinates[1]}&lon=${reverseCoordinates[0]}&zoom=16&format=jsonv2`);
  const data = await response.json();
  const county = data.address.county.split(' ')[0].toUpperCase();
  return county;
}

export const PinSidebar = () => {
  const dispatch = useDispatch();
  const pinCoordinates = useSelector(pinCoordinatesSelector);
  const [,, rangeInMiles] = useRange();
  const includeStateLegislators = useSelector(includeStateLegislatorsInAddressesSelector);
  const [nasaCounty, setNasaCounty] = useState();

  const reverseCoordinates = useMemo(() => {
    return [pinCoordinates[1], pinCoordinates[0]]
  }, [pinCoordinates]);

  // Gets rid of an existing pin
  const removePin = () => {
    dispatch(setPinDropped(false))
    dispatch(setNoticesListView(false))
  }

  // Queries for addresses based on lng, lat and radius + queries for state legislators based on lng and lat.
  const [{ isFinished: addressesFinished }] = useRequest(!nasaCounty ? getAddressesQuery({ lng: reverseCoordinates[0], lat: reverseCoordinates[1], radiusMiles: rangeInMiles }) : null);
  const [{ isFinished: electedOfficialsFinished }] = useRequest(getElectedOfficialsQuery({ lng: reverseCoordinates[0], lat: reverseCoordinates[1] }));
  const legislators =  useSelector(stateLegislatorsSelector);

  useEffect(() => {
    // Waits for addresses and legislators to be fetched and sets it as global state
    if (addressesFinished && electedOfficialsFinished) {
      dispatch(setNoticePinDrop(reverseCoordinates, legislators));
    }
  }, [addressesFinished, electedOfficialsFinished]);

  useEffect(() => {
    // Checks whether this notice is located in a county that uses the NASA API
    getCounty(reverseCoordinates).then(county => {
      const usingAddressApproximations = !COUNTIES_WITH_ADDRESSES.includes(county);
      setNasaCounty(usingAddressApproximations);
    });
  }, [reverseCoordinates]);


  return (
    <NoticesSidebar>
      <Link onClick={removePin} to='/app'>
        <BackArrow src={backIcon} alt="grey arrow facing left" aria-label='back'/>
      </Link>
      <ContentWrapper>

        <h4>Number of {nasaCounty ? 'Residents' : 'Residences'} in Area</h4>
        {nasaCounty && <WarningFlag showLearnMore={true} />}
        <RadiusSlider coordinates={reverseCoordinates} usingAddressApproximations={nasaCounty} />
        {!nasaCounty &&
          <>
            <AddressDownloadButton style={{ fontSize: '0.9em' }} isLoading={!addressesFinished || !electedOfficialsFinished } linkLikeButton={true} />
            <ReduxCheckbox
              label="Add state legislators to address list" 
              value="includeStateLegislatorsInAddresses"
              checked={includeStateLegislators}
              onChange={() => dispatch(setIncludeStateLegislatorsInAddresses(!includeStateLegislators))}
              wrapperStyle={{ fontSize: '0.9em' }}
            />
          </>
        }
        
        <h4>State Legislators</h4>
        {electedOfficialsFinished ? legislators.map((legislatorObj, idx) => {
          return <SmallText key={`state_leg_${idx}`}>
            <div style={{ fontWeight: 600 }}>{legislatorObj.officialName}</div>
            <div>State {legislatorObj.title}, District {legislatorObj.district}</div>
            <div>{legislatorObj.address}</div>
            <div>{legislatorObj.phone}</div>
            <div>{legislatorObj.email}</div>
          </SmallText>
        }): <SmallText>Loading...</SmallText>}
        
        {!nasaCounty &&
          <div style={{display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Button onClick={() => dispatch(setCreateCampaignModal(true))} isLoading={!addressesFinished}>Send mailers</Button>
          </div>
        }

      </ContentWrapper>
    </NoticesSidebar>
  )
}