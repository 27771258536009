import React from 'react';
import { PRIMARY } from '../constants/cssVars';
import { setActiveDropdown, setCounty } from '../actions/notices';
import { useDispatch, useSelector } from 'react-redux';
import { countySelector } from '../selectors/notices';
import Select from 'react-select'
import { COUNTIES_OPTIONS_WITH_ALL_COUNTIES } from '../constants/counties';
import { pinDroppedSelector } from '../selectors/general';
import { setPinDropped } from '../actions/general';

export const CountyFilter = () => {
  const dispatch = useDispatch();
  const selectedCounty = useSelector(countySelector);
  const pinDropped = useSelector(pinDroppedSelector);

  const handleCountyClick = (e) => {
    if (pinDropped) {
      dispatch(setPinDropped(false))
    }
    const countyId = e.value;
    dispatch(setCounty(countyId))
    dispatch(setActiveDropdown(null))
  }

  return (
    <Select
      styles={{
        container: base => ({ ...base, marginBottom: '10px' }),
        control: base => ({
          ...base,
          minHeight: 30,
          borderRadius: '0px',
          borderColor: PRIMARY,
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 4
        }),
        clearIndicator: base => ({
            ...base,
            padding: 4
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 6px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: '0px 5px',
            fontSize: '16px'
        })
      }}
      className="react-select-counties"
      options={COUNTIES_OPTIONS_WITH_ALL_COUNTIES}
      onChange={handleCountyClick}
      value={COUNTIES_OPTIONS_WITH_ALL_COUNTIES.filter(function(option) {
        return option.value === selectedCounty;
      })}
    />
  )
}