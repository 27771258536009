import { Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { setCreateCampaignModal, setCustomAddressesModal } from '../../actions/modals';
import { useApproveCampaignQuery, useCreateCampaignQuery, useUploadAddresses } from '../../hooks/messagesAndRequests';
import { showCreateCampaignModalSelector } from '../../selectors/modals';
import { Button, LinkLikeButton } from '../general/Button';
import { MultiFieldLine, RadioButtonField, ReduxCheckbox, TextField } from '../FormFields';
import { Modal, ModalFooter, ModalHeader } from '../general/Modal';
import { List } from 'react-virtualized';
import { addressesSelector, stannpSelector } from '../../selectors/entities';
import { GREY_DARK, GREY_LIGHT, GREY_LIGHTER, GREY_MEDIUM, GREY_MEDIUM_DARK, GREY_MEDIUM_LIGHT, PRIMARY_DARK } from '../../constants/cssVars';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { useRange } from '../../hooks/useRange';
import { useInterval } from '../../hooks/useInterval';
import { getCampaignBookingDatesQuery, getGroupStatusQuery } from '../../actions/queries';
import { TextLink } from '../general/Common';
import GradientCircleProgressbar from '../general/GradientProgressCircle';
import { validateStannpTemplateForm } from '../../utils/validators';
import { GENERAL_MISC, NPH, NPM, SOAH } from '../../constants/notices';
import { includeStateLegislatorsInAddressesSelector, noticePinDropSelector, pinDroppedSelector, customAddressesSelector } from '../../selectors/general';
import { addStannpPinDrop, setIncludeStateLegislatorsInAddresses } from '../../actions/general';
import { useCurrentAddresses } from '../../hooks/useCurrentAddresses';

const AddressWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  width: 100%;
  overflow: hidden;
`

const Row = styled.div`
  background-color: ${({ index }) => index % 2 === 0 ? GREY_LIGHTER : '#fff'};
  font-size: 0.8em;
  padding: 3px 10px;
`

const Text = styled.div`
  margin: 10px 0px;
  font-size: 0.95em;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > i {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
`

const SmallHeader = styled.h3`
  color: ${GREY_MEDIUM_DARK};
  font-size: 0.9em;
  margin: 0px;
  margin-bottom: 2px;
`

const FormikWrapper = styled.div`
    max-height: 400px;
    overflow: auto;
`

export const CreateCampaignModal = () => {
  const showModal = useSelector(showCreateCampaignModalSelector);
  const [pageNum, setPageNum] = useState(0);
  const [selectedBookingDate, setSelectedBookingDate] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const dispatch = useDispatch();

  const pinDropped = useSelector(pinDroppedSelector);
  const currentNotice = useCurrentNotice();
  const noticePinDrop = useSelector(noticePinDropSelector);

  const notice = pinDropped ? noticePinDrop : currentNotice;

  const onClose = () => {
    setPageNum(0);
    dispatch(setCreateCampaignModal(false));
  }

  if (!showModal) {
    return null;
  }

  const titles = [
    'Upload Addresses',
    'Choose Template',
    'Create Campaign',
    'Choose Booking Date',
    'Approve Campaign'
  ]

  const pages = [
    <ApproveAddressesPage notice={notice} setPageNum={setPageNum} pageNum={pageNum} />,
    <ChooseTemplateTypePage notice={notice} setPageNum={setPageNum} setSelectedTemplateId={setSelectedTemplateId} pageNum={pageNum} />,
    <ImportStatusPage notice={notice} setPageNum={setPageNum} selectedTemplateId={selectedTemplateId} onClose={onClose} pageNum={pageNum} />,
    <BookDatePage selectedBookingDate={selectedBookingDate} setSelectedBookingDate={setSelectedBookingDate} setPageNum={setPageNum} pageNum={pageNum} />,
    <ApproveCampaignPage notice={notice} onClose={onClose} selectedBookingDate={selectedBookingDate} pageNum={pageNum} />,
  ]

  if (pinDropped) {
    titles.unshift('Add Notice Information');
    pages.unshift(<NoticeInformation setPageNum={setPageNum} pageNum={pageNum} />);
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <SmallHeader>Send Mailers</SmallHeader>
      <ModalHeader style={{marginTop: '0px', marginBottom: '20px'}}>
        {titles[pageNum]}
      </ModalHeader>
      {pages[pageNum]}
    </Modal>
  )
}

const NoticeInformation = ({ setPageNum, pageNum }) => {
  const dispatch = useDispatch();

  const saveValuesToNoticeObj = (values) => {
    const noticePinDrop = {
      address: values.address, 
      facilityName: values.facilityName, 
      principal: values.principal, 
      permitNumber: values.permitNumber,
      tinyNoticeUrl: values.tinyNoticeUrl,
      publicMeetingAddress: values.publicMeetingAddress,
      publicActionDeadline: values.publicActionDeadline,
      publicMeetingDayEn: values.publicMeetingDayEn,
      publicMeetingDayEs: values.publicMeetingDayEs,
      publicMeetingDateEn: values.publicMeetingDateEn,
      publicMeetingDateEs: values.publicMeetingDateEs,
      publicMeetingTimeEn: values.publicMeetingTimeEn,
      publicMeetingTimeEs: values.publicMeetingTimeEs,
    }

    // Adds the key-values from noticePinDrop to the pinDropNotice state object
    dispatch(addStannpPinDrop(noticePinDrop));
    setPageNum(pageNum + 1);
  }

  return (
    <Formik initialValues={{
      address: '',
      facilityName: '',
      principal: '',
      permitNumber: '',
      tinyNoticeUrl: '',
      publicMeetingAddress: '',
      publicActionDeadline: '',
      publicMeetingDayEn: '',
      publicMeetingDayEs: '',
      publicMeetingDateEn: '',
      publicMeetingDateEs: '',
      publicMeetingTimeEn: '',
      publicMeetingTimeEs: '',
    }} onSubmit={saveValuesToNoticeObj}>
      {({ handleSubmit, errors, values, touched, submitCount }) => {
        return (
          <>
          <FormikWrapper>
            <MultiFieldLine columns="49% 49%">
              <TextField name="address" label="Facility Address" />
              <TextField name="facilityName" label="Facility Name" />
            </MultiFieldLine>

            <MultiFieldLine columns="49% 49%">
              <TextField name="principal" label="Principal Name" />
              <TextField name="permitNumber" label="Permit Number" />
            </MultiFieldLine>

            <MultiFieldLine columns="49% 49%">
              <TextField name="tinyNoticeUrl" label="Notice URL" />
              <TextField name="publicMeetingAddress" label="Public Meeting Address" />
            </MultiFieldLine>

            <MultiFieldLine columns="49% 49%">
              <TextField name="publicMeetingDayEn" label="Public Meeting Day (English)" />
              <TextField name="publicMeetingDayEs" label="Public Meeting Day (Español)" />
            </MultiFieldLine>
                    
            <MultiFieldLine columns="49% 49%">
              <TextField name="publicMeetingDateEn" label="Public Meeting Date (English)" />
              <TextField name="publicMeetingDateEs" label="Public Meeting Date (Español)" />
            </MultiFieldLine>

            <MultiFieldLine columns="49% 49%">
              <TextField name="publicMeetingTimeEn" label="Public Meeting Time (English)" />
              <TextField name="publicMeetingTimeEs" label="Public Meeting Time (Español)" />
            </MultiFieldLine>

            <MultiFieldLine columns="49% 49%">
              <TextField name="publicActionDeadline" label="Public Action Deadline" />
            </MultiFieldLine>
          </FormikWrapper>

          <ModalFooter>
            <ButtonWrapper>
              <Button onClick={handleSubmit} type="submit">
                Continue
              </Button>
            </ButtonWrapper>
          </ModalFooter>
          </>
      )}}
    </Formik>
  )
}

// Send new props for notice + addresses (basically, anything related to notice)
const ApproveAddressesPage = ({ notice, setPageNum, pageNum }) => {
  const dispatch = useDispatch();
  const includeStateLegislators = useSelector(includeStateLegislatorsInAddressesSelector);
  const customAddresses = useSelector(customAddressesSelector);
  const [,, rangeInMiles] = useRange();
  const [submit, submitting] = useUploadAddresses(() => setPageNum(pageNum + 1));

  const addresses = useCurrentAddresses(notice);

  if (!notice) {
    return null;
  }

  const handleSendMailers = (notice) => {
    submit({
      noticeObj: notice,
      lng: notice.location.coordinates[0], 
      lat: notice.location.coordinates[1], 
      radiusMiles: rangeInMiles,
      includeStateLegislators,
      customAddresses
    })
  }

  const rowRenderer = ({ index, style }) => {
    const address = addresses[index];
    const { firstName, lastName, addressLineOne, addressLineTwo, city, state, zip } = address;
    return (
      <Row style={style} index={index} key={`address_tile_${index}`}>
        {firstName} {lastName}{(firstName || lastName) ? ',' : ''} {addressLineOne}{addressLineTwo ? ` ${addressLineTwo}` : ''}, {city}, {state} {zip}   
      </Row>
    );
  }

  return (
    <>
      <Text>
        Here are the <b>{addresses.length}</b> addresses for residences the selected radius{includeStateLegislators ? <> and state legislators. </> : '. '}
        This list will be uploaded to Stannp as a recipient group if you choose to continue. 
      </Text>
      <AddressWrapper>
        <List
          height={200}
          rowCount={addresses.length}
          rowHeight={30}
          rowRenderer={rowRenderer}
          width={550}
        />
      </AddressWrapper>
      <ReduxCheckbox
        label="Add state legislators to address list" 
        value="includeStateLegislatorsInAddresses"
        checked={includeStateLegislators}
        onChange={() => dispatch(setIncludeStateLegislatorsInAddresses(!includeStateLegislators))}
        wrapperStyle={{ fontSize: '0.9em' }}
      />
      <LinkLikeButton onClick={() => dispatch(setCustomAddressesModal(true))}>
        {Array.isArray(customAddresses) && customAddresses.length > 0 ? 'Edit' : '+ Add'} custom addresses
      </LinkLikeButton>
      <ModalFooter>
        <ButtonWrapper>
          {submitting && <i>Uploading addresses to Stannp service ...</i>}
          <Button onClick={() => handleSendMailers(notice)} isLoading={submitting}>
            Continue
          </Button>
        </ButtonWrapper>
      </ModalFooter>
    </>
  )
}

const getDefaultTemplateTypeByNotice = (notice, templateTypeOptions) => {
  const currentNoticeIsPublicMeeting = notice.noticeType === NPM || notice.noticeType === NPH || notice.noticeType === SOAH
  const matchingTemplate = templateTypeOptions.find(({ industryType, isPublicMeeting }) => {
    return isPublicMeeting === currentNoticeIsPublicMeeting 
            && Array.isArray(notice.industryTypeCodes)
            && notice.industryTypeCodes.find(itc => itc.category === industryType) != null
  })
  if (matchingTemplate) {
    return matchingTemplate
  }
  return templateTypeOptions.find(({ industryType, isPublicMeeting }) => industryType === GENERAL_MISC && !isPublicMeeting) || templateTypeOptions[0];
}

const ChooseTemplateTypePage = ({ notice, setPageNum, setSelectedTemplateId, pageNum }) => {
  const { templateTypeOptions } = useSelector(stannpSelector);

  const submit = (values) => {
    let templateId = values.templateId
    if (values.templateId === 'other') {
      templateId = values.altTemplateId
    }
    setSelectedTemplateId(templateId);
    setPageNum(pageNum + 1);
  }

  const defaultOption = getDefaultTemplateTypeByNotice(notice, templateTypeOptions);

  return (
    <Formik
      initialValues={{
        altTemplateId: '',
        templateId: defaultOption.value,
      }}
      validate={validateStannpTemplateForm}
      enableReinitialize={true}
      onSubmit={submit}>
      {({ handleSubmit, errors, values, touched, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <RadioButtonField label="Stannp Template Type" name="templateId" error={getError('templateType')} options={templateTypeOptions} fontSize="0.83em" />
            {values.templateId === 'other' && <TextField extraShort={true} name="altTemplateId" error={getError('altTemplateId')}  label="Other Template ID" />}
            <ModalFooter>
              <ButtonWrapper>
                <Button onClick={handleSubmit}>
                  Continue
                </Button>
              </ButtonWrapper>
            </ModalFooter>
          </>
        )}}
    </Formik>
  )
}


// Gets a time estimate using a linear function
const getTimeEstimateByNumAddresses = (numAddresses) => {
  // This equation is from an online linear regression calculator,
  // using a sampling of (# addresses, wait time) points I gathered
  const est = (0.11539*numAddresses) + 5;
  // To make the time look nice, rounding up to the nearest 5 second interval
  const estRoundedToFive = Math.ceil(est/5)*5;
  if (estRoundedToFive < 5) {
    return 5;
  }
  return estRoundedToFive;
}

const getTimeInMinutesSecondsString = (seconds) => {
  const timeInMinutes = Math.floor(seconds / 60);
  const remainderTimeInSeconds = seconds % 60;
  return `${timeInMinutes ? `${timeInMinutes}m ` : ''}${remainderTimeInSeconds}s`
}

const ImportStatusPage = ({ notice, setPageNum, selectedTemplateId, onClose, pageNum }) => {
  const [submit, submitting] = useCreateCampaignQuery(() => setPageNum(pageNum + 1), onClose);
  const { groupId, groupImportProgress, groupStatus, numAddresses } = useSelector(stannpSelector);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [{ isFinished }, refresh] = useRequest(getGroupStatusQuery(groupId))
  const stillImporting = groupImportProgress !== '100';
  const estimatedTime = getTimeEstimateByNumAddresses(numAddresses);

  let interval = 2000;
  if (numAddresses > 1000) {
    interval = 4000;
  }

  useInterval(() => {
    if (isFinished && stillImporting) {
      refresh();
    }
  }, interval)

  useInterval(() => {
    if (stillImporting) {
      setElapsedTime(elapsedTime + 1)
    }
  }, 1000)

  const handleCreateCampaign = () => {
    submit({
      templateId: selectedTemplateId,
      facilityAddress: notice.address,
      facilityName: notice.facilityName,
      groupId,
    })
  }

  return (
    <>
      <Text>
        Stannp must finish importing & validating the uploaded addresses before creating a campaign.
      </Text>
      <Text>
        For the current number of addresses, we estimate this to take about {getTimeInMinutesSecondsString(estimatedTime)}. {stillImporting ? 'Current elapsed time is ' : 'Total time was '} {getTimeInMinutesSecondsString(elapsedTime)}.
      </Text>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <GradientCircleProgressbar percentage={groupStatus ? groupImportProgress : 0} strokeWidth={10} width={150} secondaryColor={GREY_LIGHT} />
        {groupStatus && <div style={{ textTransform: 'capitalize' }}>Status: {groupStatus}</div>}
      </div>
      <ModalFooter>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <TextLink to={`https://us.stannp.com/mailing-groups`}>
            View in Stannp
          </TextLink>
          <div>
            {/* <div style={{ display: 'block', textAlign: 'right', marginBottom: '5px'}}>
              {submitting && <i>Creating campaign ...</i>}
            </div> */}
            <Button onClick={handleCreateCampaign} isLoading={submitting} disabled={stillImporting}>
              Create Campaign
            </Button>
          </div>
        </div>
      </ModalFooter>
    </>
  )
}

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    font-size: 0.92em;
    font-style: italic;
    color: ${GREY_DARK};
  }
  > img {
    max-width: 200px;
    max-height: 170px;
    border: 1px solid ${GREY_MEDIUM};
  }
`

const ApproveCampaignPage = ({ notice, onClose, selectedBookingDate }) => {
  const [submit, submitting] = useApproveCampaignQuery(onClose);
  const { campaignId, cost, thumbnailImg, inTestMode, stannpTemplateId, importStatusIncomplete } = useSelector(stannpSelector);
  const [,,rangeInMiles] = useRange();
  const addresses = useSelector(state => addressesSelector(state, rangeInMiles));

  const actionDeadline = moment(new Date(notice.publicActionDeadline)).utc().format('MMMM Do, YYYY');

  return (
    <>
      <Text style={{ marginTop: '0px', fontSize: '0.9em' }}>
        <div style={{ marginBottom: '10px', fontWeight: '600', textDecoration: 'underline' }}>
          Pre-order summary
        </div>
        {importStatusIncomplete
          ? <div style={{ fontStyle: 'italic', marginBottom: '10px'}}>
             {addresses.length > 500 ? 
              <div>
                For especially large imports, Stannp requires some time to validate addresses. <TextLink to={`https://us.stannp.com/mailing-groups`}>
                  Check the status
                </TextLink> of your import in Stannp. 
              </div>
              :'An issue during import may have occurred, please utilize the Stannp UI link to confirm addresses & approve.'
             }
            </div>
          : <>Total Cost: <b>${cost}</b><br/></>
        }
        To be printed on: <b>{moment(selectedBookingDate).format('MMMM Do, YYYY')}</b>
        <br/>
        Facility Name: <b>{notice.facilityName}</b>
        <br/>
        Facility Address: <b>{notice.address}</b>
        <br/>
        Public Action Deadline: <b>{actionDeadline}</b>
        <br/>
        # of Recipients: <b>{addresses.length} (all residences in a {rangeInMiles}-mile radius)</b>
      </Text>
      <ThumbnailWrapper>
        <img src={thumbnailImg} alt="thumbnail of selected postcard design" />
        <div style={{ fontSize: '0.9em', marginTop: '5px' }}>
          Selected Design Preview (<TextLink to={`https://us.stannp.com/wizard/${campaignId}/mailpiece/${stannpTemplateId}`}>
          Edit design in Stannp
        </TextLink>)
        </div>
      </ThumbnailWrapper>
      {inTestMode && <Text style={{ color: PRIMARY_DARK, fontSize: '0.9em', fontStyle: 'italic', fontWeight: 600 }}>
        Note: Currently in test mode! Approval is disabled. Mailers can still be approved in the Stannp interface.
      </Text>}
      <ModalFooter>
        <ButtonWrapper>
          {submitting && <i>Submitting approval to Stannp service</i>}
        </ButtonWrapper>
        {importStatusIncomplete 
          ? <TextLink to={`https://us.stannp.com/wizard/${campaignId}/choose-recipients`}>
            Confirm Addresses & Approve in Stannp
          </TextLink>
          : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <TextLink to={`https://us.stannp.com/wizard/${campaignId}/approval`}>
                Review & Approve in Stannp
              </TextLink>
              <Button onClick={() => !inTestMode && submit({ campaignId, bookingDate: moment(selectedBookingDate).format('YYYY-MM-DD') })} disabled={inTestMode} isLoading={submitting}>
                Approve
              </Button>
            </div>
          }
      </ModalFooter>
    </>
  )
}

const BookDatePage = ({ setPageNum, selectedBookingDate, setSelectedBookingDate, pageNum }) => {
  const { campaignId, availableDates } = useSelector(stannpSelector);
  const [{ isFinished }] = useRequest(getCampaignBookingDatesQuery({ campaignId }));

  const isoDates = availableDates ? availableDates.map((date) => new Date(`${date} 00:00`)) : [];

  return (
    <>
      <Text>
        Next, select the date you would like the mailers to be printed. You can choose weekdays within one month from now.
      </Text>
      <Text>
        Mailers will typically be mailed within one business day of printing. From there, delivery times depend on USPS.
      </Text>
      <div style={{ maxWidth: '300px', minHeight: '50px', marginTop: '20px' }}>
        <label style={{ color: GREY_MEDIUM_DARK, fontSize: '0.9em'}}>
          Print Date
        </label>
        <div style={{ paddingTop: '10px' }}>
          <DatePicker
            selected={selectedBookingDate}
            onChange={(date) => setSelectedBookingDate(date)}
            includeDates={isoDates}
            popperPlacement="top-end"
            placeholderText="Click to choose a date"
          />
        </div>
      </div>
      <ModalFooter>
        <ButtonWrapper>
          <Button onClick={() => setPageNum(pageNum + 1)}>
            Continue
          </Button>
        </ButtonWrapper>
      </ModalFooter>
    </>
  )
}