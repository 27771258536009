import { createSelector } from 'reselect';
import { serializeNoticeFilters } from '../reducers/notices';

export const rangeSelector = state => state.notices.range;
export const filtersSelector = state => state.notices.filters;
export const sortSelector = state => state.notices.sort;
export const countySelector = state => state.notices.county;
export const dateRangeSelector = state => state.notices.dateRange;
export const activeDropdownSelector = state => state.notices.activeDropdown;
export const searchSelector = state => state.notices.searches || {};
export const listViewSelector = state => state.notices.listView;

const getSecondParam = (state, param2) => param2;

export const filterValuesSelector = createSelector(
  [filtersSelector, getSecondParam],
  (allFilters, filterName) => allFilters[filterName]
);

export const filtersQuerySelector = createSelector([state => state.notices], notices =>
  serializeNoticeFilters(notices)
);

export const activeFilterSelector = state => state.notices.activeFilter;
