import { MODALS } from '../constants/analytics';
import { trackEvent } from '../utils/googleAnalytics';

export const SET_NEW_USER_MODAL = 'SET_NEW_USER_MODAL';
export const SET_ABOUT_US_MODAL = 'SET_ABOUT_US_MODAL';
export const SET_EMAIL_SUBSCRIBE_MODAL = 'SET_EMAIL_SUBSCRIBE_MODAL';
export const SET_CREATE_CAMPAIGN_MODAL = 'SET_CREATE_CAMPAIGN_MODAL';
export const SET_INDUSTRY_TYPE_CODES_MODAL = 'SET_INDUSTRY_TYPE_CODES_MODAL';
export const SET_CUSTOM_ADDRESSES_MODAL = 'SET_CUSTOM_ADDRESSES_MODAL';
export const SET_LEARN_MORE_MODAL = 'SET_LEARN_MORE_MODAL';
export const SET_EDIT_NOTICE_MODAL = 'SET_EDIT_NOTICE_MODAL';

export const setNewUserModal = showModal => {
  trackEvent(MODALS, 'New user modal', showModal ? 'opened' : 'closed');
  return { type: SET_NEW_USER_MODAL, payload: showModal };
};

export const setAboutUsModal = showModal => {
  return { type: SET_ABOUT_US_MODAL, payload: showModal };
};

export const setEmailSubscribeModal = (
  showModal,
  autopopulateEmail,
  autopopulateCounties,
  showEmailUnsubscribeOption
) => {
  return {
    type: SET_EMAIL_SUBSCRIBE_MODAL,
    payload: { showModal, autopopulateEmail, autopopulateCounties, showEmailUnsubscribeOption },
  };
};

export const setCreateCampaignModal = showModal => {
  return { type: SET_CREATE_CAMPAIGN_MODAL, payload: showModal };
};

export const setIndustryTypeCodesModal = showModal => {
  return { type: SET_INDUSTRY_TYPE_CODES_MODAL, payload: showModal };
};

export const setCustomAddressesModal = showModal => {
  return { type: SET_CUSTOM_ADDRESSES_MODAL, payload: showModal };
};

export const setLearnMoreModal = showModal => {
  return { type: SET_LEARN_MORE_MODAL, payload: showModal };
};

export const setEditNoticeModal = ({ notice }) => {
  return { type: SET_EDIT_NOTICE_MODAL, payload: notice };
};
