import React from 'react';
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { MAX_DESKTOP_WIDTH, GREY_DARKEST, GREY_MEDIUM_DARK } from '../../constants/cssVars';
import { currentUserSelector } from '../../selectors/entities';
import { useSelector } from 'react-redux';

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-weight: 600;
  padding-top: 10px;
  padding-right: 10px;
  color: ${GREY_MEDIUM_DARK};
  &:hover {
    color: ${GREY_DARKEST};
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding-top: 0px;
  }  
`

const accountUrlSlug = 'account'
const yourEmailSubscriptionsUrlSlug = 'your-email-subscriptions'
const usersUrlSlug = 'users'
const emailSubscriptionUrlSlug = 'email-subscription-management'

export const SettingsSidebar = () => {
  const { pathname } = useLocation();
  const currentUser = useSelector(currentUserSelector);
  const accountPageIsActive = pathname.includes(accountUrlSlug);
  const yourEmailSubscriptionsPageIsActive = pathname.includes(yourEmailSubscriptionsUrlSlug);
  const usersPageIsActive = pathname.includes(usersUrlSlug);
  const emailSubscriptionPageIsActive = pathname.includes(emailSubscriptionUrlSlug);

  return (
    <Sidebar left={true}>
      <StyledLink style={{ color: accountPageIsActive ? '#000' : GREY_MEDIUM_DARK }} to={`/app/settings/${accountUrlSlug}`}>Your Account</StyledLink>
      <StyledLink style={{ color: yourEmailSubscriptionsPageIsActive ? '#000' : GREY_MEDIUM_DARK }} to={`/app/settings/${yourEmailSubscriptionsUrlSlug}`}>Your Email Subscriptions</StyledLink>
      {currentUser && currentUser.admin && 
        <>
          <StyledLink style={{ color: usersPageIsActive ? '#000' : GREY_MEDIUM_DARK }} to={`/app/settings/${usersUrlSlug}`}>User Management</StyledLink>
          <StyledLink style={{ color: emailSubscriptionPageIsActive ? '#000' : GREY_MEDIUM_DARK }} to={`/app/settings/${emailSubscriptionUrlSlug}`}>Email Subscriptions Management</StyledLink>
        </>
      }
    </Sidebar>
  )
}
