import ReactGA from 'react-ga';

require('dotenv').config()
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

// Initialize Google Analytics (GA)
export const init = () => {
  if (TRACKING_ID != null) {
    ReactGA.initialize(TRACKING_ID);
  }
}

// Helper function to log GA events
export const trackEvent = (category, action, label) => {
  if (TRACKING_ID != null) {
    if (label) {
      ReactGA.event({ category, action, label });
    } else {
      ReactGA.event({ category, action });
    }
  }
}
