import React from 'react';
import styled from 'styled-components';
import { GREY_LIGHT, MAIN_CONTENT_WIDTH, MAX_DESKTOP_WIDTH } from '../../constants/cssVars';

const SectionWrapper = styled.div`
  background-color: ${({ color }) => color || GREY_LIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 0};
`;

const SectionContent = styled.div`
  display: grid;
  width: min(${({ width }) => width || MAIN_CONTENT_WIDTH}px, 100%);
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  column-gap: 20px;
  margin: 30px 0px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    > div {
      margin-bottom: 10px;
    }
  }
`;

export const Section = ({
  color,
  zIndex,
  children,
  templateColumns = '45% 55%',
  style,
  id,
  width,
}) => {
  return (
    <SectionWrapper color={color} zIndex={zIndex} id={id}>
      <SectionContent width={width} style={style} templateColumns={templateColumns}>
        {children}
      </SectionContent>
    </SectionWrapper>
  );
};
