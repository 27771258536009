import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, LinkLikeButton } from './general/Button';
import { BG_LIGHTER, SHADOW_MEDIUM, VAPOR_GREY, PRIMARY, MAX_DESKTOP_WIDTH } from '../constants/cssVars.js';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField } from './FormFields';
import { validateForgotPassword, validateLogin } from '../utils/validators';
import { useForgotPasswordQuery, useLoginQuery } from '../hooks/messagesAndRequests';
import { BUTTON_CLICK } from '../constants/analytics';
import { trackEvent } from '../utils/googleAnalytics';
import { EnterKeyPressListener } from './general/EnterKeyPressListener';

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  position: fixed;
  top: 8px;
  left: 12px;
  font-weight: 600;
  font-size: 1.1em;
  > a {
    font-family: 'Aleo', serif;
    text-decoration: none;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    position: absolute;
  }  
`

export const MainContent = styled.div`
  height: 100vh;
  height: calc(100vh - 15px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${VAPOR_GREY};
`;

export const Tile = styled.div`
  padding: 30px 20px;
  background-color: ${BG_LIGHTER};
  box-shadow: ${SHADOW_MEDIUM};
  border: 1px solid ${PRIMARY};
  border-radius: 0px;
  display: flex;
  flex-direction: column; 
  width: 350px;
`;

export const IntroText = styled.h1`
  color: ${PRIMARY};
  font-weight: 600;
  padding-bottom: 20px;
  margin: 0px;
  font-size: 30px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

const LoginContent = ({setShowForgotPassword}) => {
  const navigate = useNavigate();

  const [submit, submitting] = useLoginQuery(() => {
    navigate('/app/');
  }) 

  return (
    <Formik
      validate={validateLogin}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={submit}>
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <IntroText>
              Login
            </IntroText>
            <TextField label="Email" error={getError('email')} style={{ margin: '5px 0px '}} type="email" name="email" />
            <TextField label="Password" error={getError('password')}  style={{ margin: '5px 0px '}} type="password" name="password" />
            <ButtonWrapper>
              <Button onClick={handleSubmit} isLoading={submitting}>
                Log in
              </Button>
            </ButtonWrapper>
            <EnterKeyPressListener onEnter={() => handleSubmit(values)} />
            <LinkLikeButton style={{fontSize: '16px'}} onClick={() => {
              trackEvent(BUTTON_CLICK, "Forgot password")
              setShowForgotPassword(true)}
            }>
              Forgot password
            </LinkLikeButton>
            <Link to="/app" style={{ textAlign: 'center', color: PRIMARY, fontSize: '16px', fontWeight: 600, marginTop: '10px' }}>
              View public site
            </Link>
          </>
      )}}
    </Formik>
  )
}

const ForgotPasswordContent = ({ setShowForgotPassword }) => {
  const [resetSubmit, resetSubmitting] = useForgotPasswordQuery();

  return (
    <Formik
      validate={validateForgotPassword}
      initialValues={{ email: '' }}
      onSubmit={resetSubmit}>
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <IntroText>
              Forgot Password
            </IntroText>
            <p style={{margin: '0px 0px 10px 0px', padding: '0px', fontSize: '17px'}}>
              Please enter your account email below. We'll send you an email with instructions on how to reset your password.
            </p>
            <TextField label="Email" error={getError('email')} style={{ margin: '5px 0px '}} type="email" name="email" />
            <ButtonWrapper>
              <Button onClick={handleSubmit} isLoading={resetSubmitting}>
                Send Reset Instructions
              </Button>
            </ButtonWrapper>
            <LinkLikeButton style={{fontSize: '16px'}} onClick={() => setShowForgotPassword(false)}>
              Back to login
            </LinkLikeButton>
          </>
      )}}
    </Formik>
  )
}

export const Login = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <Wrapper>
      <Header>
        <Link to='/app' style={{fontWeight: '700', color: PRIMARY }}>AirMail</Link>
      </Header>
      <MainContent>
        <Tile>
          {showForgotPassword 
            ? <ForgotPasswordContent setShowForgotPassword={setShowForgotPassword} />
            : <LoginContent setShowForgotPassword={setShowForgotPassword} />
          }
        </Tile>
      </MainContent>
    </Wrapper>
  )
}