import React from 'react';
import styled from 'styled-components';
import aahBackground from '../../assets/aah_nav.png';
import { BG_LIGHTER } from '../../constants/cssVars';
import { IntroText } from '../Login';
import { EmailSubscriptionsPreferences } from './EmailSubscriptionsPreferences';
import { userSubscriptionEmailSelector } from '../../selectors/entities';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { getUserSubscriptionEmailQuery } from '../../actions/queries';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${aahBackground});
  background-size: cover;
  height: 100vh;
`

const Tile = styled.div`
  background-color: ${BG_LIGHTER};
  padding: 70px 40px;
  width: 500px;
  border: 1px solid #00A7D9;
`

export const EmailSubscriptions = () => {
  const id = window.location.href.split('/').pop();

  const [{ isFinished }] = useRequest(getUserSubscriptionEmailQuery({ id }));
  const email = useSelector(userSubscriptionEmailSelector);

  if (!isFinished) {
    return <p>Loading...</p>
  }

  return (
    <Wrapper>
      <Tile>
        <IntroText>AirMail</IntroText>
        <EmailSubscriptionsPreferences userEmail={email} redirectToMap />
      </Tile>
    </Wrapper>
  )
}