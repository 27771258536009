import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import aahNav from '../../assets/aah_nav.png';
import styled from 'styled-components'
import { setIndustryTypeCodesModal } from '../../actions/modals';
import { showIndustryTypeCodesModalSelector } from '../../selectors/modals';
import { Modal } from '../general/Modal';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { GREY_LIGHT, GREY_MEDIUM_DARK } from '../../constants/cssVars';
import { INDUSTRY_TYPES_ARRAY } from '../../constants/notices';

const CODE_ROW_WITDH_EM = 7;
const CLASSIFICATION_ROW_WIDTH_EM = 8;

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  font-size: 0.85em;
  > div {
    width: calc((100% - ${CODE_ROW_WITDH_EM + CLASSIFICATION_ROW_WIDTH_EM}em)/2);
    text-align: left;
  }
  > div:last-child {
    text-align: right;
  }
`

const CodesTable = styled.div`
  > div:nth-child(odd) {
    background-color: ${GREY_LIGHT};
  }
`

export const IndustryTypeCodesModal = () => {
  const showModal = useSelector(showIndustryTypeCodesModalSelector);
  const dispatch = useDispatch();
  const notice = useCurrentNotice();
  const codes = notice && Array.isArray(notice.industryTypeCodes) ? notice.industryTypeCodes : []

  const onClose = () => {
    dispatch(setIndustryTypeCodesModal(false));
  }
  
  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} headerImg={aahNav} style={{ width: 'min(95%, 750px)'  }}>
      <Title>Industry Type Codes</Title>
      <CodesTable>
        <Row style={{ fontWeight: '600'}}>
            <div style={{ width: `${CLASSIFICATION_ROW_WIDTH_EM}em` }}>Classification</div>
            <div style={{ width: `${CODE_ROW_WITDH_EM}em`  }}>Code</div>
            <div>Name</div>
            <div>Category*</div>
        </Row>
        {codes.map((codeObj, index) => {
          return <Row key={`industry_type_code_${index}`}>
            <div style={{ width: `${CLASSIFICATION_ROW_WIDTH_EM}em`  }}>
              {codeObj.codeSystem}
            </div>
            <div style={{ width: `${CODE_ROW_WITDH_EM}em`  }}>
              {codeObj.code}
            </div>
            <div>
              {codeObj.industryName}
            </div>
            <div>
              {codeObj.category}
            </div>
          </Row>
        })}
      </CodesTable>
      <i><p style={{ fontSize: '0.85em', color: GREY_MEDIUM_DARK }}>
        *AirMail categorizes codes into the following categories: {INDUSTRY_TYPES_ARRAY.join(', ')}
      </p></i>
    </Modal>
  )
}