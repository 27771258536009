import React from 'react';
import { PRIMARY } from '../../constants/cssVars';
import { Wrapper, Table, List, Text, DescriptionAndAddUser } from './UserManagement';
import { Button, LinkLikeButton } from '../general/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailSubscribeModal } from '../../actions/modals';
import { COUNTY_ID_TO_FRIENDLY_NAME } from '../../constants/counties';
import { getAllEmailSubscriptionsQuery } from '../../actions/queries';
import { useRequest } from 'redux-query-react';
import { statusIsGood } from '../../utils/helpers';
import { allSubscriptionsSelector } from '../../selectors/entities';

export const EmailSubscriptionManagement = () => {
  const dispatch = useDispatch();
  const [{ isFinished, status }] = useRequest(getAllEmailSubscriptionsQuery());
  const subscriptions = useSelector(allSubscriptionsSelector) || [];

  return (
    <Wrapper>
      <div>
        <h2 style={{ marginTop: '0px' }}>
          Email Subscriptions Management
        </h2>
        <DescriptionAndAddUser>
          <Text style={{ marginBottom: '0px', marginRight: '10px'  }}>
            Admin users can manage users' email subscriptions.
          </Text>
          <Button style={{ minWidth: '10em' }} onClick={() => dispatch(setEmailSubscribeModal(true))}>
            Add email subscription +
          </Button>
        </DescriptionAndAddUser>
        <Table color={PRIMARY}>
          {(!isFinished || !statusIsGood(status)) &&
            <div>
              Loading ...
            </div>
          }
          <List>
            {subscriptions.length > 0 ?
              subscriptions.map((subscription) => {
                return <div key={subscription._id}>
                  <div style={{width: '300px'}}>{subscription.email}</div>
                  <div style={{width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginRight: '30px'}}>{subscription.counties.join(', ')}</div>
                  <LinkLikeButton onClick={() => dispatch(setEmailSubscribeModal(true, subscription.email, subscription.counties.map((county) => ({ label: COUNTY_ID_TO_FRIENDLY_NAME[county], value: county })), true))}>Edit</LinkLikeButton>
                </div>
              })
              :
              <p style={{ display: 'flex', justifyContent: 'center', color: '#808080', marginTop: '50px' }}>
                There are no email subscriptions
              </p>
            }
          </List>
        </Table>
      </div>
    </Wrapper>
  )
}