export const INDUSTRY_TYPE_FILTERS = 'industryTypeFilters';
export const NOTICE_TYPE_FILTERS = 'noticeTypeFilters';
export const STATUS_FILTERS = 'statusFilters';
export const ENGAGEMENT_TYPE_FILTERS = 'engagementTypeFilters';
export const PROGRAM_AREA_FILTERS = 'programAreaFilters';
export const PUBLIC_ACTION_DEADLINE_FILTERS = 'publicActionDeadlineFilters';

// Notice Types
export const NORI = 'notice_of_receipt_intent';
export const NOAPD = 'notice_preliminary_decision';
export const NOA = 'notice_of_application';
export const NPM = 'notice_of_public_meeting';
export const NPH = 'notice_of_public_hearing';
export const SOAH = 'soah_hearing';
export const MINOR_AMENDMENT = 'minor_amendment';
export const NOR = 'registration';

export const NOTICE_TYPE_TO_FULL_NAME = {
  [NORI]: 'Notice of Receipt/Intent',
  [NOAPD]: 'Notice of Preliminary Decision',
  [NOA]: 'Notice of Application',
  [NPM]: 'Notice of Public Meeting',
  [NPH]: 'Notice of Public Hearing',
  [SOAH]: 'Notice of SOAH Hearing',
  [NOR]: 'Notice of Registration',
  [MINOR_AMENDMENT]: 'Notice of Minor Amendment',
};

export const noticeTypeOptions = [
  { label: NOTICE_TYPE_TO_FULL_NAME[NORI], value: NORI },
  { label: NOTICE_TYPE_TO_FULL_NAME[NOAPD], value: NOAPD },
  { label: NOTICE_TYPE_TO_FULL_NAME[NOA], value: NOA },
  { label: NOTICE_TYPE_TO_FULL_NAME[NPM], value: NPM },
  { label: NOTICE_TYPE_TO_FULL_NAME[NPH], value: NPH },
  { label: NOTICE_TYPE_TO_FULL_NAME[SOAH], value: SOAH },
  { label: NOTICE_TYPE_TO_FULL_NAME[NOR], value: NOR },
  { label: NOTICE_TYPE_TO_FULL_NAME[MINOR_AMENDMENT], value: MINOR_AMENDMENT },
];

export const PUBLIC_COMMENT = 'public_comment';
export const PUBLIC_MEETING_REQUEST = 'public_meeting';
export const CONTESTED_CASE_HEARING_REQ = 'contested_case_hearing';
export const NOTICE_AND_COMMENT_HEARING_REQ = 'notice_and_comment_hearing';

export const PUBLIC_ACTION_TO_FULL_NAME = {
  [PUBLIC_COMMENT]: 'Submit public comment',
  [PUBLIC_MEETING_REQUEST]: 'Request a public meeting',
  [CONTESTED_CASE_HEARING_REQ]: 'Request a contested case hearing',
  [NOTICE_AND_COMMENT_HEARING_REQ]: 'Request a notice and comment hearing',
};

export const engagementOptions = [
  { label: PUBLIC_ACTION_TO_FULL_NAME[PUBLIC_COMMENT], value: PUBLIC_COMMENT },
  { label: PUBLIC_ACTION_TO_FULL_NAME[PUBLIC_MEETING_REQUEST], value: PUBLIC_MEETING_REQUEST },
  {
    label: PUBLIC_ACTION_TO_FULL_NAME[CONTESTED_CASE_HEARING_REQ],
    value: CONTESTED_CASE_HEARING_REQ,
  },
  {
    label: PUBLIC_ACTION_TO_FULL_NAME[NOTICE_AND_COMMENT_HEARING_REQ],
    value: NOTICE_AND_COMMENT_HEARING_REQ,
  },
];

// Facility/Industry Types
export const CONCRETE_BATCH = 'Concrete Batch Plants';
export const PETROCHEM_REFINERY = 'Petro/Refineries/Chemical';
export const TERMINALS = 'Terminals';
export const LANDFILL = 'Landfills';
export const GENERAL_MISC = 'General/Misc';

export const INDUSTRY_TYPES_ARRAY = [
  CONCRETE_BATCH,
  PETROCHEM_REFINERY,
  TERMINALS,
  LANDFILL,
  GENERAL_MISC,
];

// "Name" to show when there is no facilityName provided for a notice
export const GENERIC_FACILITY_NAME_BY_CATEGORY = {
  [CONCRETE_BATCH]: 'Concrete Batch Plant',
  [PETROCHEM_REFINERY]: 'Petrochemical/Chemical/Refinery Facility',
  [TERMINALS]: 'Terminal',
  [LANDFILL]: 'Landfill',
};

export const industryTypeOptions = [
  { label: 'Concrete Batch', value: CONCRETE_BATCH },
  { label: 'Petrochemical/Refinery', value: PETROCHEM_REFINERY },
  { label: 'Landfill', value: LANDFILL },
  { label: 'General/Misc', value: GENERAL_MISC },
  { label: 'Terminals', value: TERMINALS },
];

export const AAH_URL_BY_INDUSTRY_TYPE = {
  [CONCRETE_BATCH]: 'https://airalliancehouston.org/our-advocacy/airmail-concrete-batch-plants/',
  [PETROCHEM_REFINERY]: 'https://airalliancehouston.org/airmail-refinery-petrochemical/',
  [TERMINALS]: 'https://airalliancehouston.org/airmail-storage-export-terminals/',
  [LANDFILL]: 'https://airalliancehouston.org/airmail-landfills/',
  [GENERAL_MISC]: 'https://airalliancehouston.org/other-permits/',
};

// Program Areas
export const AIR_QUALITY = 'air_quality';
export const MUNICIPAL_SOLID_WASTE = 'municipal_solid_waste';
export const WATER_QUALITY = 'water_quality';

export const PROGRAM_AREA_TO_FRIENDLY_NAME = {
  [AIR_QUALITY]: 'Air Quality',
  [WATER_QUALITY]: 'Water Quality',
  [MUNICIPAL_SOLID_WASTE]: 'Municipal Solid Waste',
};

export const programAreaOptions = [
  { label: PROGRAM_AREA_TO_FRIENDLY_NAME[AIR_QUALITY], value: AIR_QUALITY },
  { label: PROGRAM_AREA_TO_FRIENDLY_NAME[WATER_QUALITY], value: WATER_QUALITY },
  { label: PROGRAM_AREA_TO_FRIENDLY_NAME[MUNICIPAL_SOLID_WASTE], value: MUNICIPAL_SOLID_WASTE },
];

// Public Actions
export const ISSUANCE_DATE = 'issuanceDate';
export const NUMBER_OF_COMMENTS = 'publicTotalComments';
export const ACTION_DEADLINE = 'publicActionDeadline';
export const ACTION_DEADLINE_ASC = 'publicActionDeadlineAsc';
export const RESIDENCES_IN_HALF_MILE = 'residencesInHalfMile';
export const RESIDENCES_IN_QUARTER_MILE = 'residencesInQuarterMile';
export const RESIDENCES_IN_MILE = 'residencesInMile';
export const RESIDENCES_IN_TWO_MILES = 'residencesInTwoMile';
export const PLACES_IN_MILE = 'placesInMile';

export const MILEAGE_SORTS = [
  RESIDENCES_IN_HALF_MILE,
  RESIDENCES_IN_QUARTER_MILE,
  RESIDENCES_IN_MILE,
  RESIDENCES_IN_TWO_MILES,
];

export const MILEAGE_SORTS_TO_MILES = {
  [RESIDENCES_IN_QUARTER_MILE]: '0.25 miles',
  [RESIDENCES_IN_HALF_MILE]: '0.5 miles',
  [RESIDENCES_IN_MILE]: '1 mile',
  [RESIDENCES_IN_TWO_MILES]: '2 miles',
};

export const sortOptions = [
  { label: 'Public action deadline (desc)', value: ACTION_DEADLINE },
  { label: 'Public action deadline (asc)', value: ACTION_DEADLINE_ASC },
  { label: 'Issuance date', value: ISSUANCE_DATE },
  { label: 'Public comments and requests', value: NUMBER_OF_COMMENTS },
  { label: 'Residences in 0.25 miles', value: RESIDENCES_IN_QUARTER_MILE },
  { label: 'Residences in 0.5 miles', value: RESIDENCES_IN_HALF_MILE },
  { label: 'Residences in 1 mile', value: RESIDENCES_IN_MILE },
  { label: 'Residences in 2 miles', value: RESIDENCES_IN_TWO_MILES },
  { label: 'Places of concern in 1 mile', value: PLACES_IN_MILE },
];

export const sortOptionsNASACounty = [
  { label: 'Public action deadline (desc)', value: ACTION_DEADLINE },
  { label: 'Public action deadline (asc)', value: ACTION_DEADLINE_ASC },
  { label: 'Issuance date', value: ISSUANCE_DATE },
  { label: 'Residents in 0.5 miles', value: RESIDENCES_IN_HALF_MILE },
  { label: 'Residents in 1 mile', value: RESIDENCES_IN_MILE },
  { label: 'Residents in 2 miles', value: RESIDENCES_IN_TWO_MILES },
];

// Public Action Deadlines
export const DEADLINE_PASSED = 'deadlinePassed';
export const DEADLINE_NOT_PASSED = 'deadlineNotPassed';
export const DEADLINE_UNKNOWN = 'deadlineUnknown';

export const publicActionDeadlineOptions = [
  { label: 'Deadline Passed', value: DEADLINE_PASSED },
  { label: 'Deadline Not Passed', value: DEADLINE_NOT_PASSED },
  { label: 'Deadline Unknown', value: DEADLINE_UNKNOWN },
];

// Sorting Options
export const LAST_YEAR = 'lastYear';
export const LAST_TWO_MONTHS = 'lastTwoMonths';
export const LAST_MONTH = 'lastMonth';
export const LAST_WEEK = 'lastWeek';
export const TODAY = 'today';
export const CUSTOM_RANGE = 'customRange';

export const DATE_TYPE_TO_FRIENDLY_NAME = {
  [LAST_YEAR]: 'Last year',
  [LAST_TWO_MONTHS]: 'Last two months',
  [LAST_MONTH]: 'Last month',
  [LAST_WEEK]: 'Last week',
  [TODAY]: 'Today',
  [CUSTOM_RANGE]: 'Custom range',
};

export const dateOptions = [
  { label: DATE_TYPE_TO_FRIENDLY_NAME[TODAY], value: TODAY },
  { label: DATE_TYPE_TO_FRIENDLY_NAME[LAST_WEEK], value: LAST_WEEK },
  { label: DATE_TYPE_TO_FRIENDLY_NAME[LAST_MONTH], value: LAST_MONTH },
  { label: DATE_TYPE_TO_FRIENDLY_NAME[LAST_TWO_MONTHS], value: LAST_TWO_MONTHS },
  { label: DATE_TYPE_TO_FRIENDLY_NAME[LAST_YEAR], value: LAST_YEAR },
  { label: DATE_TYPE_TO_FRIENDLY_NAME[CUSTOM_RANGE], value: CUSTOM_RANGE },
];

// Search types
export const NAME_SEARCH = 'nameSearch';
export const NAICS_SEARCH = 'naicsSearch';
export const SIC_SEARCH = 'sicSearch';
export const RN_SEARCH = 'rnSearch';
export const PERMIT_NUM_SEARCH = 'permitNumberSearch';
export const CODE_SEARCHES_ARR = [NAICS_SEARCH, SIC_SEARCH, RN_SEARCH, PERMIT_NUM_SEARCH];

export const FILTERS_DROPDOWN_ID = 'filters_dropdown';
export const SORT_DROPDOWN_ID = 'sort_dropdown';
export const DATE_RANGE_DROPDOWN_ID = 'date_range_dropdown';
export const ACCOUNT_DROPDOWN_ID = 'account_dropdown';
export const USER_ROW_DROPDOWN_ID = 'user_row_dropdown';
export const SETTINGS_DROPDOWN_ID = 'settings_dropdown';
export const COUNTY_DROPDOWN_ID = 'county_dropdown';
export const SAVED_FILTERS_DROPDOWN_ID = 'saved_filters';
