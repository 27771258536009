export const isValidEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const isValidPhone = (phone) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
const isValidStannpTemplateId = (id) => /^\d{6}$/.test(id);
const isOnlyNumbers = (str) => /^[0-9]*$/.test(str);
const isEmptyStringOrNull = (val) => val == null || val === "";

export const validateLogin = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required'
  }
  return errors;
}

export const validateForgotPassword = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

export const validateResetPassword = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Invalid password, must be 8 characters or more';
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = "Passwords must match"
  }
  return errors;
}

export const validateAddUser = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  return errors;
}

export const validateCustomAddresses = (values) => {
  const errors = {};
  values.customAddresses.forEach((addressObj, index) => {
    let addressErrors = {};
    if (!addressObj.addressLineOne) {
      addressErrors.addressLineOne = 'Required'
    }
    if (!addressObj.city) {
      addressErrors.city = 'Required'
    }
    if (!addressObj.zip) {
      addressErrors.zip = 'Required'
    }
    if (!addressObj.state) {
      addressErrors.state = 'Required'
    } else if (addressObj.state.length !== 2) {
      addressErrors.state = 'Please use a 2 letter state abbreviation'
    }
    if (Object.keys(addressErrors).length > 0) {
      errors[index] = addressErrors;
    }
  })
  return errors;
}

export const validateStannpTemplateForm = (values) => {
  const errors = {};
  if (!values.templateId) {
    errors.templateId = 'Required';
  }
  if (values.templateId === 'other') {
    if (isEmptyStringOrNull(values.altTemplateId)) {
      errors.altTemplateId = 'Required'
    }
    else if (!isValidStannpTemplateId(values.altTemplateId)) {
      errors.altTemplateId = 'Must be valid a 6 digit ID'
    }
  }
  return errors;
}