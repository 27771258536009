import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import close from '../../assets/icons/close.svg';
import embed from '../../assets/icons/social/embed.svg';
import facebook from '../../assets/icons/social/facebook.svg';
import linkedin from '../../assets/icons/social/linkedin.svg';
import twitter from '../../assets/icons/social/twitter.svg';
import download from '../../assets/icons/download_primary.svg';
import share from '../../assets/icons/share-solid.svg';
import exampleExport from '../../assets/example_export.png';
import { GREY_LIGHT, MAX_DESKTOP_WIDTH, PRIMARY, PRIMARY_DARK } from '../../constants/cssVars';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { createScreenshot } from '../../utils/screenshot';
import { Map } from './Map';
import {
  NoticeDetailsHeader,
  PublicActions,
  PublicMeetingNotice,
  StateLegislators,
} from './NoticeDetailsSidebar';

const DropdownButton = styled.button`
  display: flex;
  font-size: 0.9em;
  background-color: ${({ active }) => (active ? '#d3edf3' : '#fff')};
  border: 1px solid ${PRIMARY};
  padding: 2px 6px;
  color: ${PRIMARY};
  cursor: pointer;
  font-weight: 600;
  transition: all 0.1s linear;
  &:hover {
    color: ${PRIMARY_DARK};
    background-color: #d3edf3;
    border: 1px solid ${PRIMARY};
  }
`;

const ShareIcon = styled.img`
  display: block;
  height: 22px;
  width: 22px;
  margin: 0 0 0 6px;
`;

const ShareDropdown = styled.div`
  position: absolute;
  top: 36px;
  right: 0px;
  z-index: 400;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  font-size: 0.9em;
  max-height: calc(100vh - 40px - 125px);
  overflow: auto;
  @media only screen and (max-width: 600px) {
    position: fixed;
    top: 40px;
    max-width: 232px;
  }
`;

const ShareHeader = styled.div`
  font-family: 'Aleo', serif;
  font-size: 1.1em;
  text-align: center;
  margin: 0 0 8px;
`;

const SocialIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  a {
    align-items: center;
    border: 1px solid ${GREY_LIGHT};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0 4px;
    height: 32px;
    width: 32px;
  }
  a:hover {
    background-color: #d3edf3;
  }
  img {
    display: block;
    height: 22px;
    width: 22px;
    margin: 0 5px 0 5px;
  }
`;

const ShareLinkWrapper = styled.div`
  font-family: 'Aleo', serif;
  font-size: 0.9em;
  margin: 16px 0 8px 0;
  > div {
    margin: 0 0 4px 0;
  }
  img {
    height: 1em;
    margin: 0 0 0 6px;
    transform: translateY(3px);
    width: 1.1em;
  }
`;

const CopyLink = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${PRIMARY};
  padding: 4px 6px;
  width: 320px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 220px;
  }
`;

const LinkText = styled.div`
  flex-grow: 1;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  input {
    border: none;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Copy = styled.div`
  color: ${PRIMARY};
  cursor: pointer;
  text-align: right;
  width: 55px;
`;

const Close = styled.img`
  cursor: pointer;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 12px;
  :hover {
    opacity: 0.8;
  }
`;

const DownloadButtonWrapper = styled.div`
  font-family: 'Aleo', serif;
  font-size: 0.9em;
  margin-top: 16px;
  > div {
    display: flex;
    margin-top: 4px;
  }
`;

const ScreenshotButton = styled.button`
  background: white;
  border: 1px solid ${PRIMARY};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  > img {
    filter: blur(1px);
    max-width: 73%;
  }
  :hover {
    background-color: #d3edf3;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    > img {
      max-width: 50%;
    }
  }
`;

const DownloadAction = styled.div`
  color: ${PRIMARY};
  font-family: 'Aleo', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 3px;
  > img {
    height: 22px;
    width: 22px;
  }
`;

const NoticeWrapper = styled.div`
  box-sizing: border-box;
  font-size: 80%;
  padding: 16px;
  position: absolute;
  right: 0px;
  width: 400px;
  h4 {
    margin: 12px 0 8px 0;
  }
`;

const ExportBanner = styled.div`
  background: rgb(242, 245, 248);
  font-size: 15px;
  border-bottom: 1px solid ${PRIMARY};
  padding: 14px 16px;
`;

const CopyableLink = ({ link }) => {
  const [copied, setCopied] = useState(false);

  const setCopiedTimeout = useCallback(() => {
    setCopied(true);
    const t = window.setTimeout(() => setCopied(false), 2000);
    return () => {
      setCopied(false);
      window.clearTimeout(t);
    };
  }, []);

  const handleCopyClick = async e => {
    try {
      e.stopPropagation();
      await navigator.clipboard.writeText(link);
      setCopiedTimeout();
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <CopyLink>
      <LinkText>
        <input value={link} readOnly={true} />
      </LinkText>
      <Copy onClick={handleCopyClick}>{copied ? 'Copied!' : 'Copy'}</Copy>
    </CopyLink>
  );
};

/**
 * Reders a version of the notice details sidebar that's simplified and has no
 * links or dropdowns or other UI elements.
 */
const ExportableNotice = () => {
  const notice = useCurrentNotice();
  if (!notice) {
    return null;
  }

  return (
    <>
      <NoticeDetailsHeader notice={notice} renderForExport={true} />
      <PublicMeetingNotice notice={notice} renderForExport={true} />
      <PublicActions notice={notice} renderForExport={true} />
      <StateLegislators notice={notice} renderForExport={true} />
    </>
  );
};

const ScreenshotComponent = ({ onSceenshotComplete }) => {
  const componentWrapperRef = useRef(null);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // The map takes some time to load, we want to wait until the tiles are
    // fully loaded and the whole component is mounted before we kick off the
    // screenshot function. Once this is done, this component will destroy
    // itself.
    if (tilesLoaded && mounted) {
      try {
        createScreenshot(componentWrapperRef, onSceenshotComplete);
      } catch (error) {
        console.error(error);
        toast.error('Unable to create screenshot with the current view.');
        onSceenshotComplete();
      }
    }
  }, [tilesLoaded, mounted]);

  return (
    <div ref={componentWrapperRef} style={{ position: 'fixed', width: '1300px', left: '-9999px' }}>
      <ExportBanner>
        New or increased pollution has been proposed for this location. Visit{' '}
        <span style={{ textDecoration: 'underline' }}>{process.env.REACT_APP_SITE_URL}</span> to
        view all new pollution permit notices in Texas.
      </ExportBanner>
      <NoticeWrapper>
        <ExportableNotice />
      </NoticeWrapper>
      <Map animate={false} renderForExport={true} onReady={() => setTilesLoaded(true)} />
    </div>
  );
};

const ShareSocial = ({ shareHeaderText }) => {
  const currentNotice = useCurrentNotice();
  const [active, setIsActive] = useState(false);
  const [createScreenshotComponent, setCreateScreenshotComponent] = useState(false);
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  // Only share the notice search params on the notice view, not all the notice filters
  const shareableSearch =
    currentNotice && searchParams.get('notice_id')
      ? `?notice_id=${searchParams.get('notice_id')}`
      : search;

  const SHARE_TEXT = 'Track and take action on polluter permit notices.';
  const shareLink = `${process.env.REACT_APP_SITE_URL}${pathname}${shareableSearch}`;
  const embedLink = `${process.env.REACT_APP_SITE_URL}/aah${pathname}${shareableSearch}`;

  const encodedShareLink = encodeURIComponent(shareLink);
  const encodedShareText = encodeURIComponent(SHARE_TEXT);

  useEffect(() => {
    const close = () => setIsActive(false);
    window.addEventListener('click', close);
    return () => {
      window.removeEventListener('click', close);
    };
  }, []);

  const removeScreenshotComponent = useCallback(() => setCreateScreenshotComponent(false), []);

  return (
    <div style={{ position: 'relative' }}>
      <DropdownButton
        active={active}
        onClick={e => {
          e.stopPropagation();
          setIsActive(!active);
        }}
      >
        <div>Share</div>
        <ShareIcon src={share} alt="Share" />
      </DropdownButton>
      {active && (
        <ShareDropdown onClick={e => e.stopPropagation()}>
          <Close alt="close-icon" onClick={() => setIsActive(false)} src={close} />
          <ShareHeader>{shareHeaderText}</ShareHeader>
          <SocialIconWrapper>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}&quote=${encodedShareText}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={facebook} alt="facebook-icon" />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${encodedShareLink}&text=${encodedShareText}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={twitter} alt="twitter-icon" />
            </a>
            <a
              href={`https://linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={linkedin} alt="linkedin-icon" />
            </a>
          </SocialIconWrapper>
          <ShareLinkWrapper>
            <div>Link to share this view</div>
            <CopyableLink link={shareLink} />
          </ShareLinkWrapper>
          <ShareLinkWrapper>
            <div>
              Link to embed this view
              <img src={embed} alt="embed" />
            </div>
            <CopyableLink link={embedLink} />
          </ShareLinkWrapper>
          {currentNotice && (
            <DownloadButtonWrapper>
              Download summary image
              <ScreenshotButton
                onClick={() => {
                  setCreateScreenshotComponent(true);
                }}
              >
                <img src={exampleExport} alt="example-export" />
                <DownloadAction>
                  <div>Download</div>
                  <img src={download} alt="download-icon" />
                </DownloadAction>
              </ScreenshotButton>
            </DownloadButtonWrapper>
          )}
        </ShareDropdown>
      )}
      {createScreenshotComponent && (
        <ScreenshotComponent onSceenshotComplete={removeScreenshotComponent} />
      )}
    </div>
  );
};

export default ShareSocial;
