import { useSelector } from "react-redux"
import { ENGAGEMENT_TYPE_FILTERS, INDUSTRY_TYPE_FILTERS, PUBLIC_ACTION_DEADLINE_FILTERS, NAICS_SEARCH, NAME_SEARCH, NOTICE_TYPE_FILTERS, PERMIT_NUM_SEARCH, PROGRAM_AREA_FILTERS, RN_SEARCH, SIC_SEARCH } from '../constants/notices';
import { dateRangeSelector, filterValuesSelector, searchSelector, sortSelector } from '../selectors/notices';
import { defaultNoticesState } from "../reducers/notices";
import { stringIsEmptyOrNull } from "../utils/helpers";

const checkThatAnyValueIsFalse = (obj) => Object.values(obj).some((v) => v === false);

// Hook to determine whether to show the filter/sort/search buttons as activated
// and whether or not to show the "reset filters" button
export const useFiltersSortActiveStatus = () => {
  const industryTypeFilters = useSelector((state) => filterValuesSelector(state, INDUSTRY_TYPE_FILTERS))
  const noticeTypeFilters = useSelector((state) => filterValuesSelector(state, NOTICE_TYPE_FILTERS))
  const programAreaFilters = useSelector((state) => filterValuesSelector(state, PROGRAM_AREA_FILTERS))
  const engagementFilters = useSelector((state) => filterValuesSelector(state, ENGAGEMENT_TYPE_FILTERS))
  const publicActionDeadlineFilters = useSelector((state) => filterValuesSelector(state, PUBLIC_ACTION_DEADLINE_FILTERS))
  const searches = useSelector(searchSelector);
  const sortBy = useSelector(sortSelector);
  const { selection } = useSelector(dateRangeSelector);

  // Check that any filter type has changed from default (all checked),
  // or any code search string is non-null (from inputs that are also in "filters" dropdown) 
  const checkboxAndCodeFiltersStatusArray = [
    checkThatAnyValueIsFalse(industryTypeFilters),
    checkThatAnyValueIsFalse(noticeTypeFilters),
    checkThatAnyValueIsFalse(programAreaFilters),
    checkThatAnyValueIsFalse(engagementFilters),
    checkThatAnyValueIsFalse(publicActionDeadlineFilters),
    !stringIsEmptyOrNull(searches[NAICS_SEARCH]),
    !stringIsEmptyOrNull(searches[SIC_SEARCH]),
    !stringIsEmptyOrNull(searches[RN_SEARCH]),
    !stringIsEmptyOrNull(searches[PERMIT_NUM_SEARCH])
  ]
  const numCheckboxAndCodeFiltersActive = checkboxAndCodeFiltersStatusArray.filter(isActive => isActive).length;
  const checkboxAndCodeFiltersActive = numCheckboxAndCodeFiltersActive > 0;
  
  // Check if sort selection has changed from default state
  const sortActive = sortBy !== defaultNoticesState.sort;

  // Check if date selection has changed from default state
  const dateRangeActive = selection !== defaultNoticesState.dateRange.selection;

  // Check if name search is active
  const nameSearchActive = !stringIsEmptyOrNull(searches[NAME_SEARCH])

  const anyFilterActive = checkboxAndCodeFiltersActive || sortActive || dateRangeActive || nameSearchActive;

  return {
    anyFilterActive,
    dateRangeActive,
    sortActive,
    nameSearchActive,
    checkboxAndCodeFiltersActive,
    numCheckboxAndCodeFiltersActive
  }
}