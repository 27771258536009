import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import store from './reducers/store';
import 'react-toastify/dist/ReactToastify.css';
import { Main } from './components/Main';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
};

const App = () => (
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={(state) => state.queries}>
      <Router />
    </ReduxQueryProvider>
  </Provider>
);

export default App;