import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SHADOW_NONE, SHADOW_MENU, BG_LIGHTER, MAX_DESKTOP_WIDTH, SECONDARY, GREY_DARK, GREY_MEDIUM, GREY_MEDIUM_LIGHT } from '../../constants/cssVars.js';
import closeImg from '../../assets/icons/close.svg'
import stannpStep1 from '../../assets/stannp/step_1.png'
import stannpStep2 from '../../assets/stannp/step_2.png'
import stannpStep3 from '../../assets/stannp/step_3.png'
import stannpStep4 from '../../assets/stannp/step_4.png'
import stannpStep5 from '../../assets/stannp/step_5.png'
import stannpStep6 from '../../assets/stannp/step_6.png'
import stannpStep7 from '../../assets/stannp/step_7.png'
import stannpStep8 from '../../assets/stannp/step_8.png'
import stannpStep9 from '../../assets/stannp/step_9.png'
import stannpStep10 from '../../assets/stannp/step_10.png'
import stannpStep11 from '../../assets/stannp/step_11.png'
import stannpStep12 from '../../assets/stannp/step_12.png'
import stannpStep13 from '../../assets/stannp/step_13.png'
import { TextLink } from '../general/Common.js';
import { setStannpSlider } from '../../actions/general.js';
import { stannpSliderSelector } from '../../selectors/general.js';
import { TemplateCodeCopyButton } from './TemplateCodeCopyButton.js';
import { AddressDownloadButton } from './AddressDownloadButton.js';
import { useCurrentNotice } from '../../hooks/useCurrentNotice.js';

const MENU_WIDTH = 800;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: ${({ active }) => active ? 'visible' : 'hidden'};
  z-index: 1000;
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
`;

const ClickableWrapper = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  font-size: 18px;

`;

const CloseText = styled.a`
  color: ${GREY_DARK};
  font-weight: 600;
  text-decoration: underline;
`;

const CloseImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  background-color: ${BG_LIGHTER};
  color: ${GREY_DARK};
  margin: 0px;
  height: 100vh;
  width: min(${MENU_WIDTH}px, 100%);
  border-left: 3px solid ${SECONDARY};
  position: fixed;
  z-index: 1;
  top: 0;
  visibility: ${({ active }) => active ? 'visible' : 'hidden'};
  right: ${({ active }) => active ? 0 : -MENU_WIDTH}px;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: ${({ active }) => active ? SHADOW_MENU : SHADOW_NONE};
`

const Instructions = styled.div`
  margin: 0px;
  padding: 10px 30px;
  font-size: 1em;
  padding-bottom: 60px;
  > p {
    font-size: 0.9em;
    margin-top: 10px;
  }
  > h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    border-top: 1px solid ${GREY_MEDIUM};
    padding-top: 30px;
  }
  > img {
    width: calc(100% - 30px);
    border: 2px solid ${GREY_MEDIUM_LIGHT};
    margin-bottom: 20px;
  }
`

const SlideOut = ({ active, setActive }) => {
  const notice = useCurrentNotice();
  const close = () => setActive(false);  

  return (
    <OuterWrapper active={active} onClick={close}>
      <Wrapper role="navigation" active={active} onClick={(e) => e.stopPropagation()}>
        <CloseWrapper>
          <ClickableWrapper tabIndex="0" onClick={close}>
            <CloseText>
              close
            </CloseText>
            <CloseImg src={closeImg} alt="" />
          </ClickableWrapper>
        </CloseWrapper>
        <Instructions>
          <p>
            <b>Stannp</b> is a mailing service AAH uses to send out mailers to residents. If you'd like to use a similar workflow to send mailers for a facility in your area, follow these instructions!
          </p>
          <h3>
            1. Create or Login to Stannp Account
          </h3>
          <p>
           <TextLink to="https://us.stannp.com/register">Sign up</TextLink> to create a new Stannp account, or <TextLink to="https://us.stannp.com/login">log in</TextLink> to an existing account.
          </p>
          <h3>
            2. Create a Campaign
          </h3>
          <p>
            In the top navigation bar, click on the "Send Mail" dropdown, then click <b>"Bulk Campaigns"</b>.
          </p>
          <img src={stannpStep1} />
          <p>
            Next, click <b>"Create a New Campaign"</b> in the upper right hand corner.
          </p>
          <img src={stannpStep2} />
          <h3>
            3. Use Our Templates or Design Your Postcard
          </h3>
          <p>
            If you want to use our templates, select the 6 x 9 Postcard Size.
          </p>
          <img src={stannpStep3} />
          <p>
            On the "Campaign Options" screen, name your campaign and set your postcard type to <b>"First Class"</b>. Click <b>"Create Campaign"</b>.
          </p>
          <img src={stannpStep4} />
          <p>
            Next, under "Our Templates" on the "A Blank Canvas" card, click <b>"Use this template"</b>.
          </p>
          <img src={stannpStep5} />
          <p>
            Once in the design editor, click on the "Advanced Edit With JSON" button (marked with the orange arrow below).
          </p>
          <img src={stannpStep6} />
          <p>
            Next, delete <b>all</b> of the text that is currently in the "Template JSON" box.
          </p>
          <img src={stannpStep7} />
          <p>
            Now we'll generate the template text to add into the cleared out "Template JSON" box. First, update the following two inputs to the correct values for this postcard if needed.
            Next, click the "Copy Template Text To Clipboard" button to copy new text to your clipboard, then paste it into the box. Click "<b>Update</b>" once it's in!
          </p>
          {notice && <TemplateCodeCopyButton />}
          <br/><br/>
          <p>
            After clicking "<b>update</b>", you should see your design populated with our default template!
          </p>
          <img src={stannpStep8} />
          <p>
            If you'd like to make adjustments to this design, you can use the editor to add or remove text and images. 
            Pieces marked with brackets, such as {`"{facilityaddress}"`}, will be replaced by notice variables once the addresses have been uploaded.
          </p>
          <p>
            Click "<b>Save and Finish</b>" in the upper-right hand corner to continue.
          </p>
          <img src={stannpStep9} />
          <h3>
            3. Importing the Address List
          </h3>
          <p>
            On the "Select/Upload Recipients" page, click "<b>Upload New Recipients</b>" 
          </p>
          <img src={stannpStep10} />
          <p>
            Click the following button to download the current address list, populated with notice information that will be inserted into the Stannp template.
          </p>
          <AddressDownloadButton includeStannpVariables={true} />
          <br/><br/>
          <p>
            Drag and drop the downloaded address list into the section indicated on the page. Once the upload is complete, scroll down to <b>name your group</b> and click "<b>Import Data</b>".
          </p>
          <img src={stannpStep11} />
          <p>
            It will take a minute for Stannp to run verification checks on the import, but once that is complete, click "<b>Select</b>" next to your new group.
          </p>
          <img src={stannpStep12} />
          <p>
            Click "<b>Save Selection</b>" on the following page.
          </p>
          <h3>
            4. Approving and Booking Your Campaign
          </h3>
          <p>
            Next, Stannp will walk you through the final steps of reviewing and approving your campaign.
          </p>
          <p>
            Be sure to give the <b>PDF sample</b> a review before approving, verifying that variables such as {`"{facilityaddress}"`} have been replaced with the correct address text for the notice.
          </p>
          <img src={stannpStep12} />
          <p>
            Once you approve, you'll be able to choose a weekday date within the next month to send out your mailers. 
          </p>
          <p>
            And that's it! We hope this was a helpful process for your organization efforts. 
            {/* If you have any recommendations for how we can improve these instructions, please let us know at <TextLink>link to feedback survey (todo)</TextLink> */}
          </p>
        </Instructions>
      </Wrapper>
    </OuterWrapper>
  );
}

export const StannpSlider = (props) => {
  const showStannpSlider = useSelector(stannpSliderSelector);
  const dispatch = useDispatch();
  return (
    <SlideOut active={showStannpSlider} setActive={(active) => dispatch(setStannpSlider(active))} {...props} />
  )
}