import React from 'react';
import { useCSVDownload } from "../../hooks/useCSVDownload";
import { useMailMergeVariables } from "../../hooks/useMailMergeVariables";
import { LinkLikeButton } from "../general/Button";

// Returns a function to download the current notice's mailer template properties as a CSV
const useMailMergeCSVDownload = () => {
  const mailMergeVars = useMailMergeVariables();
  const csvHeaderString = Object.keys(mailMergeVars).join(',');
  const csvContentString = Object.values(mailMergeVars).map(str => str ? `"${str}"` : "").join(',');
  const fullCsvString = [csvHeaderString, csvContentString].join('\n')
  const downloadCSV = useCSVDownload(fullCsvString, 'mail_merge.csv');
  return downloadCSV;
}

// Returns a button that downloads the current notice's mailer template properties as a mail-merge CSV
export const MailMergeDownloadButton = () => {
  const downloadCSV = useMailMergeCSVDownload();
  return <LinkLikeButton style={{ fontSize: '0.9em' }} onClick={downloadCSV}>
    Download mail merge CSV
  </LinkLikeButton>
}