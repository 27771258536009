import dayCareIcon from '../assets/icons/places-of-concern/daycare.svg';
import hospitalIcon from '../assets/icons/places-of-concern/hospital.svg';
import nursingHomeIcon from '../assets/icons/places-of-concern/nursing-home.svg';
import schoolIcon from '../assets/icons/places-of-concern/school.svg';

export const PLACES_ICONS = {
  'CHILD CARE CENTER': {
    icon: dayCareIcon,
    label: 'Child care centers',
  },
  HOSPITAL: {
    icon: hospitalIcon,
    label: 'Hospitals',
  },
  'NURSING HOME': {
    icon: nursingHomeIcon,
    label: 'Nursing Homes',
  },
  SCHOOL: {
    color: '#c0392b',
    icon: schoolIcon,
    label: 'Schools',
  },
};
