import React from 'react';
import styled from 'styled-components';
import { GREY_MEDIUM, GREY_MEDIUM_DARK, PRIMARY, PRIMARY_DARK, SHADOW_CRISP } from '../../constants/cssVars';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: default;
  &:hover div {
    visibility: visible;
  }
`

const TooltipContent = styled.div`
  visibility: hidden;
  width: 220px;
  background-color: #fff;
  font-size: 0.9em;
  border: 1px solid ${GREY_MEDIUM_DARK};
  box-shadow: ${SHADOW_CRISP};
  text-align: center;
  border-radius: 6px;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  right: 0px;
  bottom: 100%;
  margin-left: -113px;
`

export const Tooltip = ({ content, children }) => {
  return <TooltipWrapper>
    {children}
    <TooltipContent>
      {content}
    </TooltipContent>
  </TooltipWrapper>
}