import { applyMiddleware, createStore, combineReducers } from 'redux';
import { entitiesReducer, queriesReducer, queryMiddleware } from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import thunk from 'redux-thunk';

import { CLEAR_STORE } from '../actions/clearStore';

import generalReducer from './general';
import modalsReducer from './modals';
import noticesReducer from './notices';

export const getQueries = state => state.queries;
export const getEntities = state => state.entities;

const reducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  modals: modalsReducer,
  notices: noticesReducer,
  general: generalReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const middlewares = [thunk, queryMiddleware(superagentInterface, getQueries, getEntities)];

if (process.env.NODE_ENV === `development` && process.env.REACT_APP_LOG_STATE == 'true') {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
