import React from 'react';
import styled from 'styled-components';

import { JA_LOGO_HEIGHT, JA_BAR_WIDTH } from '../../constants/cssVars';

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  width: ${JA_BAR_WIDTH}px;
  height: ${JA_LOGO_HEIGHT}px;
  bottom: 0px;
  left: 0px;
  z-index: 501;
  background-color: #fff;
  padding: 5px 10px;
  padding-left: 10px;
  color: #06335c;
  font-weight: 600;
  font-size: 12px;
  border-radius: 0px 10px 0px 0px;
  border-top: 1px solid #dedede;
  border-right: 1px solid #dedede;
  text-align: center;
  @media only screen and (max-width: 760px) {
    width: ${JA_BAR_WIDTH - 20}px;
  }
  .cls-1 {
    fill: #319cf4;
  }
  .cls-2 {
    fill: #081f30;
  }
  .cls-3 {
    fill: #ff8b00;
  }
`;

const Logo = styled.div`
  margin-left: 3px;
  transform: translateY(2px);
  width: 102.33px;
  height: 16px;
  z-index: 502;
  position: relative;
`;

const logoSvgRaw = (
  <svg viewBox="0 0 500 78.5">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M23.6,14.6h8.5V49.07q0,7.05-4.4,11.2T16.07,64.43q-7.69,0-11.88-3.92T0,49.67H8.51c0,2.61.64,4.58,1.94,5.89a7.5,7.5,0,0,0,5.62,2,7,7,0,0,0,5.48-2.28A9,9,0,0,0,23.6,49Z"
        />
        <path
          className="cls-1"
          d="M60.13,63.75a12.61,12.61,0,0,1-1-3.41,12.79,12.79,0,0,1-9.59,4.09,12.9,12.9,0,0,1-9-3.14,10.08,10.08,0,0,1-3.47-7.77,10.37,10.37,0,0,1,4.33-9q4.35-3.12,12.41-3.12h5V39a6.4,6.4,0,0,0-1.59-4.54,6.25,6.25,0,0,0-4.82-1.71,7.18,7.18,0,0,0-4.59,1.41,4.31,4.31,0,0,0-1.79,3.56H37.91a9.12,9.12,0,0,1,2-5.62A13.37,13.37,0,0,1,45.32,28,19.08,19.08,0,0,1,53,26.55c4.28,0,7.68,1.07,10.23,3.22s3.85,5.17,3.91,9.07V55.31a18.56,18.56,0,0,0,1.39,7.87v.57Zm-9-5.91a9.33,9.33,0,0,0,4.58-1.18,7.87,7.87,0,0,0,3.22-3.17V46.6H54.49c-3,0-5.33.53-6.85,1.59a5.12,5.12,0,0,0-2.3,4.49,4.78,4.78,0,0,0,1.57,3.76A6.06,6.06,0,0,0,51.11,57.84Z"
        />
        <path
          className="cls-1"
          d="M81.93,27.22l.24,4.22A13.14,13.14,0,0,1,92.8,26.55q11.42,0,11.62,13.06V63.75H96.21V40.09q0-3.48-1.5-5.15c-1-1.12-2.64-1.67-4.91-1.67a7.89,7.89,0,0,0-7.39,4.49v26H74.2V27.22Z"
        />
        <path
          className="cls-1"
          d="M133.52,60.17q-3.61,4.26-10.26,4.26c-4,0-7-1.16-9-3.48s-3.06-5.67-3.06-10.06V27.22h8.21V50.79q0,6.95,5.77,7,6,0,8.07-4.29V27.22h8.2V63.75h-7.73Z"
        />
        <path
          className="cls-1"
          d="M170.25,63.75a12.61,12.61,0,0,1-.95-3.41,13.78,13.78,0,0,1-18.56.95,10.05,10.05,0,0,1-3.48-7.77,10.35,10.35,0,0,1,4.34-9q4.34-3.12,12.4-3.12h5V39a6.43,6.43,0,0,0-1.58-4.54,6.29,6.29,0,0,0-4.83-1.71A7.18,7.18,0,0,0,158,34.2a4.31,4.31,0,0,0-1.79,3.56H148a9.06,9.06,0,0,1,2-5.62A13.27,13.27,0,0,1,155.44,28a19.08,19.08,0,0,1,7.65-1.48q6.42,0,10.23,3.22t3.92,9.07V55.31a18.73,18.73,0,0,0,1.38,7.87v.57Zm-9-5.91a9.3,9.3,0,0,0,4.58-1.18A7.87,7.87,0,0,0,169,53.49V46.6h-4.42q-4.56,0-6.85,1.59a5.14,5.14,0,0,0-2.3,4.49A4.78,4.78,0,0,0,157,56.44,6.06,6.06,0,0,0,161.23,57.84Z"
        />
        <path
          className="cls-1"
          d="M203.57,34.72a20.26,20.26,0,0,0-3.34-.27q-5.64,0-7.6,4.32v25h-8.2V27.22h7.83l.2,4.09a9.25,9.25,0,0,1,8.24-4.76,7.75,7.75,0,0,1,2.9.47Z"
        />
        <path
          className="cls-1"
          d="M224.43,52l7.43-24.78h8.74L226.09,69.29q-3.34,9.21-11.35,9.21a14.53,14.53,0,0,1-4-.61V71.52l1.56.1A8,8,0,0,0,217,70.49a7.45,7.45,0,0,0,2.48-3.8l1.19-3.14L207.86,27.22h8.84Z"
        />
        <path
          className="cls-2"
          d="M273.62,52.31h-19l-4,11.44h-8.87L260.28,14.6H268l18.6,49.15h-8.92ZM257,45.42h14.25L264.1,25Z"
        />
        <path
          className="cls-2"
          d="M287,45.22q0-8.44,3.92-13.56a12.54,12.54,0,0,1,10.5-5.11,11.91,11.91,0,0,1,9.38,4.05V11.9H319V63.75h-7.43l-.4-3.78a12.11,12.11,0,0,1-9.83,4.46,12.48,12.48,0,0,1-10.38-5.17Q287,54.1,287,45.22Zm8.21.71q0,5.57,2.14,8.69a7,7,0,0,0,6.09,3.12,7.73,7.73,0,0,0,7.36-4.49V37.66a7.63,7.63,0,0,0-7.29-4.39,7,7,0,0,0-6.14,3.15Q295.16,39.59,295.16,45.93Z"
        />
        <path
          className="cls-2"
          d="M339,53.35l7.73-26.13h8.47L342.55,63.75h-7.12L322.67,27.22h8.51Z"
        />
        <path className="cls-2" d="M369.05,63.75h-8.2V27.22h8.2Z" />
        <path
          className="cls-2"
          d="M395.69,53.83a3.73,3.73,0,0,0-1.8-3.35,19.36,19.36,0,0,0-6-2,30.85,30.85,0,0,1-7-2.23q-6.15-3-6.15-8.61a9.73,9.73,0,0,1,4-7.9,15.76,15.76,0,0,1,10.12-3.17q6.56,0,10.59,3.24a10.3,10.3,0,0,1,4,8.41h-8.2a5.07,5.07,0,0,0-1.76-3.94,6.75,6.75,0,0,0-4.66-1.57,7.26,7.26,0,0,0-4.4,1.25,4,4,0,0,0-1.71,3.34,3.32,3.32,0,0,0,1.59,2.94,21.75,21.75,0,0,0,6.41,2.11,30.3,30.3,0,0,1,7.58,2.53,10.75,10.75,0,0,1,4.09,3.53,9.07,9.07,0,0,1,1.33,5,9.51,9.51,0,0,1-4.09,8q-4.08,3.06-10.7,3.06a18.75,18.75,0,0,1-8-1.63,13.19,13.19,0,0,1-5.47-4.45,10.58,10.58,0,0,1-1.95-6.11h8a5.8,5.8,0,0,0,2.2,4.47,8.5,8.5,0,0,0,5.36,1.57A8.28,8.28,0,0,0,394,57.05,3.81,3.81,0,0,0,395.69,53.83Z"
        />
        <path
          className="cls-2"
          d="M408,45.15a21.5,21.5,0,0,1,2.13-9.67,15.57,15.57,0,0,1,6-6.62,16.88,16.88,0,0,1,8.85-2.31,16,16,0,0,1,12,4.76q4.6,4.75,5,12.62l0,1.93a21.75,21.75,0,0,1-2.07,9.65,15.42,15.42,0,0,1-5.95,6.59A17,17,0,0,1,425,64.43q-7.74,0-12.38-5.15T408,45.55Zm8.2.71q0,5.64,2.33,8.83A7.61,7.61,0,0,0,425,57.88a7.49,7.49,0,0,0,6.46-3.24c1.54-2.16,2.31-5.33,2.31-9.49q0-5.53-2.38-8.78a8,8,0,0,0-12.84,0Q416.18,39.52,416.18,45.86Z"
        />
        <path
          className="cls-2"
          d="M466.62,34.72a20.26,20.26,0,0,0-3.34-.27q-5.64,0-7.6,4.32v25h-8.2V27.22h7.83l.2,4.09a9.25,9.25,0,0,1,8.24-4.76,7.75,7.75,0,0,1,2.9.47Z"
        />
        <path
          className="cls-2"
          d="M491.93,53.83a3.73,3.73,0,0,0-1.8-3.35,19.36,19.36,0,0,0-6-2,30.85,30.85,0,0,1-7-2.23q-6.15-3-6.15-8.61a9.73,9.73,0,0,1,4-7.9,15.76,15.76,0,0,1,10.12-3.17q6.56,0,10.59,3.24a10.3,10.3,0,0,1,4,8.41h-8.2a5.07,5.07,0,0,0-1.76-3.94,6.75,6.75,0,0,0-4.66-1.57,7.26,7.26,0,0,0-4.4,1.25A4,4,0,0,0,479,37.28a3.32,3.32,0,0,0,1.59,2.94A21.75,21.75,0,0,0,487,42.33a30.3,30.3,0,0,1,7.58,2.53,10.75,10.75,0,0,1,4.09,3.53,9.07,9.07,0,0,1,1.33,5,9.51,9.51,0,0,1-4.09,8q-4.08,3.06-10.7,3.06a18.75,18.75,0,0,1-8-1.63,13.19,13.19,0,0,1-5.47-4.45,10.58,10.58,0,0,1-1.95-6.11h8a5.8,5.8,0,0,0,2.2,4.47,8.5,8.5,0,0,0,5.36,1.57,8.28,8.28,0,0,0,4.93-1.23A3.81,3.81,0,0,0,491.93,53.83Z"
        />
        <path
          className="cls-3"
          d="M364.9,23.22c-3.55,0-5.5-3.67-5.5-6.71,0-6.52,3.35-9,3.35-9-2.54,7.73,1.36,7.47,1.36,7.47s5.56.45.79-15c0,0,5.61,5.27,5.61,15.41C370.51,15.41,370.4,23.22,364.9,23.22Z"
        />
      </g>
    </g>
  </svg>
);

export const JALogo = () => (
  <a href="https://www.januaryadvisors.com/" rel="noreferrer noopener" target="_blank">
    <LogoWrapper>
      Built by
      <Logo>{logoSvgRaw}</Logo>
    </LogoWrapper>
  </a>
);
